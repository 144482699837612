import React, { useEffect, useRef } from "react";

export const OtpInput = ({ value, onChange, numInputs }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = Array(numInputs)
      .fill(null)
      .map((_, i) => inputRefs.current[i] || React.createRef());

    if (inputRefs.current[0]?.current) {
      inputRefs.current[0].current.focus();
    }
  }, [numInputs]);

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    if (newValue && !/^\d*$/.test(newValue)) return;

    const digit = newValue.length > 1 ? newValue.slice(-1) : newValue;

    const newOtpValue =
      value.substring(0, index) + digit + value.substring(index + 1);
    onChange(newOtpValue);

    if (
      digit &&
      index < numInputs - 1 &&
      inputRefs.current[index + 1]?.current
    ) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (
      e.key === "Backspace" &&
      !value[index] &&
      index > 0 &&
      inputRefs.current[index - 1]?.current
    ) {
      inputRefs.current[index - 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();
    if (!/^\d+$/.test(pastedData)) return;

    const newValue = pastedData
      .substring(0, numInputs)
      .padEnd(value.length, value.substring(pastedData.length));
    onChange(newValue);

    const focusIndex = Math.min(pastedData.length, numInputs - 1);
    if (inputRefs.current[focusIndex]?.current) {
      inputRefs.current[focusIndex].current.focus();
    }
  };

  return (
    <div className="flex items-center justify-center space-x-2">
      {Array(numInputs)
        .fill(0)
        .map((_, index) => (
          <React.Fragment key={index}>
            <input
              ref={(el) => (inputRefs.current[index] = { current: el })}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={value[index] || ""}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={index === 0 ? handlePaste : undefined}
              className="input input-bordered rounded-lg w-12 h-12 text-center text-lg focus:outline-none focus:outline-0 focus:border-primary focus:ring-2 ring-green-100"
            />
          </React.Fragment>
        ))}
    </div>
  );
};
