import React, { useState, useEffect } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { AddressSection } from "./AddressSection";

export const IndividualForm = ({
  register,
  errors,
  setValue,
  watch,
  options,
}) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);

  const watchCity = watch("city");
  const watchDistrict = watch("district");

  useEffect(() => {
    if (watchCity) {
      setSelectedCity(watchCity);
      setDistricts(
        options?.aimags.filter((item) => item.title === watchCity)[0]?.childs
      );
      setValue("district", "");
      setValue("khoroo", "");
      setSelectedDistrict(null);
    }
  }, [watchCity, setValue, options?.aimags]);

  useEffect(() => {
    if (watchDistrict) {
      setSelectedDistrict(watchDistrict);
    }
  }, [watchDistrict]);

  return (
    <div className="space-y-4 mt-6">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="mb-4 w-full">
          <label className="block text-sm font-semibold mb-1">
            <span className="text-error">*</span> Нэр
          </label>
          <input
            type="text"
            placeholder="Бат"
            className={`w-full p-3 border ${
              errors.firstName ? "border-error" : "border-base-300"
            } rounded-md focus:outline-none focus:border-primary`}
            {...register("firstName")}
          />
          {errors.firstName && (
            <p className="text-error text-xs mt-1">
              {errors.firstName.message}
            </p>
          )}
        </div>
        <div className="mb-4 w-full">
          <label className="block text-sm font-semibold mb-1">
            <span className="text-error">*</span> Овог
          </label>
          <input
            type="text"
            placeholder="Болд"
            className={`w-full p-3 border ${
              errors.lastName ? "border-error" : "border-base-300"
            } rounded-md focus:outline-none focus:border-primary`}
            {...register("lastName")}
          />
          {errors.lastName && (
            <p className="text-error text-xs mt-1">{errors.lastName.message}</p>
          )}
        </div>
        <div className="w-full">
          <label className="block text-sm font-medium mb-1">
            Утас <span className="text-error">*</span>
          </label>
          <input
            type="text"
            className={`w-full p-3 border ${
              errors.phone ? "border-error" : "border-base-300"
            } rounded-md focus:outline-none focus:border-primary`}
            {...register("phone")}
          />
          {errors.phone && (
            <p className="text-error text-xs mt-1">{errors.phone.message}</p>
          )}
        </div>
      </div>
      <AddressSection
        register={register}
        errors={errors}
        setValue={setValue}
        watch={watch}
        options={options}
        selectedCity={selectedCity}
        selectedDistrict={selectedDistrict}
        districts={districts}
      />
    </div>
  );
};
