import { motion } from "framer-motion";
export const OrganizationList = ({ organizations, activeOrg, onOrgClick }) => {
  return (
    <>
      <h3 className="font-medium mb-3">Бүх байгууллага</h3>
      <ul className="text-sm space-y-1.5 border rounded-md  max-h-96 overflow-y-auto">
        {organizations.map((org, idx) => (
          <motion.li
            key={`org-${idx}`}
            className={`cursor-pointer py-1 px-1 transition-colors hover:bg-base200 rounded ${
              activeOrg === org ? "bg-base200 font-medium" : ""
            }`}
            onClick={() => onOrgClick(org)}
            whileHover={{ backgroundColor: "#f3f4f6" }}
            whileTap={{ scale: 0.98 }}
          >
            {org}
          </motion.li>
        ))}
      </ul>
    </>
  );
};
