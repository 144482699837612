import { useContext, useEffect, useState } from "react";
import { ProfileForm } from "../components/settingsPage/";
import { BasicService } from "../service/get-basic";
import { GlobalContext } from "../context/global-context";
import { SettingsPageSkeleton } from "../components/skeleton/SettingsPageSkeleton";

const Settings = () => {
  const [profileData, setProfileData] = useState();
  const [companyInfo, setCompanyInfo] = useState(null);
  const [userInfoState, setUserInfoState] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { userInfo } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchProfileData = async () => {
      const data = await BasicService.getProfile(userInfo.token);
      const res = await BasicService.getBasic(userInfo.token);
      console.log(data);

      setProfileData(data);
      if (res?.data?.isPrimary) {
        setUserInfoState(data?.data?.user);
      } else {
        setCompanyInfo(data?.data?.company);
      }

      if (data.status === 200) {
        setIsLoading(false);
      }
    };
    fetchProfileData();
  }, [userInfo]);

  const handleImageSelect = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (isLoading) return <SettingsPageSkeleton />;

  return (
    profileData && (
      <div className="p-6 space-y-6 border border-base300 rounded-xl w-full">
        {(userInfoState || companyInfo) && (
          <div className="w-full p-6 bg-base-100 rounded-xl">
            <div className="grid grid-cols-1 xl:grid-cols-4 mx-auto max-w-6xl xl:gap-8">
              <ProfileForm
                profile={userInfoState || companyInfo}
                setProfile={setProfileData}
                editMode={editMode}
                setEditMode={setEditMode}
                initialPhone={userInfoState?.phone || companyInfo?.phone}
              />
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default Settings;
