import React from "react";

export const SettingsPageSkeleton = () => {
  return (
    <div className="min-h-full bg-gray-100 p-8">
      <div className="container mx-auto max-w-7xl">

        <div className="space-y-6">
          <div className="w-full p-6 bg-white rounded-xl animate-pulse">
            <div className="grid grid-rows-[0.3fr_0.6fr] grid-cols-1 xl:grid-rows-1 xl:grid-cols-5 mx-auto max-w-6xl gap-8">
              
              <div className="col-span-5 row-span-1 xl:col-span-4 grid grid-cols-4 gap-8">
                <div className="col-span-2 space-y-4">
                  {[1, 2, 3, 4].map((item) => (
                    <div key={item} className="space-y-2">
                      <div className="h-4 w-1/3 bg-gray-300 rounded"></div>
                      <div className="h-10 w-full bg-gray-300 rounded"></div>
                    </div>
                  ))}
                </div>
                <div className="col-span-2 space-y-4">
                  {[1, 2, 3, 4].map((item) => (
                    <div key={item} className="space-y-2">
                      <div className="h-4 w-1/3 bg-gray-300 rounded"></div>
                      <div className="h-10 w-full bg-gray-300 rounded"></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Bottom Sections Skeleton */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
            <div className="bg-white rounded-xl p-6 space-y-4 animate-pulse">
              <div className="h-6 w-1/2 bg-gray-300 mx-auto rounded mb-4"></div>
              <div className="h-10 w-full bg-gray-300 rounded"></div>
              <div className="h-10 w-full bg-gray-300 rounded mt-4"></div>
            </div>

            <div className="bg-white p-8 rounded-xl space-y-4 animate-pulse">
              <div className="h-6 w-1/2 bg-gray-300 rounded mb-4"></div>
              <div className="h-10 w-full bg-gray-300 rounded"></div>
              <div className="h-10 w-full bg-gray-300 rounded mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
