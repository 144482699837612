import React from "react";

function TenderCard() {
  return (
    <div className="bg-white rounded-lg border border-base-300 p-4">
      <div className="flex items-center space-x-3 mb-3">
        <div className="w-8 h-8 rounded-full overflow-hidden">
          <img
            src="/mcslogo.png"
            alt="MCS Coca Cola"
            className="w-full h-full object-contain"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://via.placeholder.com/32";
            }}
          />
        </div>
        <h3 className="font-bold">MCS Coca Cola</h3>
      </div>

      <p className="text-sm mb-2">
        Шилэн кабель болон автоматикийн тоноглол нийлүүлэх
      </p>
      <p className="text-xs text-gray-500 mb-3">
        Санал хүлээн авах огноо: 2025/03/20
      </p>

      <div className="flex space-x-2">
        <span className="badge bg-green-100 text-green-800 text-xs">
          Барааг
        </span>
        <span className="badge bg-accent text-black text-xs">
          Нээлттэй тендер
        </span>
      </div>
    </div>
  );
}

export function CompanyTenders() {
  return (
    <div>
      <h3 className="font-bold mb-4">
        MCS Coca Cola - бусад зарласан тендерүүд
      </h3>
      <div className="space-y-4">
        <TenderCard />
        <TenderCard />
      </div>
    </div>
  );
}
