import React from "react";
import { useNavigate } from "react-router-dom";
import thousandify from "thousandify";

export const TenderCard = ({ tender, deadline }) => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-white rounded-lg p-3 sm:p-4 hover:cursor-pointer mb-4 border transition-all duration-200 hover:shadow-md"
      onClick={() => navigate("/tenders/d")}
    >
      {/* Mobile Layout (Stack) */}
      <div className="flex flex-col sm:hidden">
        {/* Header with Logo and Type */}
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 rounded-full flex-shrink-0">
              <img
                src="/mcslogo.png"
                alt=""
                className="w-full h-full object-contain"
              />
            </div>
            <span className="text-sm font-medium">MCS Coca Cola</span>
          </div>
          <span className="text-xs px-2 py-0.5 rounded-[5px] border border-[#72CF58] bg-[#CBF3C0] text-[#72CF58]">
            {tender.type}
          </span>
        </div>

        {/* Title */}
        <p className="text-sm font-medium mb-2">
          Шилэн кабель болон автоматжуулах төхөөрөмж нийлүүлэх
        </p>

        {/* Date */}
        <div className="text-xs text-gray-400 mb-2">
          Санал хүлээн авах огноо: {tender.date}
        </div>

        {/* Budget and Tags */}
        <div className="flex items-center justify-between mt-1">
          <div className="flex flex-col sm:flex-row gap-1">
            <button className="bg-[#97E182] text-neutral text-xs px-2 py-1 rounded-[5px]">
              Багц {tender.package}
            </button>
            <button
              className={`text-white text-xs px-2 py-1 rounded-[5px] ${
                tender.status === "Нээлттэй тендер"
                  ? "bg-[#FFC456]"
                  : "bg-error"
              }`}
            >
              {tender.status}
            </button>
          </div>

          <div className="text-right">
            <div className="font-bold text-base">
              {thousandify(tender.amount)}₮
            </div>
            <div className="text-xs text-gray-400">Төсөв</div>
          </div>
        </div>

        {/* Deadline (if applicable) */}
        {deadline && (
          <div className="mt-2 flex justify-end">
            <button className="bg-green-500 text-white text-xs px-3 py-1 rounded-[5px]">
              9 хоног үлдсэн
            </button>
          </div>
        )}
      </div>

      {/* Desktop/Tablet Layout (Side by side) */}
      <div className="hidden sm:flex items-start h-full justify-between">
        <div className="flex items-start h-full gap-3">
          <div className="flex-shrink-0">
            <div className="w-6 h-6 rounded-full flex items-center justify-center">
              <img
                src="/mcslogo.png"
                alt=""
                className="w-full h-full object-contain"
              />
            </div>
          </div>
          <div className="flex flex-col justify-between h-full">
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium">MCS Coca Cola</span>
              <span className="text-xs px-4 py-0 rounded-[5px] border border-[#72CF58] bg-[#CBF3C0] text-[#72CF58]">
                {tender.type}
              </span>
            </div>
            <p className="text-sm font-medium mt-1">
              Шилэн кабель болон автоматжуулах төхөөрөмж нийлүүлэх
            </p>
            <div className="text-xs text-gray-400 mt-1">
              Санал хүлээн авах огноо: {tender.date}
            </div>
            <div className="flex mt-2 gap-1">
              <button className="bg-[#97E182] text-neutral text-xs px-3 py-1 rounded-[5px]">
                Багц {tender.package}
              </button>
              <button
                className={`text-white text-xs px-3 py-1 rounded-[5px] ${
                  tender.status === "Нээлттэй тендер"
                    ? "bg-[#FFC456]"
                    : "bg-error"
                }`}
              >
                {tender.status}
              </button>
            </div>
          </div>
        </div>
        <div className="text-right">
          <div className="font-bold text-lg">{thousandify(tender.amount)}₮</div>
          <div className="text-xs text-gray-400 font-bold">Төсөв</div>
          {deadline && (
            <button className="bg-green-500 text-white text-xs px-3 py-1 rounded-[5px] mt-2">
              9 хоног үлдсэн
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
