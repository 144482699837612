import React, { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const RobotCheckModal = ({ isOpen, onClose, onSuccess }) => {
  const [variableA, setVariableA] = useState(() => {
    const randomNumber = Math.floor(Math.random() * 20) + 1;
    const limit = Math.floor(randomNumber * 0.3);
    return Math.floor(Math.random() * (limit + 1));
  });

  const [variableC, setVariableC] = useState(() => {
    return variableA + Math.floor(Math.random() * 10) + 1;
  });

  const [variableB, setVariableB] = useState(() => variableC - variableA);
  const [answer, setAnswer] = useState("");
  const [notRobot, setNotRobot] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const reRenderCaptcha = () => {
    const randomNumber = Math.floor(Math.random() * 20) + 1;
    const limit = Math.floor(randomNumber * 0.3);
    const secondRandom = Math.floor(Math.random() * (limit + 1));
    setVariableA(secondRandom);
    const newTotal = secondRandom + Math.floor(Math.random() * 10) + 1;
    setVariableC(newTotal);
    setVariableB(newTotal - secondRandom);
    setAnswer("");
  };

  const handleNotRobotClick = () => {
    setNotRobot(true);
    setErrorMessage("");
  };

  const handleSave = () => {
    if (!answer || isNaN(parseInt(answer))) {
      setErrorMessage("Баталгаажуулалт зөвхөн тоо байх ёстой");
      reRenderCaptcha();
      return;
    }

    if (parseInt(answer) !== variableB) {
      setErrorMessage("Баталгаажуулалт буруу байна");
      reRenderCaptcha();
      return;
    }
    onSuccess();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-primary text-white rounded-md shadow-lg max-w-md w-full p-6 mx-4">
        {!notRobot ? (
          <div className="flex items-center gap-6 py-4">
            <div
              className="border-2 border-white w-6 h-6 flex items-center justify-center cursor-pointer select-none"
              onClick={handleNotRobotClick}
            >
              {notRobot && <FaCheck className="text-white" />}
            </div>
            <div className="text-lg">Би робот биш</div>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="mb-6 flex items-center justify-between">
              <h3 className="text-xl font-medium">Хүсэлт илгээх</h3>
              <div
                className="text-2xl select-none cursor-pointer p-2"
                onClick={onClose}
              >
                <IoClose />
              </div>
            </div>

            <EquationImage
              variableA={`${variableA}`}
              variableC={`${variableC}`}
            />

            {errorMessage && (
              <div className="text-red-400 text-sm mt-2 mb-4">
                {errorMessage}
              </div>
            )}

            <input
              value={answer}
              onChange={(e) => {
                setAnswer(e.target.value);
                setErrorMessage("");
              }}
              placeholder="Бодлогын хариу хэд вэ?"
              className="border-2 border-gray-300 p-3 rounded-md w-full my-6 text-black"
            />

            <button
              className="bg-white text-[#213547] py-3 px-6 rounded-md font-semibold text-lg cursor-pointer"
              onClick={handleSave}
            >
              Илгээх
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

// Canvas-based equation image generator
const EquationImage = ({ variableA = "A", variableC = "C" }) => {
  const canvasRef = useRef(null);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    canvas.width = 300;
    canvas.height = 60;
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.font = "bold 28px Arial";
    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    const positions = [50, 100, 150, 200, 250];
    const texts = [variableA, "+", "?", "=", variableC];

    texts.forEach((text, i) => {
      ctx.fillText(text, positions[i], canvas.height / 2);
    });

    setImageURL(canvas.toDataURL("image/png"));
  }, [variableA, variableC]);

  return (
    <div className="flex flex-col items-center">
      <canvas ref={canvasRef} style={{ display: "none" }} />
      {imageURL && (
        <img
          src={imageURL}
          alt="Generated equation"
          className="border border-gray-600 bg-[#213547] p-2 rounded-md"
        />
      )}
    </div>
  );
};

export default RobotCheckModal;
