import React, { useState, useEffect } from "react";
import { industries } from "../components/data/industries";
import { provinces } from "../components/data/provinces";
import {
  CompanyCard,
  TabButton,
  OrganizationList,
  StatsCard,
  FilterSection,
  SearchBar,
  Pagination,
} from "../components/companiesPage";
import Layout from "../layout/Layout";

const Companies = () => {
  const [activeTab, setActiveTab] = useState("Захиалагч");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    province: null,
    soum: null,
    industry: null,
    organization: "Бүх байгууллага",
  });

  const [availableSoums, setAvailableSoums] = useState([]);
  const [filteredTenders, setFilteredTenders] = useState(mockTenders);

  useEffect(() => {
    if (filters.province) {
      setAvailableSoums(mockSoums[filters.province.id] || []);
      setFilters((prev) => ({ ...prev, soum: null }));
    } else {
      setAvailableSoums([]);
    }
  }, [filters.province]);

  useEffect(() => {
    // Here you would normally filter based on API data
    // For this example, we'll just simulate filtering
    let filtered = [...mockTenders];

    if (searchTerm) {
      filtered = filtered.filter((tender) =>
        tender.company.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredTenders(filtered);
  }, [searchTerm, filters]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    console.log("Searching for:", searchTerm);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({ ...prev, [type]: value }));
  };

  const handleOrganizationClick = (org) => {
    setFilters((prev) => ({ ...prev, organization: org }));
  };

  const itemsPerPage = 9;
  const totalPages = Math.ceil(filteredTenders.length / itemsPerPage);
  const currentTenders = filteredTenders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const currentTendersCount = Math.min(filteredTenders.length, 9);
  const currentSectionTenders = filteredTenders.slice(0, currentTendersCount);
  const pastSectionTenders = filteredTenders.slice(
    currentTendersCount,
    currentTendersCount + 9
  );

  return (
    <Layout>
      <div className="min-h-full text-black">
        {/* Logo and Tabs */}
        <div className="flex relative justify-center mb-20">
          <img
            src="/banner2.png"
            alt="eTender Logo"
            className="h-full w-full"
          />
          <div className="flex lg:absolute w-2/6 -bottom-7 z-10 justify-center">
            <div className="rounded-lg overflow-hidden flex w-full">
              <TabButton
                name="Захиалагч"
                isActive={activeTab === "Захиалагч"}
                onClick={() => handleTabChange("Захиалагч")}
              />
              <TabButton
                name="Нийлүүлэгч"
                isActive={activeTab === "Нийлүүлэгч"}
                onClick={() => handleTabChange("Нийлүүлэгч")}
              />
            </div>
          </div>
        </div>
        <div className="container mx-auto max-w-7xl">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full lg:w-1/4">
              <StatsCard count="320" title="Нийт бүртгэгдсэн компани" />
              <FilterSection
                filters={filters}
                provinces={provinces}
                availableSoums={availableSoums}
                industries={industries}
                onFilterChange={handleFilterChange}
              />

              <OrganizationList
                organizations={organizations}
                activeOrg={filters.organization}
                onOrgClick={handleOrganizationClick}
              />

              <div className="bg-gray-200 rounded-lg mt-8 p-4 text-center">
                <p className="text-gray-500 text-lg">БАННЕР</p>
              </div>
            </div>

            <div className="w-full lg:w-3/4">
              <SearchBar
                value={searchTerm}
                onChange={handleSearch}
                onSearch={handleSearchClick}
              />
              <div>
                <h2 className="text-black text-lg font-medium mb-4">
                  Онцлох компаниуд
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {currentSectionTenders.map((tender) => (
                    <CompanyCard key={tender.id} tender={tender} />
                  ))}
                </div>
              </div>
              <div className="text-center my-6 rounded-lg overflow-hidden flex items-center justify-center">
                <img
                  src="/banner2.png"
                  alt="eTender Logo"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="mb-6">
                <h2 className="text-black text-lg font-medium mb-4">
                  Бусад компаниуд
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {pastSectionTenders.map((tender) => (
                    <CompanyCard key={tender.id} tender={tender} />
                  ))}
                </div>
              </div>
              <Pagination
                currentPage={currentPage}
                totalPages={12}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Companies;

const mockTenders = Array(18)
  .fill()
  .map((_, i) => ({
    id: i + 1,
    company: "МЦХ Coca Cola",
    rating: (Math.random() * 2 + 3).toFixed(1),
    reviews: Math.floor(Math.random() * 100) + 1,
    deadline: `Нийт оролцогчийн тоо: ${Math.floor(Math.random() * 10) + 14}`,
  }));

const mockSoums = {
  1: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  2: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  3: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  4: ["Хутаг-Өндөр", "Сэлэнгэ", "Хангал", "Бүрэгхангай", "Дашинчилэн"],
  5: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  6: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  7: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  8: ["Хутаг-Өндөр", "Сэлэнгэ", "Хангал", "Бүрэгхангай", "Дашинчилэн"],
  9: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  10: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  11: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  12: ["Хутаг-Өндөр", "Сэлэнгэ", "Хангал", "Бүрэгхангай", "Дашинчилэн"],
  13: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  14: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  15: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  16: ["Хутаг-Өндөр", "Сэлэнгэ", "Хангал", "Бүрэгхангай", "Дашинчилэн"],
  17: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  18: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  19: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  20: ["Баянзүрх", "Хан-Уул", "Сүхбаатар", "Баянгол", "Налайх"],
};

const organizations = [
  "Бүх байгууллага",
  "Төрийн байгууллага",
  "Хувийн хэвшлийн байгууллага",
  "Банк, санхүү, даатгал",
  "Барилга угсралт, засвар",
  "Боловсрол, Хэвлэл мэдээ",
  "Газар, тариалан",
  "Мэдээлэл, технологи",
  "Тээвэр, агаар, далай",
  "Нийтийн хоол, зочид буудал",
  "Олборлох үйлдвэрлэл (Уул уурхай)",
  "Нэхмэл, сав баглаа боодол (Үйлдвэрлэл)",
  "Үйлчилгээ (Хувийн эрүүл мэнд хүнсний)",
  "Худалдаа (Авто машин, хүнс)",
  "Эрчим хүчний хангамж (Цахилгаан)",
  "Цэвэр ус/цэвэр агаар",
  "Гоо сайхан (Гоёл нүүр будалт гоёл)",
  "Олон улсын байгууллага (Төрийн бус)",
  "Мэргэжлийн холбоо",
  "ХХХ өргөдөл",
  "Иргэд",
  "Эрүүл мэнд",
  "Тэтгэвэр",
  "Хүнсний үйлдвэрлэл",
  "Шингэн хий, нүүрсний хийгээр хангамж",
  "Хүрээлэн буй орчинд нөлөөлөх",
  "Архи тамхи",
  "Спорт",
  "Хөдөө аж ахуй, ан, агнуур",
  "Бусад",
];
