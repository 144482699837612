import { useState } from "react";

export const CustomField = ({ socket, tkn, customFields, setCustomFields }) => {
  const [fieldValue, setFieldValue] = useState("");

  const addCustomField = () => {
    const newField = {
      id: Date.now(),
      title: fieldValue,
    };

    const updatedFields = [...customFields, newField];
    setCustomFields(updatedFields);

    socket?.emit("add-tender-dynamic-field", {
      token: tkn,
      fieldId: -1,
      status: "edit",
      icon: "",
      title: fieldValue,
    });
    setFieldValue("");
  };

  const removeField = (id) => {
    const fieldToRemove = customFields.find((field) => field.id === id);
    if (fieldToRemove) {
      socket?.emit("add-tender-dynamic-field", {
        token: tkn,
        fieldId: fieldToRemove.id !== -1 ? fieldToRemove.id : -1,
        status: "delete",
        icon: "",
        title: fieldToRemove.value,
      });

      const updatedFields = customFields.filter((field) => field.id !== id);
      setCustomFields(updatedFields);
    }
  };

  return (
    <div className="custom-fields-container space-y-4">
      <h3 className="text-lg font-semibold">Нэмэлт талбар</h3>
      <p>Та тендерийн талаар нэмэх дэлгэрэнгүй мэдээллийг доор бичиж оруулна уу</p>
      <div className="flex gap-3 mb-4">
        <input
          type="text"
          value={fieldValue}
          onChange={(e) => setFieldValue(e.target.value)}
          placeholder="Утга"
          className="px-4 py-2 border w-fit border-gray-300 rounded-md outline-none text-sm"
        />
        <button
          type="button"
          onClick={addCustomField}
          className="px-4 py-2 bg-secondary text-white rounded-md text-sm font-medium"
        >
          Нэмэх
        </button>
      </div>

      {(customFields ?? []).length > 0 && (
        <div className="border border-gray-200 rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-gray-600 font-medium text-sm">
                    Утга
                  </th>
                  <th className="px-4 py-3 text-gray-600 font-medium text-sm w-32">
                    Үйлдэл
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {customFields.map((field) => (
                  <tr key={field.id} className="hover:bg-gray-50">
                    <td className="px-4 py-3 text-sm text-gray-900">
                      {field.title}
                    </td>
                    <td className="px-4 py-3">
                      <button
                        type="button"
                        onClick={() => removeField(field.id)}
                        className="px-3 py-1.5 bg-[#F38E8E] text-white text-sm rounded-md"
                      >
                        Устгах
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
