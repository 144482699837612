import React, { useState } from "react";
import { FormField } from "../components/settingsPage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileEditSchema } from "../utils/validations";
import { MdOutlineArrowRight } from "react-icons/md";

const DashboardAccount = () => {
  const [editMode, setEditMode] = useState(false);
  const [profile, setProfile] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileEditSchema),
  });
  const onSubmit = (data) => {
    setProfile((prevData) => ({
      ...prevData,
      ...data,
    }));
    setEditMode(false);
  };
  return (
    <div className="rounded-xl border border-base300 p-6 w-full h-full">
      <form
        className="grid grid-cols-1 md:grid-cols-2 p-6 gap-x-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-4">
          <FormField
            label="Банк сонгох"
            name="bank"
            control={control}
            errors={errors}
            editMode={editMode}
            profile=""
          />

          <FormField
            label="Дансны дугаар"
            name="accountNumber"
            control={control}
            errors={errors}
            editMode={editMode}
            profile=""
          />
        </div>
        <div className="col-span-1 md:col-span-2 flex justify-end mt-4">
          <button
            type="button"
            onClick={() => {
              if (editMode) {
                handleSubmit(onSubmit)();
              } else {
                setEditMode(true);
              }
            }}
            className="btn bg-primary hover:bg-primary-focus border-primary font-medium text-white rounded-md"
          >
            {editMode ? "Хадгалах" : "Засварлах"}
            <MdOutlineArrowRight className="w-5 h-5 ml-4" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default DashboardAccount;
