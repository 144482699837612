export const LoginService = {
  login(data) {
    return fetch("http://202.126.94.29:3004/api/v1/sign-in", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());
  },
};
