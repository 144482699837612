import React, { useContext, useEffect, useState } from "react";
import { BasicService } from "../service/get-basic";
import { GlobalContext } from "../context/global-context";
import { useParams } from "react-router-dom";
import { TenderDocument } from "../components/tenderDetailPage";
import { DashboardCompanyHeader } from "../components/dashboardPage/tenderDetail/DashboardCompanyHeader";
import { DashboardTenderDetails } from "../components/dashboardPage/tenderDetail/DashboardTenderDetail";

const DashboardTenderDetail = () => {
  const { userInfo } = useContext(GlobalContext);
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  console.log(id);

  useEffect(() => {
    const getTenderDetail = async () => {
      const res = await BasicService.getTenderDetail(userInfo.token, id);
      console.log(res);
      setDetail(res);
    };
    getTenderDetail();
  }, [userInfo, id]);

  return (
    <div className="min-h-screen pb-10">
      <div className="max-w-7xl container mx-auto px-4 py-6 space-y-6">
        <DashboardCompanyHeader detail={detail} />
        <div className="grid grid-cols-1 gap-6">
          <div className="lg:col-span-2">
            <DashboardTenderDetails detail={detail} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTenderDetail;
