import React from "react";

const PlanCardSkeleton = () => (
  <div className="card-body animate-pulse transition-all content-center cursor-pointer min-w-64 max-w-96 shadow-md rounded-lg bg-gray-100">
    <div className="grid gap-3">
      <div className="h-6 bg-gray-300 rounded w-3/4"></div>
      <div className="h-4 bg-gray-300 rounded w-1/2"></div>
    </div>

    <ul className="mt-2 flex flex-col gap-2">
      {[1, 2, 3].map((_, idx) => (
        <li key={idx} className="flex items-center gap-2">
          <div className="h-6 w-6 bg-gray-300 rounded-full"></div>
          <div className="flex-grow">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="h-3 bg-gray-300 rounded w-1/2 mt-1"></div>
          </div>
        </li>
      ))}
    </ul>

    <div className="mt-2">
      <div className="h-10 bg-gray-300 rounded-lg w-full"></div>
    </div>
  </div>
);

export const PlansSkeleton = ({ numberOfCards }) => {
  return (
    <div className="w-full p-6">
      <div className="card w-fit mx-auto content-center h-fit grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
        {[...Array(numberOfCards)].map((_, idx) => (
          <PlanCardSkeleton key={`skeleton-${idx}`} />
        ))}
      </div>
    </div>
  );
};
