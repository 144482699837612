// AuthLayout.jsx - Common layout for all auth pages
import React from "react";
import { Link } from "react-router-dom";

const AuthLayout = ({ children, title }) => {
  return (
    <div className="flex max-h-screen h-screen">
      {/* Left side - form */}
      <div className="w-full md:w-1/2 flex flex-col p-8">
        <div className="flex-grow flex flex-col items-center justify-center max-w-md mx-auto w-full">
          <div className="mb-8">
            <Link to="/">
              <img src="/logopurple.svg" alt="eTender" className="h-12" />
            </Link>
          </div>
          <div className="w-full">
            <h1 className="text-2xl font-medium mb-8 text-center">{title}</h1>
            {children}
          </div>
        </div>
      </div>

      {/* Right side - image placeholder */}
      <div className="hidden md:block md:w-2/3 bg-secondary">
        <div className="h-full flex items-center justify-center">
          <img src="/banner5.png" alt="eTender" className="object-cover w-full h-full" />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
