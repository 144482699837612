import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthLayout from "../layout/AuthLayout";
import { loginSchema } from "../utils/validations";
import { FaRegUser } from "react-icons/fa6";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { GoChevronDown } from "react-icons/go";
import { cyrillicLetters } from "../components/data/cyrillicletters";
import { LoginService } from "../service/login-service";
import { toast } from "react-toastify";
import { encryptToken } from "../utils/token-encryption";
import { GlobalContext } from "../context/global-context";
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isPerson, setIsPerson] = useState(false);
  const [firstLetter, setFirstLetter] = useState("Р");
  const [secondLetter, setSecondLetter] = useState("Д");
  const [numberPart, setNumberPart] = useState("");
  const [showFirstDropdown, setShowFirstDropdown] = useState(false);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);

  const firstDropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);

  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onBlur",
    defaultValues: {
      register: "",
      password: "",
      isPerson: false,
    },
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        firstDropdownRef.current &&
        !firstDropdownRef.current.contains(event.target)
      ) {
        setShowFirstDropdown(false);
      }
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setShowSecondDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUserTypeChange = (value) => {
    setIsPerson(value);
    setNumberPart("");
    setFirstLetter("Р");
    setSecondLetter("Д");
    reset({
      register: value ? "РД" : "",
      password: "",
      isPerson: value,
    });
  };

  const updateRegistrationNumber = (first, second, numbers) => {
    if (isPerson) {
      setValue("register", first + second + numbers);
    } else {
      setValue("register", numbers);
    }
  };

  const handleFirstLetterChange = (letter) => {
    setFirstLetter(letter);
    setShowFirstDropdown(false);
    updateRegistrationNumber(letter, secondLetter, numberPart);
  };

  const handleSecondLetterChange = (letter) => {
    setSecondLetter(letter);
    setShowSecondDropdown(false);
    updateRegistrationNumber(firstLetter, letter, numberPart);
  };

  const handleNumberInput = (e) => {
    let input = e.target.value;

    if (isPerson) {
      const numbers = input.replace(/[^0-9]/g, "").slice(0, 8);
      setNumberPart(numbers);
      updateRegistrationNumber(firstLetter, secondLetter, numbers);
    } else {
      const numbers = input.replace(/[^0-9]/g, "").slice(0, 7);
      setValue("register", numbers);
    }
  };

  const onSubmit = async (value) => {
    try {
      const formData = {
        username: isPerson ? value.register : value.email,
        password: value.password,
        isPerson: value.isPerson,
      };

      const data = await LoginService.login(formData);
      if (data.status === 200) {
        toast.success("Амжилттай нэвтэрлээ");
        const encryptedToken = encryptToken(data.data, "testing");

        localStorage.setItem("tkn", encryptedToken);
        
        setUserInfo({ isLoggedIn: true, token: encryptedToken });
        navigate("/dashboard");
      } else if (data.status === 201) {
        toast.error(
          data.message || "Та нэвтрэх мэдээллээ нягтлаад дахин оролдож үзнэ үү"
        );
      }
    } catch (error) {
      toast.error(error || "Нэвтрэхэд алдаа гарлаа");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthLayout title="Нэвтрэх">
      <div className="w-full">
        <div className="flex mb-4">
          <button
            type="button"
            onClick={() => handleUserTypeChange(false)}
            className={`flex-1 py-2 ${
              isPerson === false
                ? "bg-primary text-white "
                : "bg-white border text-secondary border-base300"
            } rounded-l-md flex items-center justify-center font-medium`}
          >
            <span className="mr-2">
              <HiOutlineBuildingLibrary size={20} />
            </span>
            Байгууллага
          </button>
          <button
            type="button"
            onClick={() => handleUserTypeChange(true)}
            className={`flex-1 py-2 ${
              isPerson === true
                ? "bg-primary text-white "
                : "bg-white border text-secondary border-base300"
            } rounded-r-md flex items-center justify-center font-medium`}
          >
            <span className="mr-2">
              <FaRegUser />
            </span>
            Хувь хүн
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="block text-sm mb-1">Регистрийн дугаар</label>

            {isPerson ? (
              <div className="flex gap-2">
                {/* First letter dropdown */}
                <div className="relative" ref={firstDropdownRef}>
                  <button
                    type="button"
                    onClick={() => setShowFirstDropdown(!showFirstDropdown)}
                    className="w-14 h-12 bg-white border border-base300 rounded-md flex items-center justify-between pl-3 pr-2 focus:outline-none focus:border-primary"
                  >
                    <span className="text-lg font-medium">{firstLetter}</span>
                    <GoChevronDown
                      className={`transition-transform ${
                        showFirstDropdown ? "rotate-180" : ""
                      }`}
                    />
                  </button>

                  {showFirstDropdown && (
                    <div className="absolute z-10 mt-1 max-h-60 w-14 overflow-auto bg-white border border-base300 rounded-md shadow-lg">
                      <div className="p-1 grid grid-cols-1">
                        {cyrillicLetters.map((letter) => (
                          <button
                            key={`first-${letter}`}
                            type="button"
                            onClick={() => handleFirstLetterChange(letter)}
                            className={`px-2 py-1 text-center hover:bg-gray-100 rounded ${
                              firstLetter === letter
                                ? "bg-primary text-white hover:text-secondary"
                                : ""
                            }`}
                          >
                            {letter}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="relative" ref={secondDropdownRef}>
                  <button
                    type="button"
                    onClick={() => setShowSecondDropdown(!showSecondDropdown)}
                    className="w-14 h-12 bg-white border border-base300 rounded-md flex items-center justify-between pl-3 pr-2 focus:outline-none focus:border-primary"
                  >
                    <span className="text-lg font-medium">{secondLetter}</span>
                    <GoChevronDown
                      className={`transition-transform ${
                        showSecondDropdown ? "rotate-180" : ""
                      }`}
                    />
                  </button>

                  {showSecondDropdown && (
                    <div className="absolute z-10 mt-1 max-h-60 w-14 overflow-auto bg-white border border-base300 rounded-md shadow-lg">
                      <div className="p-1 grid grid-cols-1">
                        {cyrillicLetters.map((letter) => (
                          <button
                            key={`second-${letter}`}
                            type="button"
                            onClick={() => handleSecondLetterChange(letter)}
                            className={`px-2 py-1 text-center hover:bg-gray-100 rounded ${
                              secondLetter === letter
                                ? "bg-primary hover:text-secondary text-white"
                                : ""
                            }`}
                          >
                            {letter}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* Number input */}
                <input
                  type="text"
                  placeholder="12345678"
                  value={numberPart}
                  onChange={handleNumberInput}
                  className="flex-1 p-3 border border-base300 rounded-md focus:outline-none focus:border-primary"
                />

                <input type="hidden" {...register("register")} />
              </div>
            ) : (
              <input
                type="text"
                placeholder="7012950"
                className={`w-full p-3 border ${
                  errors.register ? "border-error" : "border-base300"
                } rounded-md focus:outline-none focus:border-primary`}
                {...register("register")}
                onChange={handleNumberInput}
              />
            )}

            {errors.register && (
              <p className="text-error text-xs mt-1">
                {errors.register.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm mb-1">
              <span className="text-error">*</span> Имэйл хаяг
            </label>
            <input
              type="email"
              placeholder="info@infinitech.mn"
              className={`w-full p-3 border ${
                errors.email ? "border-error" : "border-base300"
              } rounded-md focus:outline-none focus:border-primary`}
              {...register("email")}
            />
            {errors.email && (
              <p className="text-error text-xs mt-1">{errors.email.message}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-sm mb-1">Нууц үг</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="********"
                className={`w-full p-3 border ${
                  errors.password ? "border-error" : "border-base300"
                } rounded-md focus:outline-none focus:border-primary`}
                {...register("password")}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={togglePasswordVisibility}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-400"
                >
                  {showPassword ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  )}
                </svg>
              </button>
            </div>
            {errors.password && (
              <p className="text-error text-xs mt-1">
                {errors.password.message}
              </p>
            )}
          </div>

          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="rememberMe"
                className="mr-2 checkbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label htmlFor="rememberMe" className="text-sm">
                Нэвтрэх нэр санахуулах
              </label>
            </div>
            <Link to="/forgot-password" className="text-sm text-primary">
              Нууц үг мартсан
            </Link>
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-primary text-white rounded-md font-medium mb-4"
          >
            Нэвтрэх
          </button>

          <p className="text-center text-sm">
            Та бүртгэлгүй юу?{" "}
            <Link to="/register" className="text-primary font-medium">
              БҮРТГҮҮЛЭХ
            </Link>
          </p>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
