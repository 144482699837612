import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/global-context";

export const AdminNavbar = () => {
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useContext(GlobalContext);

  const handleLogout = () => {
    setUserInfo({ isLoggedIn: false, token: null });
    localStorage.removeItem("tkn");
    navigate("/");
  };

  return (
    <div className="bg-secondary flex sticky top-0 z-50 justify-between w-full px-4 lg:px-14 h-16 md:h-24">
      <div className="flex items-center flex-1 gap-5 lg:gap-10 h-full">
        <div className="flex items-center">
          <a href="/">
            <img
              src="/logo.png"
              alt="etender.mn logo"
              width={155}
              height={33}
              className="min-w-[155px]"
            />
          </a>
        </div>
      </div>
      <div className="hidden h-full md:flex items-center">
        <button
          type="button"
          onClick={handleLogout}
          className="btn border-error shadow-none text-neutral bg-error rounded-full lg:py-6 lg:px-10"
        >
          Системээс гарах
        </button>
      </div>
    </div>
  );
};

const menus = [
  { id: 1, name: "Тендерүүд", path: "/tenders" },
  { id: 2, name: "Компаниуд", path: "/companies" },
  { id: 3, name: "Мэдээ, судалгаа", path: "/news" },
  { id: 4, name: "Холбоо барих", path: "/contact" },
];
