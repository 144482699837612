export const BasicService = {
  getBasic(token) {
    return fetch("http://202.126.94.29:3004/api/v1/get-basic", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  },
  getOptions(token) {
    return fetch("http://202.126.94.29:3004/api/v1/get-options", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  },
  getProfile(token) {
    return fetch("http://202.126.94.29:3004/api/v1/get-profile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  },
  getOperationList() {
    return fetch("http://202.126.94.29:3004/api/v1/direction-of-actions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => res.data);
  },
  getDashboard(token) {
    return fetch("http://202.126.94.29:3004/api/v1/guest/get-dashboard", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => res.data);
  },
  getDashboardTenders(token) {
    return fetch(
      "http://202.126.94.29:3004/api/v1/guest/get-dashboard-tenders",
      {
        method: "POST",
        body: JSON.stringify({ page: 1, limit: 20 }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => res.data);
  },
  getTenders(token) {
    return fetch("http://202.126.94.29:3004/api/v1/guest/get-tenders", {
      method: "POST",
      body: JSON.stringify({ page: 1, limit: 8 }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => res.data);
  },
  getTenderDetail(token, id) {
    return fetch(`http://202.126.94.29:3004/api/v1/tender-detail/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => res.data);
  },
};
