export const provinces = [
  { id: 1, name: "Архангай" },
  { id: 2, name: "Баян-Өлгий" },
  { id: 3, name: "Баянхонгор" },
  { id: 4, name: "Булган" },
  { id: 5, name: "Говь-Алтай" },
  { id: 6, name: "Дорноговь" },
  { id: 7, name: "Дорнод" },
  { id: 8, name: "Дундговь" },
  { id: 9, name: "Завхан" },
  { id: 10, name: "Өвөрхангай" },
  { id: 11, name: "Өмнөговь" },
  { id: 12, name: "Сүхбаатар" },
  { id: 13, name: "Сэлэнгэ" },
  { id: 14, name: "Төв" },
  { id: 15, name: "Увс" },
  { id: 16, name: "Ховд" },
  { id: 17, name: "Хөвсгөл" },
  { id: 18, name: "Хэнтий" },
  { id: 19, name: "Дархан-Уул" },
  { id: 20, name: "Улаанбаатар" },
  { id: 21, name: "Орхон" },
  { id: 22, name: "Говьсүмбэр" },
];
