import { motion } from "framer-motion";
import React from "react";
import { IoIosCheckmark } from "react-icons/io";

const PlanCard = ({ plan, idx, navigate, hide }) => {
  return (
    <motion.div
      className="flex-shrink-0 w-[300px] p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300"
      whileHover={{ scale: 1.02 }}
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: idx * 0.1 }}
    >
      <div className="mb-4">
        <h2 className="text-2xl font-bold text-primary mb-2">{plan.title}</h2>
        <p className="text-neutral-600">{plan.description}</p>
      </div>
      <ul className="space-y-3 mb-4">
        {plan.contents.map((content, contentIdx) => (
          <li
            key={`benefit-${contentIdx}`}
            className="flex items-center space-x-3"
          >
            <IoIosCheckmark size={24} className="text-success flex-shrink-0" />
            <div>
              <p className="font-medium">{content.title}</p>
              <span className="text-xs text-neutral-500">
                {content.description}
              </span>
            </div>
          </li>
        ))}
      </ul>

      {/* Purchase Button */}
      {hide === false && (
        <div className="mt-2">
          <button
            onClick={async () =>
              navigate(`/dashboard/order/${encodeURIComponent(plan.id)}`)
            }
            key={idx}
            className="relative btn overflow-hidden group transition-all duration-300 ease-in-out hover:border-secondary bg-primary text-white rounded-lg border-primary btn-block hover:bg-secondary"
          >
            <span className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out translate-y-0 group-hover:translate-y-full">
              {plan.price}₮ / {plan.durationStr}
            </span>

            <span className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out translate-y-full group-hover:translate-y-0 opacity-0 group-hover:opacity-100">
              багц авах
            </span>
          </button>
        </div>
      )}
    </motion.div>
  );
};

export default PlanCard;
