import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { provinces } from "../data/provinces";
import { industries } from "../data/industries";

export const FilterSection = () => {
  const [filters, setFilters] = useState({
    province: "",
    soum: "",
    industry: "",
  });

  const provinceDropdownRef = useRef(null);
  const soumDropdownRef = useRef(null);
  const industryDropdownRef = useRef(null);

  const handleFilterChange = (filterType, value, dropdownRef) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: value,
      ...(filterType === "province" && { soum: "" }),
    }));

    if (dropdownRef.current) {
      dropdownRef.current.blur();
    }
  };

  return (
    <div className="mt-2 mb-6">
      <h2 className="text-lg font-medium mb-4">Шүүлтүүр</h2>

      {/* Province Dropdown */}
      <motion.div
        className="mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className="dropdown w-full" ref={provinceDropdownRef}>
          <label
            tabIndex={0}
            className="bg-white border rounded-lg p-4 flex items-center justify-between cursor-pointer hover:bg-gray-50 transition-colors"
          >
            <span className="text-sm text-gray-600">
              {filters.province || "Аймаг/хот"}
            </span>
            <IoIosArrowDown />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content h-48 overflow-y-auto menu flex-nowrap p-2 shadow bg-base-100 rounded-box w-full mt-1"
          >
            {provinces.map((province) => (
              <li key={province.id}>
                <button
                  className="text-sm p-3 hover:bg-gray-100"
                  onClick={() =>
                    handleFilterChange(
                      "province",
                      province.name,
                      provinceDropdownRef
                    )
                  }
                >
                  {province.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </motion.div>

      {/* Soum/District Dropdown */}
      <motion.div
        className="mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div
          className="dropdown w-full"
          ref={soumDropdownRef}
          disabled={!filters.province}
        >
          <label
            tabIndex={0}
            className={`bg-white border rounded-lg p-4 flex items-center justify-between ${
              filters.province
                ? "cursor-pointer hover:bg-gray-50"
                : "opacity-50"
            } transition-all`}
          >
            <span className="text-sm text-gray-600">
              {filters.soum || "Сум/дүүрэг"}
            </span>
            <IoIosArrowDown />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content h-48 overflow-y-auto flex-nowrap menu p-2 shadow bg-base-100 rounded-box w-full mt-1"
          >
            {filters.province &&
              mockSoums[
                provinces.find((p) => p.name === filters.province)?.id
              ].map((soum) => (
                <li key={soum}>
                  <button
                    className="text-sm p-3 hover:bg-gray-100"
                    onClick={() =>
                      handleFilterChange("soum", soum, soumDropdownRef)
                    }
                  >
                    {soum}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </motion.div>

      {/* Industry Dropdown */}
      <motion.div
        className="mb-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className="dropdown w-full" ref={industryDropdownRef}>
          <label
            tabIndex={0}
            className="bg-white border rounded-lg p-4 flex items-center justify-between cursor-pointer hover:bg-gray-50 transition-colors"
          >
            <span className="text-sm text-gray-600">
              {filters.industry || "Салбараар"}
            </span>
            <IoIosArrowDown />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content flex-nowrap h-48 overflow-y-auto menu p-2 shadow bg-base-100 rounded-box w-full mt-1"
          >
            {industries?.map((type) => (
              <li key={type}>
                <button
                  className="text-sm p-3 hover:bg-gray-100"
                  onClick={() =>
                    handleFilterChange("industry", type, industryDropdownRef)
                  }
                >
                  {type}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </motion.div>
      <div className="mt-8 hidden md:block">
        <img
          src="/zar.png"
          alt="etender.mn logo"
          className="w-full rounded-lg shadow-md"
        />
      </div>
    </div>
  );
};

const mockSoums = {
  1: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  2: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  3: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  4: ["Хутаг-Өндөр", "Сэлэнгэ", "Хангал", "Бүрэгхангай", "Дашинчилэн"],
  5: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  6: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  7: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  8: ["Хутаг-Өндөр", "Сэлэнгэ", "Хангал", "Бүрэгхангай", "Дашинчилэн"],
  9: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  10: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  11: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  12: ["Хутаг-Өндөр", "Сэлэнгэ", "Хангал", "Бүрэгхангай", "Дашинчилэн"],
  13: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  14: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  15: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  16: ["Хутаг-Өндөр", "Сэлэнгэ", "Хангал", "Бүрэгхангай", "Дашинчилэн"],
  17: ["Цэцэрлэг", "Чулуут", "Хайрхан", "Тариат", "Өндөр-Улаан"],
  18: ["Улаанхус", "Цэнгэл", "Бугат", "Алтай", "Толбо"],
  19: ["Баацагаан", "Бууцагаан", "Галуут", "Жаргалант", "Заг"],
  20: ["Баянзүрх", "Хан-Уул", "Сүхбаатар", "Баянгол", "Налайх"],
};
