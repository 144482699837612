import { useState } from "react";
import { MdOutlineConstruction } from "react-icons/md";

export const IndustryCard = ({ industry }) => {
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  return (
    <div
      onClick={() =>
        setSelectedIndustry(industry === selectedIndustry ? null : industry)
      }
      className={`
        rounded-2xl w-full border flex flex-col items-center justify-center p-2
        cursor-pointer transition-all duration-300
        hover:border-[#27C432] hover:bg-[#e8f5e9]
        ${
          selectedIndustry === industry
            ? "bg-[#27C432] border-[#27C432] text-white ring-2 ring-[#27C432] ring-opacity-40"
            : "border-gray-200 bg-white text-gray-600"
        }
      `}
    >
      <MdOutlineConstruction
        size={30}
        className={`transition-colors duration-300 ${
          selectedIndustry === industry ? "text-white" : ""
        }`}
      />
      <p className="text-center text-sm font-semibold mt-2">{industry}</p>
    </div>
  );
};
