import React from "react";
import { ContactUs } from "../components/companiesPage";
import Layout from "../layout/Layout";

const Contact = () => {
  return (
    <Layout>
      <div>
        <img
          src="/banner2.png"
          alt="etender.mn banner"
          className="w-full h-full"
        />
      </div>
      <div className="max-w-7xl container mx-auto">
        <ContactUs />
      </div>
    </Layout>
  );
};

export default Contact;
