import { useRef, useState, useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const fileTitleSchema = yup.object().shape({
  fileTitle: yup.string().required("Файлын гарчиг оруулна уу"),
});

export const FileUploadInput = ({
  socket,
  tkn,
  res,
  fileRes,
  onFilesChange,
  files: initialFiles,
}) => {
  const [files, setFiles] = useState([]);
  const [editingTitleId, setEditingTitleId] = useState(null);
  const fileInputRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(fileTitleSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (initialFiles && initialFiles.length > 0) {
      setFiles(initialFiles);
    } else if (res && res.files && Array.isArray(res.files)) {
      setFiles(res.files);
      if (onFilesChange) {
        onFilesChange(res.files);
      }
    }
  }, [res, initialFiles, onFilesChange]);

  useEffect(() => {
    if (fileRes && !files.some((file) => file.id === fileRes.id)) {
      const updatedFiles = [...files, fileRes];
      setFiles(updatedFiles);
      if (onFilesChange) {
        onFilesChange(updatedFiles);
      }
    }
  }, [fileRes, files, onFilesChange]);

  useEffect(() => {
    if (!socket) return;

    const handleFileUpdated = (data) => {
      if (data.status === "deleteFile" && data.fileId) {
        const updatedFiles = files.filter((file) => file.id !== data.fileId);
        setFiles(updatedFiles);
        if (onFilesChange) {
          onFilesChange(updatedFiles);
        }
      } else if (data.status === "fileName" && data.fileId) {
        const updatedFiles = files.map((file) =>
          file.id === data.fileId ? { ...file, title: data.value } : file
        );
        setFiles(updatedFiles);
        if (onFilesChange) {
          onFilesChange(updatedFiles);
        }
      }
    };

    socket.on("tender-file-updated", handleFileUpdated);

    return () => {
      socket.off("tender-file-updated", handleFileUpdated);
    };
  }, [socket, files, onFilesChange]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);

    try {
      const base64Files = await Promise.all(
        selectedFiles.map(async (file) => ({
          name: file.name,
          type: file.type,
          base64: await convertToBase64(file),
        }))
      );

      base64Files.forEach((file) => {
        const cleanBase64 = file.base64.replace(/^data:[^;]+;base64,/, "");
        const findIndex = file.name.lastIndexOf(".");
        const fileName =
          findIndex !== 1 ? file.name.slice(0, findIndex) : file.name;

        socket?.emit("add-tender-edit-field", {
          token: tkn,
          field: "file",
          title: fileName,
          value: cleanBase64,
        });
      });
    } catch (error) {
      console.error("Error converting files:", error);
    }

    e.target.value = "";
  };

  const removeFile = (fileId) => {
    const updatedFiles = files.filter((file) => file.id !== fileId);
    setFiles(updatedFiles);
    if (onFilesChange) {
      onFilesChange(updatedFiles);
    }

    socket?.emit("add-tender-edit-file", {
      token: tkn,
      fileId: fileId,
      status: "deleteFile",
      value: "",
    });
  };

  const startEditingTitle = (fileId) => {
    setEditingTitleId(fileId);
  };

  const submitTitle = (data) => {
    const updatedFiles = files.map((file) => {
      if (file.id === editingTitleId) {
        const updatedFile = { ...file, title: data.fileTitle };

        socket?.emit("add-tender-edit-file", {
          token: tkn,
          fileId: file.id,
          status: "fileName",
          value: data.fileTitle,
        });

        return updatedFile;
      }
      return file;
    });

    setFiles(updatedFiles);
    if (onFilesChange) {
      onFilesChange(updatedFiles);
    }
    setEditingTitleId(null);
    reset();
  };

  return (
    <div className="file-upload-container space-y-4">
      <div>
        <input
          type="file"
          ref={fileInputRef}
          multiple
          onChange={handleFileChange}
          className="hidden"
          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.xlsx,.txt, .pptx, .png, .ppt"
        />
        <button
          type="button"
          onClick={() => fileInputRef.current?.click()}
          className="btn bg-secondary border-secondary text-white px-6 py-2 rounded-md text-sm font-medium"
        >
          Файл хавсаргах
        </button>
      </div>

      {files.length > 0 && (
        <div className="border border-gray-200 rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-gray-600 font-medium text-sm w-16">
                    Өргөтгөл
                  </th>
                  <th className="px-4 py-3 text-gray-600 font-medium text-sm">
                    Файлын нэр
                  </th>
                  <th className="px-4 py-3 text-gray-600 font-medium text-sm">
                    Гарчиг
                  </th>
                  <th className="px-4 py-3 text-gray-600 font-medium text-sm w-40">
                    Үйлдэл
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {files.map((file) => (
                  <tr key={file.id} className="hover:bg-gray-50">
                    <td className="px-4 py-3">{file.ext}</td>
                    <td className="px-4 py-3 text-sm font-medium text-gray-900 truncate max-w-[200px]">
                      {file.name}
                    </td>
                    <td className="px-4 py-3">
                      {editingTitleId === file.id ? (
                        <form onSubmit={handleSubmit(submitTitle)}>
                          <div className="flex flex-col gap-1">
                            <input
                              type="text"
                              {...register("fileTitle")}
                              defaultValue={file.title}
                              className={`px-3 py-1.5 border ${
                                errors.fileTitle
                                  ? "border-red-500"
                                  : "border-gray-300"
                              } rounded-md text-sm focus:ring-2 focus:ring-green-50 focus:border-primary outline-none`}
                              autoFocus
                            />
                            {errors.fileTitle && (
                              <p className="text-red-500 text-xs">
                                {errors.fileTitle?.message}
                              </p>
                            )}
                          </div>
                        </form>
                      ) : (
                        <span
                          className={`text-sm ${
                            !file.title
                              ? "text-gray-400 italic"
                              : "text-gray-700"
                          }`}
                        >
                          {file.title || "Гарчиг оруулаагүй"}
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex gap-2 items-center">
                        {editingTitleId === file.id ? (
                          <button
                            type="button"
                            onClick={handleSubmit(submitTitle)}
                            className="px-3 py-1.5 bg-primary text-white text-sm rounded-md"
                          >
                            Хадгалах
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => startEditingTitle(file.id)}
                            className="px-3 py-1.5 bg-[#C3C3C3] border-[#C3C3C3] text-white text-sm rounded-md"
                          >
                            Засах
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => removeFile(file.id)}
                          className="px-3 py-1.5 bg-error text-white text-sm rounded-md"
                        >
                          Устгах
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

