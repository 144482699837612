import React from "react";
import { IoChevronForward } from "react-icons/io5";

export const CompanyRegistrationForm = ({
  register,
  errors,
  isLoading,
  checkRegistration,
}) => {
  return (
    <>
      <div className="mb-4">
        <label className="block text-sm mb-1">
          <span className="text-error">*</span> Регистрийн дугаар
        </label>
        <div className="flex">
          <input
            type="text"
            placeholder="7012950"
            className={`flex-grow p-3 border ${
              errors.register ? "border-error" : "border-base300"
            } rounded-l-md focus:outline-none focus:border-primary`}
            {...register("register")}
          />
          <button
            type="button"
            disabled={isLoading}
            className="bg-primary text-white px-4 rounded-r-md gap-2 flex items-center"
            onClick={checkRegistration}
          >
            Шалгах
            {isLoading ? (
              <span className="loading loading-spinner loading-xs"></span>
            ) : (
              <IoChevronForward size={20} />
            )}
          </button>
        </div>
        {errors.register && (
          <p className="text-error text-xs mt-1">{errors.register.message}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-sm mb-1">
          <span className="text-error">*</span> Байгууллагын нэр
        </label>
        <input
          type="text"
          placeholder="Инфинитек ХХК"
          disabled
          className={`w-full p-3 border ${
            errors.title ? "border-error" : "border-base300"
          } rounded-md focus:outline-none focus:border-primary`}
          {...register("title")}
        />
        {errors.title && (
          <p className="text-error text-xs mt-1">{errors.title.message}</p>
        )}
      </div>
      {/* <div className="flex mb-4 gap-4">
        <div className="flex items-center">
          <input
            type="radio"
            id="isSupplier"
            name="userType"
            value="supplier"
            defaultChecked
            className="mr-2 checkbox"
            {...register("userType")}
          />
          <label htmlFor="isSupplier" className="text-sm">
            Нийлүүлэгчээр
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            id="isCustomer"
            name="userType"
            value="customer"
            className="mr-2 checkbox"
            {...register("userType")}
          />
          <label htmlFor="isCustomer" className="text-sm">
            Захиалагчаар
          </label>
        </div>
      </div> */}
      <div className="mb-4">
        <label className="block text-sm mb-1">
          <span className="text-error">*</span> Имэйл хаяг
        </label>
        <input
          type="email"
          disabled={isLoading === true}
          placeholder="info@infinitech.mn"
          className={`w-full p-3 border ${
            errors.email ? "border-error" : "border-base300"
          } rounded-md focus:outline-none focus:border-primary`}
          {...register("email")}
        />
        {errors.email && (
          <p className="text-error text-xs mt-1">{errors.email.message}</p>
        )}
      </div>
    </>
  );
};
