import React from "react";

export const ContactUs = () => {
  return (
    <div>
      <div className="mt-20 mb-8 rounded-2xl border-2 p-8">
        <h3 className="font-bold text-xl">Бидний тухай</h3>
        <p className="text-justify mt-4">
          Эрчим хүчний зөвлөгөө өгөх чиглэлээр 1993 онд үүсгэн байгуулагдсан
          М-Си-Эс компани шилжилтийн он жилүүдэд олон салбарт бизнесийн үйл
          ажиллагаа эрхлэн, улсын нийгэм, эдийн засгийн хөгжилд хувь нэмрээ
          оруулсаар ирсэн бөгөөд өдөгөө татвар шимтгэл, хөрөнгө оруулалт, ажлын
          байр зэрэг үзүүлэлтээр тэргүүлэгч үндэсний томоохон Групп болоод
          байна. Аливаа улс орны хөгжлийг шинэ шатанд хүргэдэг дэд бүтэц, эрчим
          хүч, инженеринг, мэдээлэл, харилцаа холбоо, барилга, үл хөдлөх
          хөрөнгийн салбар, эдийн засгийг үлэмж тэтгэдэг уул уурхай, өргөн
          хэрэглээний бараа бүтээгдэхүүний салбар, нийгмийн сайн сайхан, хүний
          хөгжлийг дэмждэг эрүүл мэнд, боловсрол зэрэг чухал салбарт ажилласан
          он жилүүддээ бид тогтсон байгууллагын соёлтой, төлөвшсөн боловсон
          хүчинтэй, түүчээлэх өрсөлдөх чадвартай Групп болон тэлжээ.
        </p>
      </div>
      <div className="grid grid-cols- mb-10 sm:grid-cols-2 md:grid-cols-[0.2fr_0.2fr_0.6fr] gap-3">
        <div className="p-6 rounded-xl border border-[#D0D5DD] bg-[#EEEEEE]">
          <h4 className="text-[#787878]">Холбоо барих</h4>
          <p className="font-bold">77118811</p>
        </div>
        <div className="p-6 rounded-xl border border-[#D0D5DD] bg-[#EEEEEE]">
          <h4 className="text-[#787878]">И-мэйл</h4>
          <p className="font-bold">info@etender.mn</p>
        </div>
        <div className="p-6 rounded-xl border border-[#D0D5DD] bg-[#EEEEEE]">
          <h4 className="text-[#787878]">Холбоо Дэлгэрэнгүй хаяг</h4>
          <p className="font-bold">
            Гачууртын зам 104, Амгалан 13260, Баянзүрх дүүрэг, Улаанбаатар,
            Монгол Улс
          </p>
        </div>
      </div>
    </div>
  );
};

