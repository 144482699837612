import React from "react";

export function DashboardCompanyHeader({ detail }) {
  // Format dates for display
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("mn-MN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // Format time from dateString
  const formatTime = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleTimeString("mn-MN", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="bg-white rounded-lg border border-base300">
      <div className="grid grid-cols-1 md:grid-cols-3 p-4">
        {/* Company Info - Left Section */}
        <div className="flex items-start space-x-4">
          <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-100 flex items-center justify-center">
            {detail?.company?.company?.logo ? (
              <img
                src={detail?.company?.company?.logo}
                alt={detail?.company?.company?.name}
                className="w-full h-full object-contain"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://via.placeholder.com/64";
                }}
              />
            ) : (
              <span className="text-gray-400 text-xs text-center">No logo</span>
            )}
          </div>
          <div>
            <h2 className="font-bold text-lg">
              {detail?.company?.company?.name || "Инфитек"}
            </h2>
            <p className="text-xs text-gray-600">
              Холбоо барих: {detail?.company?.company?.phone || "80234566"}
            </p>
            <p className="text-xs text-gray-600">
              Имэйл: {detail?.company?.company?.email || "-"}
            </p>
          </div>
        </div>

        {/* Category and Status - Middle Section */}
        <div className="mt-4 md:mt-0 md:px-4">
          <div className="bg-gray-50 p-4 rounded-lg h-full flex flex-col justify-between">
            <div>
              <p className="text-sm font-medium">
                {detail?.category || "Нээлттэй тендер"}
              </p>
              <div className="mt-2 flex items-center">
                <span
                  className={`inline-block w-3 h-3 rounded-full mr-2 ${
                    detail?.status === "Нээлттэй"
                      ? "bg-green-500"
                      : "bg-yellow-500"
                  }`}
                ></span>
                <span className="text-sm">{detail?.status || "Нээлттэй"}</span>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-xs text-gray-600">Зарласан огноо:</p>
              <p className="font-medium text-sm">
                {formatDate(detail?.createdAt) || "2025/04/03"}
              </p>
            </div>
          </div>
        </div>

        {/* Deadline and Edit Button - Right Section */}
        <div className="grid grid-cols-2 gap-2 mt-4 md:mt-0">
          <div className="bg-gray-50 p-2 rounded-lg">
            <div className="text-center">
              <p className="text-sm">Эхлэх огноо</p>
              <p className="font-semibold">
                {formatDate(detail?.startDate) || "2025/04/01"}
              </p>
              <p className="font-medium text-sm">
                {formatTime(detail?.startDate) || "00:04"}
              </p>
            </div>
          </div>
          <div className="bg-gray-50 p-2 rounded-lg">
            <div className="text-center">
              <p className="text-sm">Хаах огноо</p>
              <p className="font-semibold">
                {formatDate(detail?.endDate) || "2025/04/05"}
              </p>
              <p className="font-medium text-sm">
                {formatTime(detail?.endDate) || "00:04"}
              </p>
            </div>
          </div>
          <div className="col-span-2 mt-2">
            <button className="w-full bg-primary text-white py-3 rounded-lg font-medium">
              Тендерийн мэдээлэл засах
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
