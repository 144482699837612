export const RegisterService = {
  checkRegister(data) {
    return fetch("http://202.126.94.29:3004/api/v1/check-company", {
      method: "POST",
      body: JSON.stringify({ register: data }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((d) => d.data);
  },
  sendOTP(data) {
    return fetch("http://202.126.94.29:3004/api/v1/send-otp", {
      method: "POST",
      body: JSON.stringify({ email: data }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((d) => d);
  },
  signUp(data) {
    return fetch("http://202.126.94.29:3004/api/v1/sign-up", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((d) => d);
  },
};
