import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../../context/global-context";

export const ProtectedRoute = ({ children }) => {
  const { userInfo } = useContext(GlobalContext);
 

  return userInfo.isLoggedIn === true ? <Navigate to="/dashboard" replace /> : children;
};
