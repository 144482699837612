import React, { useContext } from "react";
import { CiMenuFries } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/global-context";
import MobileSidebar from "../sidebar/MobileSidebar";

export const Navbar = () => {
  const navigate = useNavigate();
  const { userInfo, openSidebar, setOpenSidebar } = useContext(GlobalContext);

  return (
    <div className="bg-secondary flex sticky top-0 z-50 justify-between w-full px-4 lg:px-14 h-16 md:h-24">
      <div className="flex items-center flex-1 gap-5 lg:gap-10 h-full">
        <div className="flex items-center">
          <a href="/">
            <img
              src="/logo.png"
              alt="etender.mn logo"
              width={155}
              height={33}
              className="min-w-[155px]"
            />
          </a>
        </div>
        <ul className="hidden md:flex gap-4 md:gap-6 lg:gap-10 text-neutral font-semibold">
          {menus.map((menu, idx) => (
            <li key={idx} className="hover:cursor-pointer">
              <a href={menu.path}>{menu.name}</a>
            </li>
          ))}
        </ul>
      </div>
      <div className="hidden h-full md:flex items-center">
        <button
          type="button"
          onClick={() => navigate(userInfo.loggedIn ? "/dashboard" : "/login")}
          className="btn border-primary shadow-none text-neutral bg-primary rounded-full lg:py-6 lg:px-10"
        >
          {userInfo.isLoggedIn ? "Миний самбар" : "Нэвтрэх"}
        </button>
      </div>
      {/* Mobile menu */}
      <div className="flex h-full items-center">
        <button role="button" onClick={() => setOpenSidebar(!openSidebar)}>
          <CiMenuFries
            className="block text-white md:hidden stroke-1 hover:cursor-pointer"
            size={25}
          />
        </button>
      </div>
      {openSidebar && (
        <MobileSidebar
          isOpen={openSidebar}
          onClose={() => setOpenSidebar(false)}
        />
      )}
    </div>
  );
};

const menus = [
  { id: 1, name: "Тендерүүд", path: "/tenders" },
  { id: 2, name: "Компаниуд", path: "/companies" },
  { id: 3, name: "Мэдээ, судалгаа", path: "/news" },
  { id: 4, name: "Холбоо барих", path: "/contact" },
];
