import { CiSearch } from "react-icons/ci";

export const SearchBar = ({ value, onChange, onSearch }) => {
  return (
    <div className="flex items-center">
      <div className="lg:w-2/3 flex gap-3 mb-6">
        <div className="flex-1">
          <label className="relative">
            <input
              type="text"
              placeholder="Компанийн нэр"
              className="w-full input placeholder:text-base rounded-md border border-base300 focus:outline-none"
              value={value}
              onChange={onChange}
            />
            <CiSearch size={23} className="absolute top-0.5 right-2 text-gray-400"/>
          </label>
        </div>
        <button
          type="button"
          onClick={onSearch}
          className="btn bg-primary rounded-full text-neutral px-8 lg:px-14 hover:bg-primary-dark transition-colors"
        >
          Хайх
        </button>
      </div>
    </div>
  );
};
