import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowRight } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";

export const NoPlan = () => {
  const navigate = useNavigate();
  return (
    <div className="p-4 w-full h-fit text-[#A87300] flex flex-col md:flex-row justify-between items-center bg-[#FFF7E8] border border-[#FFEECE] rounded-xl">
      <h1 className="font-bold flex items-center gap-2">
        <IoWarningOutline size={25}/>
        Танд одоогоор идэвхитэй багц байхгүй байна.
      </h1>
      <button
        onClick={() => navigate("/dashboard/plans")}
        className="btn w-fit btn-ghost hover:bg-[#a8730086] rounded-xl hover:text-white hover:border-0 font-bold"
      >
        Багц худалдаж авах
        <MdOutlineArrowRight size={25}/>
      </button>
    </div>
  );
};
