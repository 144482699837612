import React, { useRef, useEffect } from "react";

export const Carousel = () => {
  const activeSlideRef = useRef(1);
  const buttonsRef = useRef([]);

  const updateButtonStyles = (newActiveSlide) => {
    activeSlideRef.current = newActiveSlide;
    buttonsRef.current.forEach((button, index) => {
      if (button) {
        if (index + 1 === activeSlideRef.current) {
          button.classList.add("bg-gray-500");
        } else {
          button.classList.remove("bg-gray-500");
        }
      }
    });
  };

  useEffect(() => {
    updateButtonStyles(1);
  }, []);

  return (
    <div className="w-full relative">
      <div className="carousel w-full">
        <div id="item1" className="carousel-item w-full">
          <img src="/banner1.jpg" alt="banner" className="w-full" />
        </div>
        <div id="item2" className="carousel-item w-full">
          <img src="/banner1.jpg" alt="banner" className="w-full" />
        </div>
        <div id="item3" className="carousel-item w-full">
          <img src="/banner1.jpg" alt="banner" className="w-full" />
        </div>
        <div id="item4" className="carousel-item w-full">
          <img src="/banner1.jpg" alt="banner" className="w-full" />
        </div>
      </div>
      <div className="flex w-full absolute bottom-14 justify-center gap-2 py-2">
        <a href="#item1">
          <button
            type="button"
            ref={(el) => (buttonsRef.current[0] = el)}
            onClick={() => updateButtonStyles(1)}
            className="h-2 w-2 btn btn-xs btn-circle"
          />
        </a>
        <a href="#item2">
          <button
            type="button"
            ref={(el) => (buttonsRef.current[1] = el)}
            onClick={() => updateButtonStyles(2)}
            className="h-2 w-2 btn btn-xs btn-circle"
          />
        </a>
        <a href="#item3">
          <button
            type="button"
            ref={(el) => (buttonsRef.current[2] = el)}
            onClick={() => updateButtonStyles(3)}
            className="h-2 w-2 btn btn-xs btn-circle"
          />
        </a>
        <a href="#item4">
          <button
            type="button"
            ref={(el) => (buttonsRef.current[3] = el)}
            onClick={() => updateButtonStyles(4)}
            className="h-2 w-2 btn btn-xs btn-circle"
          />
        </a>
      </div>
    </div>
  );
};

