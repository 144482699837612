import React, { useState, useEffect, useRef } from "react";
import { GoChevronDown } from "react-icons/go";
import { cyrillicLetters } from "../../components/data/cyrillicletters";

export const PersonalRegistrationForm = ({ register, errors, setValue }) => {
  const [firstLetter, setFirstLetter] = useState("У");
  const [secondLetter, setSecondLetter] = useState("К");
  const [numberPart, setNumberPart] = useState("");
  const [showFirstDropdown, setShowFirstDropdown] = useState(false);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);

  const firstDropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        firstDropdownRef.current &&
        !firstDropdownRef.current.contains(event.target)
      ) {
        setShowFirstDropdown(false);
      }
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setShowSecondDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    updateRegistrationNumber(firstLetter, secondLetter, numberPart);
  }, [firstLetter, secondLetter, numberPart]);

  const updateRegistrationNumber = (first, second, numbers) => {
    setValue("register", first + second + numbers);
  };

  const handleFirstLetterChange = (letter) => {
    setFirstLetter(letter);
    setShowFirstDropdown(false);
  };

  const handleSecondLetterChange = (letter) => {
    setSecondLetter(letter);
    setShowSecondDropdown(false);
  };

  const handleNumberInput = (e) => {
    const input = e.target.value;
    const numbers = input.replace(/[^0-9]/g, "").slice(0, 8);
    setNumberPart(numbers);
  };

  return (
    <>
      <div className="mb-4">
        <label className="block text-sm mb-1">
          <span className="text-error">*</span> Регистрийн дугаар
        </label>
        <div className="flex gap-2">
          {/* First letter dropdown */}
          <div className="relative" ref={firstDropdownRef}>
            <button
              type="button"
              onClick={() => setShowFirstDropdown(!showFirstDropdown)}
              className="w-14 h-12 bg-white border border-base300 rounded-md flex items-center justify-center
               p-3 focus:outline-none focus:border-primary"
            >
              <span className="text-lg font-medium">{firstLetter}</span>
            </button>

            {showFirstDropdown && (
              <div className="absolute z-10 mt-1 max-h-60 w-14 overflow-auto bg-white border border-base300 rounded-md shadow-lg">
                <div className="p-1 grid grid-cols-1">
                  {cyrillicLetters.map((letter, idx) => (
                    <button
                      key={`first-${letter}-${idx}`}
                      type="button"
                      onClick={() => handleFirstLetterChange(letter)}
                      className={`px-2 py-1 text-center hover:bg-gray-100 rounded ${
                        firstLetter === letter
                          ? "bg-primary text-white hover:text-secondary"
                          : ""
                      }`}
                    >
                      {letter}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="relative" ref={secondDropdownRef}>
            <button
              type="button"
              onClick={() => setShowSecondDropdown(!showSecondDropdown)}
              className="w-14 h-12 bg-white border border-base300 rounded-md flex items-center justify-center p-3 focus:outline-none focus:border-primary"
            >
              <span className="text-lg font-medium">{secondLetter}</span>
            </button>

            {showSecondDropdown && (
              <div className="absolute z-10 mt-1 max-h-60 w-14 overflow-auto bg-white border border-base300 rounded-md shadow-lg">
                <div className="p-1 grid grid-cols-1">
                  {cyrillicLetters.map((letter, idx) => (
                    <button
                      key={`second-${letter}-${idx}`}
                      type="button"
                      onClick={() => handleSecondLetterChange(letter)}
                      className={`px-2 py-1 text-center hover:bg-gray-100 rounded ${
                        secondLetter === letter
                          ? "bg-primary hover:text-secondary text-white"
                          : ""
                      }`}
                    >
                      {letter}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Number input */}
          <input
            type="text"
            placeholder="12345678"
            value={numberPart}
            onChange={handleNumberInput}
            className="flex-1 p-3 border border-base300 rounded-md focus:outline-none focus:border-primary"
          />

          <input type="hidden" {...register("register")} />
        </div>
        {errors.register && (
          <p className="text-error text-xs mt-1">{errors.register.message}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-sm mb-1">
          <span className="text-error">*</span> Нэр
        </label>
        <input
          type="text"
          placeholder="Бат"
          className={`w-full p-3 border ${
            errors.firstName ? "border-error" : "border-base300"
          } rounded-md focus:outline-none focus:border-primary`}
          {...register("firstName")}
        />
        {errors.firstName && (
          <p className="text-error text-xs mt-1">{errors.firstName.message}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-sm mb-1">
          <span className="text-error">*</span> Овог
        </label>
        <input
          type="text"
          placeholder="Болд"
          className={`w-full p-3 border ${
            errors.lastName ? "border-error" : "border-base300"
          } rounded-md focus:outline-none focus:border-primary`}
          {...register("lastName")}
        />
        {errors.lastName && (
          <p className="text-error text-xs mt-1">{errors.lastName.message}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-sm mb-1">
          <span className="text-error">*</span> Имэйл хаяг
        </label>
        <input
          type="email"
          placeholder="info@infinitech.mn"
          className={`w-full p-3 border ${
            errors.email ? "border-error" : "border-base300"
          } rounded-md focus:outline-none focus:border-primary`}
          {...register("email")}
        />
        {errors.email && (
          <p className="text-error text-xs mt-1">{errors.email.message}</p>
        )}
      </div>
    </>
  );
};
