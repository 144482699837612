import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { RegisterService } from "../../service/register-service";

export const OtpField = ({ value, onChange, error, onClear, data }) => {
  const inputRefs = useRef([]);
  const length = 4;

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (e, index) => {
    const newValue = e.target.value;

    if (newValue && !/^\d+$/.test(newValue)) {
      return;
    }

    const otpArray = value.split("");

    if (newValue.length > 1) {
      const pastedValue = newValue.slice(0, length);
      onChange(pastedValue.padEnd(length, "").slice(0, length));

      const focusIndex = Math.min(length - 1, pastedValue.length);
      if (inputRefs.current[focusIndex]) {
        inputRefs.current[focusIndex].focus();
      }
      return;
    }

    otpArray[index] = newValue;
    const newOtp = otpArray.join("");
    onChange(newOtp);

    if (newValue && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }

    if (error && onClear) {
      onClear();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !value[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }

    if (e.key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
    if (e.key === "ArrowRight" && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const inputs = Array.from({ length }, (_, i) => i);

  return (
    <div className="mb-4">
      <p className="text-sm text-gray-600 my-3">
        Таны имэйл хаяг руу илгээсэн 4 оронтой кодыг оруулна уу.
      </p>

      <div className="flex gap-2 justify-center">
        {inputs.map((index) => (
          <motion.input
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            type="text"
            maxLength={1}
            value={value[index] || ""}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            className={`w-12 h-12 text-center text-lg font-bold border ${
              error ? "border-error" : "border-base300"
            } rounded-md focus:outline-none focus:border-primary`}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: index * 0.05 }}
          />
        ))}
      </div>

      {error && (
        <motion.p
          className="text-error text-xs mt-2 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {error}
        </motion.p>
      )}
    </div>
  );
};
