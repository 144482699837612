import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "../utils/validations";
import AuthLayout from "../layout/AuthLayout";
import {
  UserTypeSelector,
  CompanyRegistrationForm,
  PersonalRegistrationForm,
  OtpField,
} from "../components/signupPage/";
import RobotCheckModal from "../utils/robot-check";
import { RegisterService } from "../service/register-service";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { toast } from "react-toastify";
import { encryptToken } from "../utils/token-encryption";

const Signup = () => {
  const [isPerson, setIsPerson] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showRobotCheck, setShowRobotCheck] = useState(false);
  const [isHuman, setIsHuman] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [otpError, setOtpError] = useState("");
  const [storedOtp, setStoredOtp] = useState();
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [registrationChecked, setRegistrationChecked] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(registerSchema),
    mode: "onBlur",
    defaultValues: {
      register: "",
      title: "",
      firstName: "",
      lastName: "",
      // userType: "supplier",
      email: "",
      isPerson: false,
    },
  });

  const passwordForm = useForm({
    resolver: yupResolver(
      yup.object({
        password: yup
          .string()
          .required("Нууц үг оруулна уу")
          .min(8, "Нууц үг 8-аас доошгүй тэмдэгт байна")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
            "Том, жижиг үсэг, тоо, тусгай тэмдэгт агуулсан байх ёстой"
          ),
        confirmPassword: yup
          .string()
          .required("Нууц үгээ давтан оруулна уу")
          .oneOf([yup.ref("password")], "Нууц үг таарахгүй байна"),
      })
    ),
    mode: "onBlur",
  });

  const handleSetIsUser = (value) => {
    setIsPerson(value);
    setRegistrationChecked(false);
    reset({
      register: "",
      title: "",
      firstName: "",
      lastName: "",
      // userType: "supplier",
      email: "",
      isPerson: value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!isPerson && !registrationChecked) {
      toast.error("Компанийн регистрийн дугаараа шалгана уу");
      return;
    }

    if (isHuman && otpSent) {
      handleSubmit(verifyOtpAndSubmit)();
    } else {
      setShowRobotCheck(true);
    }
  };

  const onRobotCheckSuccess = () => {
    setIsHuman(true);
    setShowRobotCheck(false);
    toast.success("Робот биш шалгалтыг давлаа");
    // handleSubmit(sendOtp)();
    const email = getValues("email");

    sendOtp(email);
  };

  const sendOtp = async (data) => {
    setIsLoading(true);

    try {
      const results = await RegisterService.sendOTP(data);
      console.log(results);

      if (results.data && results.data.otp) {
        setStoredOtp(results.data.otp);
        setOtpSent(true);

        if (results.data.message) {
          toast.success(results.message);
        } else {
          toast.success("OTP код амжилттай илгээгдлээ");
        }
        const otpModal = document.getElementById("otp_modal");

        if (otpModal && otpModal.showModal) {
          otpModal.showModal();
        } else {
          console.error("Modal element or showModal method not found");
        }
      } else {
        toast.error(results.message || "OTP код илгээхэд алдаа гарлаа");
      }

      setIsLoading(false);
    } catch (error) {
      toast.error(error.response?.message || "OTP код илгээхэд алдаа гарлаа");
      setIsLoading(false);
    }
  };

  const verifyOtpAndSubmit = async () => {
    setIsLoading(true);
    if (otpValue === storedOtp) {
      setShowPasswordForm(true);
      setIsLoading(false);
      const otpModal = document.getElementById("otp_modal");
      if (otpModal && otpModal.close) {
        otpModal.close();
      }
      toast.success("OTP код амжилттай баталгаажлаа");
    } else {
      setOtpError("OTP код буруу байна. Та дахин оролдоно уу");
      toast.error("OTP код буруу байна. Та дахин оролдоно уу");
      setIsLoading(false);
    }
  };

  const checkRegistration = async () => {
    const regNumber = getValues("register");
    console.log(regNumber);

    if (!regNumber) {
      toast.error("Бүртгэлийн дугаар оруулна уу");
      return;
    }

    setIsLoading(true);
    try {
      const result = await RegisterService.checkRegister(regNumber);
      if (result && result.name) {
        setValue("title", result.name);
        setRegistrationChecked(true);
        toast.success(
          result.message || "Бүртгэлийн дугаар амжилттай шалгагдлаа"
        );
      } else {
        setRegistrationChecked(false);
        toast.error(result.message || "Бүртгэлийн дугаар олдсонгүй");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error checking registration:", error);
      setRegistrationChecked(false);
      toast.error(
        error.response?.message || "Бүртгэлийн дугаар шалгахад алдаа гарлаа"
      );
      setIsLoading(false);
    }
  };

  const handlePasswordSubmit = async (passwordData) => {
    setIsLoading(true);
    try {
      const formData = getValues();
      const completeData = {
        ...formData,
        password: passwordData.password,
        otp: otpValue,
      };
      console.log(completeData);

      const registrationResult = await RegisterService.signUp(completeData);
      console.log({ registrationResult });
      const encryptedToken = encryptToken(
        registrationResult.data,
        "testing"
      );

      localStorage.setItem("tkn", encryptedToken);
      toast.success(
        registrationResult?.message || "Таны бүртгэл амжилттай үүслээ"
      );
      setRegistrationComplete(true);
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      console.error("Error during registration:", error);
      toast.error(error.response?.message || "Бүртгэл үүсгэхэд алдаа гарлаа");
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout
      title={
        registrationComplete
          ? "Бүртгэл амжилттай"
          : showPasswordForm
          ? "Нууц үг үүсгэх"
          : "Бүртгүүлэх"
      }
    >
      {showPasswordForm ? (
        <div className="w-full">
          <p className="font-semibold text-center text-[#808080] mb-6">
            Том, жижиг үсэг, тоо, тусгай тэмдэгт агуулсан 8-аас доошгүй байна.
          </p>

          <form onSubmit={passwordForm.handleSubmit(handlePasswordSubmit)}>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Нууц үг</label>
              <input
                type="password"
                {...passwordForm.register("password")}
                className={`w-full p-3 border rounded-md outline-none ${
                  passwordForm.formState.errors.password ? "border-red-500" : ""
                }`}
              />
              {passwordForm.formState.errors.password && (
                <p className="text-red-500 text-sm mt-1">
                  {passwordForm.formState.errors.password.message}
                </p>
              )}
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium mb-1">
                Нууц үг давтах
              </label>
              <input
                type="password"
                {...passwordForm.register("confirmPassword")}
                className={`w-full p-3 border rounded-md outline-none ${
                  passwordForm.formState.errors.confirmPassword
                    ? "border-red-500"
                    : ""
                }`}
              />
              {passwordForm.formState.errors.confirmPassword && (
                <p className="text-red-500 text-sm mt-1">
                  {passwordForm.formState.errors.confirmPassword.message}
                </p>
              )}
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-3 bg-primary text-white rounded-md font-medium mb-4"
            >
              {isLoading ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Бүртгүүлэх"
              )}
            </button>
          </form>
        </div>
      ) : (
        <div className="w-full">
          <UserTypeSelector
            isPerson={isPerson}
            onUserTypeChange={handleSetIsUser}
          />

          <form onSubmit={handleFormSubmit}>
            {isPerson ? (
              <PersonalRegistrationForm
                register={register}
                errors={errors}
                setValue={setValue}
              />
            ) : (
              <>
                <CompanyRegistrationForm
                  register={register}
                  errors={errors}
                  isLoading={isLoading}
                  checkRegistration={checkRegistration}
                />
              </>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="w-full py-3 bg-primary text-white rounded-md font-medium mb-4"
            >
              {isLoading ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Бүртгүүлэх"
              )}
            </button>

            <p className="text-center text-sm">
              Та бүртгэлтэй бол{" "}
              <Link to="/login" className="text-primary font-medium">
                НЭВТРЭХ
              </Link>
            </p>
          </form>

          {showRobotCheck && (
            <RobotCheckModal
              isOpen={showRobotCheck}
              onClose={() => setShowRobotCheck(false)}
              onSuccess={onRobotCheckSuccess}
            />
          )}

          <dialog id="otp_modal" className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg">OTP код оруулна уу</h3>
              <OtpField
                value={otpValue}
                onChange={setOtpValue}
                error={otpError}
                onClear={() => setOtpError("")}
              />
              <button
                type="button"
                onClick={() => verifyOtpAndSubmit()}
                disabled={isLoading}
                className="w-full py-3 bg-primary text-white rounded-md font-medium mb-4"
              >
                {isLoading ? (
                  <span className="loading loading-spinner loading-sm"></span>
                ) : (
                  "Баталгаажуулах"
                )}
              </button>
              {/* <div className="modal-action">
                <form method="dialog">
                  <button className="btn">Хаах</button>
                </form>
              </div> */}
            </div>
          </dialog>
        </div>
      )}
    </AuthLayout>
  );
};

export default Signup;
