import React, { useState } from "react";
import "cally";
import { industries } from "../data/industries";

export const SearchFloatingBar = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="xl:absolute xl:-bottom-9 w-full bg-neutral rounded-xl max-w-7xl border shadow-md">
      {/* Mobile Toggle Button */}
      <div className="flex justify-between items-center p-3 lg:hidden">
        <h3 className="font-medium text-gray-700">Хайлтын шүүлтүүр</h3>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="btn btn-sm bg-primary text-neutral rounded-full px-4"
        >
          {isExpanded ? "Хаах" : "Нээх"}
        </button>
      </div>

      <div className={`${isExpanded ? "block" : "hidden"} lg:block p-3 lg:p-6`}>
        <div className="flex flex-col lg:flex-row gap-3 lg:gap-4">
          {/* Search Input */}
          <div className="w-full lg:flex-1">
            <label className="block text-xs text-gray-500 mb-1 lg:hidden">
              Тэндэрийн нэр
            </label>
            <input
              type="text"
              placeholder="Тэндэрийн нэр"
              className="input bg-[#F4F4F5] border-0 w-full rounded-lg focus:outline-0"
            />
          </div>

          {/* Company Select */}
          <div className="w-full lg:flex-1">
            <label className="block text-xs text-gray-500 mb-1 lg:hidden">
              Компани
            </label>
            <select
              placeholder="Компани"
              className="select bg-[#F4F4F5] border-0 w-full rounded-lg focus:outline-0"
            >
              {mockCompanies.map((company, index) => (
                <option key={index}>{company}</option>
              ))}
            </select>
          </div>

          {/* Tender Type Select */}
          <div className="w-full lg:flex-1">
            <label className="block text-xs text-gray-500 mb-1 lg:hidden">
              Үйл ажиллагааны төрөл
            </label>
            <select className="select bg-[#F4F4F5] border-0 w-full rounded-lg focus:outline-0">
              {industries?.map((type, index) => (
                <option key={index}>{type}</option>
              ))}
            </select>
          </div>

          {/* Date Picker */}
          <div className="w-full lg:flex-1">
            <label className="block text-xs text-gray-500 mb-1 lg:hidden">
              Огноо
            </label>
            <input
              type="date"
              className="input bg-[#F4F4F5] border-0 w-full rounded-lg focus:outline-none focus:ring-0"
            />
          </div>

          {/* Search Button */}
          <div className="w-full lg:w-auto mt-2 lg:mt-0">
            <button
              type="button"
              className="btn bg-primary rounded-full text-neutral w-full lg:w-auto px-8 lg:px-14 hover:bg-primary-dark transition-colors"
            >
              Хайх
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mockCompanies = [
  "Компани",
  "Барилгын Корпорац",
  "Дорнын Электроникс",
  "Монгол Уул Уурхай",
  "Техник Хангамж ХХК",
  "Цахилгаан Сүлжээ",
  "Багаж Тоног ХХК",
  "Шинэ Үйлчилгээ",
  "Хөгжүүлэлт ХХК",
  "Эко Дизэйн",
  "Улаанбаатар Зам",
];
