import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation, Link } from "react-router-dom";
import {
  RiCloseLine,
  RiHome2Line,
  RiBuilding2Line,
  RiNewspaperLine,
  RiPhoneLine,
} from "react-icons/ri";

const MobileSidebar = ({ isOpen, onClose }) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);

  const menus = [
    { id: 1, name: "Тендерүүд", path: "/tenders", icon: RiHome2Line },
    { id: 2, name: "Компаниуд", path: "/companies", icon: RiBuilding2Line },
    { id: 3, name: "Мэдээ, судалгаа", path: "/news", icon: RiNewspaperLine },
    { id: 4, name: "Холбоо барих", path: "/contact", icon: RiPhoneLine },
  ];

  useEffect(() => {
    const currentMenu = menus.find((menu) => menu.path === location.pathname);
    if (currentMenu) {
      setActiveMenu(currentMenu.id);
    }
  }, [location.pathname]);

  const sidebarVariants = {
    closed: {
      x: "200%",
      opacity: 0,
      transition: { duration: 0.5, ease: "easeOut" },
    },
    open: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeIn" },
    },
  };

  const menuItemVariants = {
    closed: { x: -20, opacity: 0 },
    open: (i) => ({
      x: 0,
      opacity: 1,
      transition: { delay: 0.1 + i * 0.1, duration: 0.4 },
    }),
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-50 bg-base-200 w-full md:max-w-[50%] h-screen overflow-hidden"
          initial="closed"
          animate="open"
          exit="closed"
          variants={sidebarVariants}
        >
          <div className="flex flex-col h-full">
            {/* Header */}
            <div className="flex justify-between items-center p-6 border-b border-base300/20">
              <h1 className="text-2xl font-bold text-black">Menu</h1>
              <motion.button
                whileTap={{ scale: 0.9 }}
                onClick={onClose}
                className="p-2 rounded-full bg-base-300 hover:bg-primary transition-colors"
                aria-label="Close menu"
              >
                <RiCloseLine className="text-2xl text-black" />
              </motion.button>
            </div>

            {/* Menu Items */}
            <div className="flex-1 overflow-y-auto py-8 px-6">
              <motion.nav>
                <ul className="space-y-4">
                  {menus.map((menu, index) => (
                    <motion.li
                      key={menu.id}
                      custom={index}
                      variants={menuItemVariants}
                    >
                      <Link
                        to={menu.path}
                        onClick={() => {
                          setActiveMenu(menu.id);
                          onClose();
                        }}
                      >
                        <motion.div
                          className={`flex items-center p-4 rounded-xl transition-all ${
                            activeMenu === menu.id
                              ? "bg-primary text-neutral shadow-md"
                              : "bg-base-200 hover:bg-base-300 text-black"
                          }`}
                          whileHover={{
                            scale: 1.02,
                            transition: { duration: 0.2 },
                          }}
                          whileTap={{ scale: 0.98 }}
                        >
                          <menu.icon
                            className={`text-2xl ${
                              activeMenu === menu.id
                                ? "text-neutral"
                                : "text-primary"
                            }`}
                          />
                          <span className="ml-4 font-medium">{menu.name}</span>
                          {activeMenu === menu.id && (
                            <motion.div
                              className="ml-auto w-1.5 h-8 bg-accent rounded-full"
                              layoutId="activeIndicator"
                            />
                          )}
                        </motion.div>
                      </Link>
                    </motion.li>
                  ))}
                </ul>
              </motion.nav>
            </div>

            {/* Footer */}
            <motion.div
              className="p-6 border-t border-base300/20"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <div className="flex items-center p-4 bg-secondary rounded-lg">
                <div className="w-12 h-12 rounded-full bg-primary flex items-center justify-center">
                  <img src="" alt="" />
                </div>
                <div className="ml-4">
                  <p className="text-neutral font-medium">Хэрэглэгч</p>
                  <p className="text-base300 text-sm">mcs@example.com</p>
                </div>
                <motion.button
                  className="ml-auto bg-base100 p-2 rounded-full"
                  whileHover={{ rotate: 180, transition: { duration: 0.5 } }}
                >
                  <RiCloseLine className="text-neutral" />
                </motion.button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileSidebar;
