import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { OtpInput } from "./";
import { ConfirmService } from "../../service/confirm-account";
import { GlobalContext } from "../../context/global-context";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneUpdateSchema } from "../../utils/validations";

export const PhoneVerification = ({ initialPhone }) => {
  const [phoneMode, setPhoneMode] = useState("display");
  const [phoneInput, setPhoneInput] = useState(initialPhone);
  const [otpValue, setOtpValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContext(GlobalContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(phoneUpdateSchema),
    defaultValues: {
      phoneNumber: initialPhone,
    },
  });

  const isAutoVerifyNumber = (phone) => {
    return phone.length === 8 && /^7[0-9]/.test(phone);
  };

  const isInvalidFirstDigit = (phone) => {
    return phone.length === 8 && /^[0-4]/.test(phone);
  };

  const onSubmit = (data) => {
    handleSendOtp();
  };

  const handleSendOtp = async () => {
    try {
      if (isInvalidFirstDigit(phoneInput)) {
        toast.error("Утасны дугаар 5-аас их цифрээр эхлэх ёстой");
        return;
      }
      setIsLoading(true);
      if (isAutoVerifyNumber(phoneInput)) {
        toast.success("Утасны дугаар амжилттай шиэнчлэгдлээ");
        setPhoneMode("display");
        setIsLoading(false);
        return;
      }

      try {
        const response = await ConfirmService.sendOTP(
          phoneInput,
          userInfo.token
        );

        if (response.status === 200) {
          setIsLoading(false);
          setPhoneInput(phoneInput);
          setPhoneMode("otp");
          toast.success("OTP код илгээгдлээ");
        }

        if (response.status === 201) {
          toast.error(
            response.message ||
              "OTP илгээхэд алдаа гарлаа. Та дахин оролдоно уу"
          );
          setPhoneMode("display");
        }
      } catch (error) {
        console.error("Error sending OTP to phone", error);
      }
    } catch (error) {
      toast.error("OTP илгээхэд алдаа гарлаа");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      setIsLoading(true);
      const response = await ConfirmService.verifyOTP(
        { otp: otpValue },
        userInfo.token
      );
      if (response.status === 200) {
        toast.success(
          response.message || "Утасны дугаар амжилттай шиэнчлэгдлээ"
        );
        setOtpValue("");
        setPhoneMode("display");
      } else if (response.success === false) {
        toast.error(
          response.message || "OTP код буруу байна. Та дахин оруулна уу."
        );
        setOtpValue("");
      }
    } catch (error) {
      toast.error("Баталгаажуулахад алдаа гарлаа");
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setValue("phoneNumber", phoneInput);
  }, [phoneInput, setValue]);

  return (
    <div>
      <label className="label text-xs text-black">Утасны дугаар</label>
      {phoneMode === "display" ? (
        <div className="flex items-center mt-2 space-x-4">
          <input
            type="text"
            value={phoneInput}
            className="input w-full rounded-lg bg-[#F9F9F9] text-[#808080] font-semibold border-[#D5D5D6] focus:outline-none focus:border-primary focus:ring-0"
            readOnly
          />
          <button
            type="button"
            onClick={() => setPhoneMode("edit")}
            className="btn bg-secondary font-medium border-secondary text-white rounded-lg"
          >
            Засварлах
          </button>
        </div>
      ) : phoneMode === "edit" ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex items-center mt-2 space-x-4">
            <div className="w-full">
              <input
                type="text"
                {...register("phoneNumber")}
                value={phoneInput}
                onChange={(e) => setPhoneInput(e.target.value)}
                className={`input w-full rounded-lg text-[#808080] font-semibold focus:outline-none bg-[#F9F9F9] border-[#D5D5D6] focus:border-primary focus:ring-0 ${
                  errors.phoneNumber ? "border-red-500" : ""
                }`}
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="btn bg-secondary border-secondary text-white rounded-lg"
            >
              {isLoading
                ? "Илгээж байна..."
                : isAutoVerifyNumber(phoneInput)
                ? "Баталгаажуулах"
                : "OTP илгээх"}
            </button>
          </div>
        </form>
      ) : (
        <div className="flex items-center mt-2 space-x-4">
          <OtpInput value={otpValue} onChange={setOtpValue} numInputs={4} />
          <button
            type="button"
            onClick={handleVerifyOtp}
            disabled={isLoading || otpValue.length !== 4}
            className="btn bg-secondary border-secondary text-white rounded-lg"
          >
            {isLoading ? "Баталгаажуулж байна..." : "Баталгаажуулах"}
          </button>
        </div>
      )}
    </div>
  );
};
