import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiChevronDown, FiChevronRight, FiX, FiSearch } from "react-icons/fi";

export const OperationSelector = ({
  value = "",
  onChange,
  onBlur,
  error,
  className = "",
  operationList,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (value && typeof value === "string") {
      const tags = value
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag);
      setSelectedTags(tags);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        if (onBlur) onBlur();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onBlur]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedTags.join(", "));
    }
  }, [selectedTags, onChange]);

  const addTag = (tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const removeTag = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };

  const filteredCategories = operationList.filter((category) =>
    category.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={`relative w-full ${className}`} ref={dropdownRef}>
      {/* Input field */}
      <div
        className={`input input-bordered rounded-lg bg-[#F9F9F9] border-[#D5D5D6] focus:ring-0 w-full focus:outline-0 min-h-fit flex flex-wrap items-center gap-2 p-2 cursor-text ${
          error ? "input-error" : ""
        }`}
        onClick={() => {
          setIsOpen(true);
          if (inputRef.current) inputRef.current.focus();
        }}
      >
        {/* Selected tags */}
        {selectedTags.map((tag, index) => (
          <div
            key={index}
            className="bg-[#ececec] rounded-md pl-2 text-xs flex items-center gap-1"
          >
            {tag}
            <button
              type="button"
              className="btn btn-ghost cursor-pointer btn-xs btn-circle z-10"
              onClick={(e) => {
                e.stopPropagation();
                removeTag(tag);
              }}
            >
              <FiX />
            </button>
          </div>
        ))}

        <input
          ref={inputRef}
          type="text"
          className="flex-grow bg-transparent border-none focus:outline-0 focus:ring-0 p-1 text-sm min-w-[50px]"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={
            selectedTags.length === 0 ? "Үйл ажиллагааны чиглэл сонгоно уу" : ""
          }
          onFocus={() => setIsOpen(true)}
        />

        <button
          type="button"
          className="btn btn-ghost absolute btn-sm btn-circle right-0 z-10"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <FiChevronDown
            className={`transition-transform ${isOpen ? "rotate-180" : ""}`}
          />
        </button>
      </div>

      {/* Error message */}
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}

      {/* Dropdown */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-30 mt-1 w-full bg-white rounded-lg shadow-lg border border-gray-200 max-h-96 overflow-auto"
          >
            {/* Search bar in dropdown */}
            <div className="sticky top-0 bg-white p-2 border-b flex items-center">
              <FiSearch className="text-gray-400 mr-2" />
              <input
                type="text"
                className="w-full bg-transparent border-none focus:outline-0 text-sm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Хайх..."
                autoFocus
              />
            </div>

            {/* Category list */}
            <div className="p-2">
              {filteredCategories.length > 0 ? (
                filteredCategories.map((category, index) => (
                  <div key={index} className="mb-1">
                    <button
                      type="button"
                      className="flex items-center w-full text-left p-2 hover:bg-gray-100 rounded-md transition-colors"
                      onClick={() =>
                        setSelectedCategory(
                          selectedCategory === category.title
                            ? null
                            : category.title
                        )
                      }
                    >
                      <FiChevronRight
                        className={`mr-2 transition-transform ${
                          selectedCategory === category.title ? "rotate-90" : ""
                        }`}
                      />
                      <span>{category.title}</span>
                    </button>

                    {/* Subcategories */}
                    <AnimatePresence>
                      {selectedCategory === category.title && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          className="pl-8 overflow-hidden"
                        >
                          {category.childs.map((child, childIndex) => (
                            <div
                              key={childIndex}
                              className="flex items-center p-2 hover:bg-gray-100 rounded-md cursor-pointer"
                              onClick={() => {
                                addTag(child.title);
                                setSearchTerm("");
                              }}
                            >
                              <span className="text-sm">{child.title}</span>
                            </div>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ))
              ) : (
                <div className="p-4 text-center text-gray-500">
                  Үр дүн олдсонгүй
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
