import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileEditSchema } from "../../utils/validations";
import { MdOutlineArrowRight } from "react-icons/md";
import { FormField, PhoneVerification } from "./";

export const ProfileForm = ({
  profile,
  setProfile,
  editMode,
  setEditMode,
  initialPhone,
  token,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileEditSchema),
    defaultValues: profile,
  });

  const onSubmit = (data) => {
    setProfile((prevData) => ({
      ...prevData,
      ...data,
    }));
    setEditMode(false);
  };

  return (
    <form
      className="col-span-4 grid grid-cols-1 md:grid-cols-2 gap-x-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="space-y-4">
        <FormField
          label="Компанийн нэр"
          name="name"
          control={control}
          errors={errors}
          editMode={false}
          profile={profile}
        />
        <FormField
          label="Компанийн регистр"
          name="register"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />
        <FormField
          label="Компани эзэмшигчийн овог"
          name="lastName"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />
        <FormField
          label="Компани эзэмшигчийн нэр"
          name="firstName"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />
        <FormField
          label="Компанийн и-мэйл хаяг"
          name="email"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />
        <PhoneVerification token={token} initialPhone={initialPhone} />
      </div>

      <div className="space-y-4">
        <FormField
          label="Банк сонгох"
          name="bank"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />

        <FormField
          label="Дансны дугаар"
          name="accountNumber"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />

        <FormField
          label="Хот/Аймаг"
          name="city"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />

        <FormField
          label="Дүүрэг/Сум"
          name="district"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />

        <FormField
          label="Хороо"
          name="khoroo"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />

        <FormField
          label="Дэлгэрэнгүй хаяг"
          name="address"
          control={control}
          errors={errors}
          editMode={editMode}
          profile={profile}
        />
      </div>

      <div className="col-span-1 md:col-span-2 flex justify-end mt-4">
        <button
          type="button"
          onClick={() => {
            if (editMode) {
              handleSubmit(onSubmit)();
            } else {
              setEditMode(true);
            }
          }}
          className="btn bg-primary hover:bg-primary-focus border-primary font-medium text-white rounded-md"
        >
          {editMode ? "Хадгалах" : "Засварлах"}
          <MdOutlineArrowRight className="w-5 h-5 ml-4" />
        </button>
      </div>
    </form>
  );
};
