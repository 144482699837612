import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const CompanyCard = ({ tender }) => {
    const navigate = useNavigate();
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.2 }}
      onClick={() => navigate(`/companies/${tender.company}`)}
      className="bg-white rounded-lg hover:cursor-pointer overflow-hidden hover:shadow-sm border"
    >
      <div className="flex p-4 items-center">
        <div className="mr-4">
          <div className="w-12 h-12 rounded-full flex items-center justify-center">
            <img
              src="/mcslogo.png"
              alt="Company Logo"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div>
          <h3 className="font-medium text-sm">{tender.company}</h3>
          <div className="flex items-center text-xs mt-1">
            <div className="flex items-center">
              <span className="text-yellow-500">★</span>
              <span className="text-yellow-500">★</span>
              <span className="text-yellow-500">★</span>
              <span className="text-yellow-500">★</span>
              <span className="text-yellow-500">★</span>
            </div>
            <span className="ml-1 text-gray-600">{tender.rating}</span>
            <span className="ml-1 text-gray-400">({tender.reviews})</span>
          </div>
        </div>
      </div>
      <div className="bg-base200 p-3 text-xs text-gray-600 flex items-center justify-between">
        <span>{tender.deadline}</span>
        <button className="bg-[#CBF3C0] border border-[#72CF58] text-black text-xs px-3 py-1 rounded-md hover:text-white hover:bg-primary transition-colors">
          Дэлгэрэнгүй
        </button>
      </div>
    </motion.div>
  );
};
