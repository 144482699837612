import React from "react";
import { NewsCard } from "../components/newsPage/NewsCard";
import Layout from "../layout/Layout";

const News = () => {
  return (
    <Layout>
      <div className="w-full">
        <div className="h-44">
          <img
            src="/banner2.png"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>

        <div className="mx-auto mt-8 mb-10 container max-w-7xl px-5">
          <h2 className="font-bold text-xl mb-5">Мэдээ мэдээлэл, судалгаа</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-3">
            {datas?.map((data, idx) => (
              <NewsCard data={data} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default News;

const datas = [
  {
    id: 1,
    title: "ХҮҮХЭД ЗАЛУУЧУУДЫН ТЕАТРЫН БУФЕТ ТҮРЭЭСЛЭХ АЖ АХУЙН НЭГЖИЙГ СОНГОН",
    date: "2025/03/20",
    image: "/banner4.png",
  },
  {
    id: 2,
    title: "ХҮҮХЭД ЗАЛУУЧУУДЫН ТЕАТРЫН БУФЕТ ТҮРЭЭСЛЭХ АЖ АХУЙН НЭГЖИЙГ СОНГОН",
    date: "2025/03/20",
    image: "/banner4.png",
  },
  {
    id: 3,
    title: "ХҮҮХЭД ЗАЛУУЧУУДЫН ТЕАТРЫН БУФЕТ ТҮРЭЭСЛЭХ АЖ АХУЙН НЭГЖИЙГ СОНГОН",
    date: "2025/03/20",
    image: "/banner4.png",
  },
  {
    id: 4,
    title: "ХҮҮХЭД ЗАЛУУЧУУДЫН ТЕАТРЫН БУФЕТ ТҮРЭЭСЛЭХ АЖ АХУЙН НЭГЖИЙГ СОНГОН",
    date: "2025/03/20",
    image: "/banner4.png",
  },
  {
    id: 5,
    title: "ХҮҮХЭД ЗАЛУУЧУУДЫН ТЕАТРЫН БУФЕТ ТҮРЭЭСЛЭХ АЖ АХУЙН НЭГЖИЙГ СОНГОН",
    date: "2025/03/20",
    image: "/banner4.png",
  },
  {
    id: 6,
    title: "ХҮҮХЭД ЗАЛУУЧУУДЫН ТЕАТРЫН БУФЕТ ТҮРЭЭСЛЭХ АЖ АХУЙН НЭГЖИЙГ СОНГОН",
    date: "2025/03/20",
    image: "/banner4.png",
  },
  {
    id: 7,
    title: "ХҮҮХЭД ЗАЛУУЧУУДЫН ТЕАТРЫН БУФЕТ ТҮРЭЭСЛЭХ АЖ АХУЙН НЭГЖИЙГ СОНГОН",
    date: "2025/03/20",
    image: "/banner4.png",
  },
];
