import React from "react";
import { Controller } from "react-hook-form";

export const FormField = ({
  label,
  name,
  control,
  errors,
  editMode,
  profile,
}) => {
  return (
    <div>
      <label className="label text-black text-xs mb-2">{label}</label>
      {editMode ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <input
                type="text"
                value={value || ""}
                onChange={onChange}
                autoFocus={name === "name"}
                className="input input-bordered w-full rounded-lg font-semibold focus:outline-0 focus:ring-2  focus:border-primary ring-green-100"
              />
              {errors[name] && (
                <p className="text-error text-xs mt-1">
                  {errors[name].message}
                </p>
              )}
            </div>
          )}
        />
      ) : (
        <input
          type="text"
          value={profile[name] || ""}
          readOnly
          className={`input w-full bg-[#F9F9F9] rounded-lg text-[#808080] font-semibold border-[#D5D5D6] cursor-default focus:outline-none focus:border-primary focus:ring-0`}
        />
      )}
    </div>
  );
};
