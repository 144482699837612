import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { DropdownSelect } from "./";

export const AddressSection = ({
  register,
  errors,
  setValue,
  watch,
  options,
  selectedCity,
  selectedDistrict,
  districts,
}) => {
  const handleSelectItem = (fieldName, value) => {
    setValue(fieldName, value, { shouldValidate: true });
  };
  return (
    <div>
      {/* <h2 className="font-medium mb-2">Хаягийн мэдээлэл</h2> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4">
        <div className="mb-2 md:mb-0">
          <label className="block text-sm font-medium mb-1">
            Аймаг/Хот <span className="text-error">*</span>
          </label>
          <DropdownSelect
            options={options?.aimags || []}
            placeholder="Аймаг/Хот"
            value={watch("city")}
            onChange={(value) => handleSelectItem("city", value)}
            error={errors.city}
          />
          {errors.city && (
            <p className="text-error text-xs mt-1">{errors.city.message}</p>
          )}
        </div>

        <div className="">
          <label className="block text-sm font-medium mb-1">
            Сум дүүрэг <span className="text-error">*</span>
          </label>
          <div className="dropdown w-full">
            <div
              tabIndex={0}
              role="button"
              className={`w-full flex justify-between items-center border ${
                errors.district ? "border-error" : "border-base-300"
              } rounded-md p-3`}
              style={{ opacity: selectedCity ? 1 : 0.6 }}
            >
              <span>{watch("district") || "Сум дүүрэг"}</span>
              <IoMdArrowDropdown />
            </div>
            {selectedCity && (
              <ul
                tabIndex={0}
                className="dropdown-content flex-nowrap z-30 menu bg-base-100 rounded-box w-full mt-1 p-2 shadow-md max-h-40 overflow-y-auto"
              >
                {districts?.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleSelectItem("district", item.title)}
                    className="text-sm py-2"
                  >
                    {item.title}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {errors.district && (
            <p className="text-error text-xs mt-1">{errors.district.message}</p>
          )}
        </div>
        <div>
          <div className="md:mt-0 mt-2">
            <label className="block text-sm font-medium mb-1">
              Баг/Хороо <span className="text-error">*</span>
            </label>
            <div className="dropdown w-full">
              <input
                type="text"
                style={{ opacity: selectedDistrict ? 1 : 0.6 }}
                placeholder="1-р баг эсвэл 1-р хороо"
                className={`w-full p-3 border ${
                  errors.khoroo ? "border-error" : "border-base-300"
                } rounded-md focus:outline-none focus:border-primary`}
                {...register("khoroo")}
              />
            </div>
            {errors.khoroo && (
              <p className="text-error text-xs mt-1">{errors.khoroo.message}</p>
            )}
          </div>
        </div>
        <div className="mt-2 block md:hidden">
          <label className="block text-sm font-medium mb-1">
            Дэлгэрэнгүй хаяг <span className="text-error">*</span>
          </label>
          <input
            type="text"
            className={`w-full p-3 border ${
              errors.address ? "border-error" : "border-base-300"
            } rounded-md focus:outline-none focus:border-primary`}
            placeholder="Олимпийн гудамж, Олимп хотхон, 1-р байр, 1-р орц, 201 тоот"
            {...register("address")}
          />
          {errors.address && (
            <p className="text-error text-xs mt-1">{errors.address.message}</p>
          )}
        </div>
      </div>
      <div className="flex gap-4">
        <div className="mt-2 hidden w-full md:block">
          <label className="block text-sm font-medium mb-1">
            Дэлгэрэнгүй хаяг <span className="text-error">*</span>
          </label>
          <input
            type="text"
            className={`w-full p-3 border ${
              errors.address ? "border-error" : "border-base-300"
            } rounded-md focus:outline-none focus:border-primary`}
            placeholder="Олимпийн гудамж, Олимп хотхон, 1-р байр, 1-р орц, 201 тоот"
            {...register("address")}
          />
          {errors.address && (
            <p className="text-error text-xs mt-1">{errors.address.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};
