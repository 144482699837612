import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/global-context";
import {
  FileUploadInput,
  CustomField,
  OperationSelector,
} from "../components/dashboardPage/";
import { toast } from "react-toastify";
import { MdOutlineArrowRight } from "react-icons/md";
import { step1Schema } from "../utils/validations";
import { BasicService } from "../service/get-basic";
import { AddTenderSkeleton } from "../components/skeleton/AddTenderSkeleton";
import { step2Schema } from "../utils/validations";

const formatDateForInput = (dateString) => {
  if (!dateString) return "";
  return new Date(dateString).toISOString().split("T")[0];
};

const AddTender = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [res, setRes] = useState();
  const [fileRes, setFileRes] = useState();
  const [fileValidationError, setFileValidationError] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const { socket, userInfo } = useContext(GlobalContext);
  const initialized = useRef(false);
  const currentSocket = useRef(socket);
  const [tenderId, setTenderId] = useState(-1);
  const [operationList, setOperationList] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCurrentSchema = () => {
    switch (step) {
      case 0:
        return step1Schema;
      case 1:
        return step2Schema;
      case 2:
        return yup.object().shape({});
      default:
        return step1Schema;
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(getCurrentSchema()),
    mode: "onChange",
  });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchData = useCallback(async () => {
    try {
      if (!socket) return;
      await delay(1000);
      socket?.emit("new-tender", {
        token: userInfo.token,
      });
    } catch (err) {
      console.error("err: ", err);
    }
  }, [socket, userInfo]);

  useEffect(() => {
    if (!socket || (currentSocket.current === socket && initialized.current))
      return;
    initialized.current = true;
    currentSocket.current = socket;
    fetchData();
  }, [socket, fetchData]);

  useEffect(() => {
    if (!socket) return;

    const listenTenderOn = async (data) => {
      if ((data?.id ?? -1) < 0) {
        toast.error("Тендер оруулах эрхгүй байна");
        navigate(-1);
        return;
      }
      setRes(data);
      setCustomFields(data.requirements);
      if (data.id) {
        setTenderId(data.id);
      }
      if (data.files && Array.isArray(data.files)) {
        setFiles(data.files);
        setValue("files", data.files);
      }
      setIsLoading(false);
    };

    const listenTenderFileOn = async (data) => {
      setFileRes(data);
      if (data && !files.some((file) => file.id === data.id)) {
        const updatedFiles = [...files, data];
        setFiles(updatedFiles);
        setValue("files", updatedFiles);
      }
    };

    const listenDynamicFieldOn = async (data) => {
      if (data.status === "edit") {
        const existingFieldIndex = customFields.findIndex(
          (field) => field.id === data.fieldId
        );

        if (existingFieldIndex >= 0) {
          const updatedFields = [...customFields];
          updatedFields[existingFieldIndex] = {
            id: data.fieldId,
            title: data.value,
          };
          setCustomFields(updatedFields);
        } else {
          setCustomFields([
            ...customFields,
            {
              id: data.fieldId || Date.now(),
              title: data.value,
            },
          ]);
        }
      } else if (data.status === "delete") {
        setCustomFields(
          customFields.filter((field) => field.id !== data.fieldId)
        );
      }
    };

    const listenTenderCreateOn = async (data) => {
      if (data === true) {
        toast.success("Тендер амжилттай үүслээ");
        navigate("/dashboard/tenders");
      }
    };

    const handleFileUpdated = (data) => {
      if (data.status === "deleteFile" && data.fileId) {
        const updatedFiles = files.filter((file) => file.id !== data.fileId);
        setFiles(updatedFiles);
        setValue("files", updatedFiles);
      } else if (data.status === "fileName" && data.fileId) {
        const updatedFiles = files.map((file) =>
          file.id === data.fileId ? { ...file, title: data.value } : file
        );
        setFiles(updatedFiles);
        setValue("files", updatedFiles);
      }
    };

    socket.on("new-tender-on", listenTenderOn);
    socket.on("new-tender-file-on", listenTenderFileOn);
    socket.on("add-tender-dynamic-field-on", listenDynamicFieldOn);
    socket.on("add-tender-create-on", listenTenderCreateOn);
    socket.on("tender-file-updated", handleFileUpdated);

    const getOperations = async () => {
      const response = await BasicService.getOperationList();
      setOperationList(response);
    };

    getOperations();

    return () => {
      socket.off("new-tender-on", listenTenderOn);
      socket.off("new-tender-file-on", listenTenderFileOn);
      socket.off("add-tender-dynamic-field-on", listenDynamicFieldOn);
      socket.off("add-tender-create-on", listenTenderCreateOn);
      socket.off("tender-file-updated", handleFileUpdated);
    };
  }, [socket, customFields, navigate, files]);

  useEffect(() => {
    if (!res) return;
    const fields = [
      "name",
      "title",
      "category",
      "totalBudget",
      "tags",
      "cost",
      "funding",
    ];
    fields.forEach((field) => {
      if (res[field] !== undefined) {
        setValue(field, res[field]);
      }
    });

    if (res.endDate) {
      setValue("endDate", formatDateForInput(res.endDate));
    }
    if (res.startDate) {
      setValue("startDate", formatDateForInput(res.startDate));
    }
  }, [res, setValue]);

  const onSubmit = () => {
    socket?.emit("add-tender-create", {
      token: userInfo.token,
      tenderId: tenderId,
    });
  };

  const handleNext = async () => {
    let isValid = true;
    if (step === 0) {
      isValid = await trigger();
    } else if (step === 1) {
      if (files.length === 0) {
        setFileValidationError("Дор хаяж нэг файл оруулах шаардлагатай");
        return;
      } else {
        setFileValidationError("");
      }
    }

    if (isValid) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setFileValidationError("");
    setStep((prevStep) => prevStep - 1);
  };

  const renderInput = (key) => {
    const inputType = key.includes("Date")
      ? "date"
      : typeof res?.[key] === "number"
      ? "number"
      : "text";

    return (
      <div>
        <input
          type={inputType}
          {...register(key)}
          className={`input input-bordered rounded-lg bg-[#F9F9F9] border-[#D5D5D6] w-full ${
            errors[key] ? "input-error" : ""
          }`}
          onBlur={(e) => {
            const value =
              inputType === "date" && e.target.value
                ? e.target.value
                : e.target.value;

            socket?.emit("add-tender-edit-field", {
              field: key,
              value: value,
              token: userInfo.token,
            });
          }}
        />
        {errors[key] && (
          <p className="text-red-500 text-xs mt-1">{errors[key]?.message}</p>
        )}
      </div>
    );
  };

  if (isLoading) {
    return <AddTenderSkeleton />;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="w-full mb-8">
        <ul className="steps steps-vertical text-sm lg:steps-horizontal w-full">
          <li className={`step ${step >= 0 ? "step-primary" : ""}`}>
            Үндсэн мэдээлэл
          </li>
          <li className={`step ${step >= 1 ? "step-primary" : ""}`}>
            Файл хавсаргах
          </li>
          <li className={`step ${step >= 2 ? "step-primary" : ""}`}>
            Нэмэлт талбар
          </li>
        </ul>
      </div>

      <div className="card bg-base-100">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            {step === 0 && res && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="form-control">
                  <label className="label text-xs text-black font-medium mb-2">
                    Компанийн нэр
                  </label>
                  {renderInput("name")}
                </div>
                <div className="form-control">
                  <label className="label text-xs text-black font-medium mb-2">
                    Тендерийн нэр
                  </label>
                  {renderInput("title")}
                </div>
                <div className="form-control">
                  <label className="label text-xs text-black font-medium mb-2">
                    Тендерийн төрөл
                  </label>
                  {renderInput("category")}
                </div>
                <div className="form-control">
                  <label className="label text-xs text-black font-medium mb-2">
                    Төсөвт өртөг
                  </label>
                  {renderInput("totalBudget")}
                </div>
                <div className="form-control">
                  <label className="label text-xs text-black font-medium mb-2">
                    Үйл ажиллагааны чиглэл
                  </label>
                  <OperationSelector
                    value={res?.tags || ""}
                    operationList={operationList}
                    onChange={(value) => {
                      setValue("tags", value);
                      socket?.emit("add-tender-edit-field", {
                        field: "tags",
                        value: value,
                        token: userInfo.token,
                      });
                    }}
                    onBlur={() => {
                      trigger("tags");
                    }}
                    error={errors.tags?.message}
                  />
                </div>
                <div className="form-control">
                  <label className="label text-xs text-black font-medium mb-2">
                    Нээгдэх огноо
                  </label>
                  {renderInput("startDate")}
                </div>
                <div className="form-control">
                  <label className="label text-xs text-black font-medium mb-2">
                    Хаагдах огноо
                  </label>
                  {renderInput("endDate")}
                </div>
                <div className="form-control">
                  <label className="label text-xs text-black font-medium mb-2">
                    Үнэ
                  </label>
                  {renderInput("cost")}
                </div>
                <div className="form-control">
                  <label className="label text-xs text-black font-medium mb-2">
                    Санхүүжилт
                  </label>
                  {renderInput("funding")}
                </div>
              </div>
            )}

            {step === 1 && (
              <div className="mb-4">
                <h3 className="text-xl font-bold mb-4">Файл хавсаргах</h3>
                {fileValidationError && (
                  <div className="alert alert-error bg-red-100 border-red-300 shadow-none mb-4">
                    <span>{fileValidationError}</span>
                  </div>
                )}
                <FileUploadInput
                  socket={socket}
                  tkn={userInfo.token}
                  res={res}
                  fileRes={fileRes}
                  onFilesChange={(updatedFiles) => {
                    setFiles(updatedFiles);
                    setValue("files", updatedFiles);
                    if (updatedFiles.length > 0) {
                      setFileValidationError("");
                    }
                  }}
                  files={files}
                />
              </div>
            )}

            {step === 2 && (
              <div className="mb-4">
                <CustomField
                  socket={socket}
                  tkn={userInfo.token}
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                />
              </div>
            )}

            <div className="card-actions justify-end mt-6">
              {step > 0 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="btn bg-[#C3C3C3] text-white rounded-lg"
                >
                  Буцах
                </button>
              )}
              {step < 2 && (
                <button
                  type="button"
                  onClick={handleNext}
                  className="btn bg-primary border-primary text-white rounded-lg"
                >
                  Үргэлжлүүлэх
                  <MdOutlineArrowRight size={25} />
                </button>
              )}
              {step === 2 && (
                <button
                  type="submit"
                  className="btn bg-primary border-primary text-white rounded-lg"
                >
                  Илгээх
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTender;
