export const Footer = () => {
  return (
    <footer className="bg-secondary text-gray-200">
      <div className="max-w-7xl container mx-auto px-4 py-8">
        {/* Top Footer */}
        <div className="grid grid-cols-1 md:grid-cols-[0.4fr_0.4fr_0.2fr] gap-8 mb-8">
          <div className="">
            <h2 className="text-2xl font-bold text-white mb-4">etender.mn</h2>
            <p className="text-sm opacity-80">
              Etender нь Тендер захиалагч болон ханган нийлүүлэгч аж ахуй
              нэгжүүдийг холбосон худалдан авах ажиллагааг хөнгөвчлөх цахим
              систем
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-white mb-4">
              Туслах цэс
            </h3>
            <ul className="grid grid-cols-2 gap-4">
              {datas.map((item) => (
                <li key={item.id}>
                  <a
                    href={item.href}
                    className="text-sm hover:text-[#27C432] transition-colors opacity-90 hover:opacity-100"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="h-full flex items-center">
            <img src="/logo.png" alt="etender.mn вэбсайтын лого" />
          </div>
        </div>

        <hr className="border-gray-600 my-6" />

        {/* Bottom Footer */}
        <div className="flex flex-col md:flex-row justify-between items-center text-sm">
          <div className="mb-4 md:mb-0 opacity-75">
            © {new Date().getFullYear()} Бүх эрх хуулиар хамгаалагдсан
          </div>
          <div className="flex items-center space-x-4">
            <a href="#" className="hover:text-[#27C432] transition-colors">
              @inftech
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

const datas = [
  { id: 1, name: "Бидний тухай", href: "/contact" },
  { id: 2, name: "Нууцлалын бодлого", href: "/privacy-policy" },
  { id: 3, name: "Мэдээ мэдээлэл, судалгаа", href: "/news" },
  { id: 4, name: "Холбоо барих", href: "/contact" },
  { id: 5, name: "Түгээмэл асуулт хариулт", href: "/faq" },
];
