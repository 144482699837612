import { createContext, useEffect, useState } from "react";
import { decryptToken } from "../utils/token-encryption";
export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [socketId, setSocketId] = useState("");
  const [socket, setSocket] = useState(null);
  const [listeners, setListeners] = useState(0);
  const [userInfo, setUserInfo] = useState({ token: "", isLoggedIn: false });
  const [openSidebar, setOpenSidebar] = useState(false);
  const paymentContext = { transations: {}, activePayments: new Set() };

  useEffect(() => {
    const token = localStorage.getItem("tkn");
    const tkn = decryptToken(token, "testing")
    if (token) {
      setUserInfo({ token: tkn, isLoggedIn: true });
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        socketId,
        setSocketId,
        socket,
        setSocket,
        listeners,
        setListeners,
        userInfo,
        setUserInfo,
        openSidebar,
        setOpenSidebar,
        paymentContext
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
