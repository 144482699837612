import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";

export const DropdownSelect = ({
  options = [],
  placeholder,
  value,
  onChange,
  error,
  register,
  name,
}) => {
  return (
    <div className="dropdown w-full">
      {register && (
        <input type="hidden" {...register(name)} value={value || ""} />
      )}
      <div
        tabIndex={0}
        role="button"
        className={`w-full flex justify-between items-center border ${
          error ? "border-error" : "border-base-300"
        } rounded-md p-3`}
      >
        <span>
          {value
            ? options.find((item) => item.title === value)?.title
            : placeholder}
        </span>
        <IoMdArrowDropdown />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content z-50 menu flex-nowrap bg-base-100 rounded-box w-full mt-1 p-2 shadow-md max-h-40 overflow-y-auto"
      >
        {options.map((item) => (
          <li key={item.id} onClick={() => onChange(item.title)}>
            <a className="text-sm py-2">{item.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};
