import React from "react";
import { Pagination } from "../companiesPage/";
import { useNavigate } from "react-router-dom";

export const TendersTable = ({
  tenders,
  pagination,
  currentPage,
  onPageChange,
}) => {
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}/${month}/${day}`;
    } catch (error) {
      console.error("Error formatting date:", error);
      return dateString;
    }
  };

  return (
    <>
      <div className="w-full overflow-x-auto border border-base300 rounded-xl">
        <table className="w-full border-collapse">
          <thead>
            <tr className=" border-b">
              <th className="py-3 px-4 text-left text-sm font-semibold text-semibold">
                Захиалагч
              </th>
              <th className="py-3 px-4 text-left text-sm font-semibold text-semibold">
                Худалдан авалтын нэр
              </th>
              <th className="py-3 px-4 text-left text-sm font-semibold text-semibold">
                Төсөв
              </th>
              <th className="py-3 px-4 text-left text-sm font-semibold text-semibold">
                Тендерийн төрөл
              </th>
              <th className="py-3 px-4 text-left text-sm font-semibold text-semibold">
                Эхлэх огноо
              </th>
              <th className="py-3 px-4 text-left text-sm font-semibold text-semibold">
                Хаах огноо
              </th>
            </tr>
          </thead>
          <tbody>
            {tenders && tenders.length > 0 ? (
              tenders.map((tender) => (
                <tr
                  key={tender.id}
                  onClick={() => navigate(`/dashboard/tenders/${tender.id}`)}
                  className="border-b hover:cursor-pointer last-of-type:border-0 hover:bg-base-200"
                >
                  <td className="py-4 px-4">
                    <div className="flex items-center">
                      <div className="h-8 w-8 rounded-full bg-red-500 mr-3 flex justify-center items-center">
                        <span className="text-white text-xs">MCS</span>
                      </div>
                      <span className="text-sm">MCS Cola LTD</span>
                    </div>
                  </td>
                  <td className="py-4 px-4">
                    <p className="text-sm">{tender.title}</p>
                    <p className="text-xs text-gray-500">
                      {tender.requirements?.join(", ") || ""}
                    </p>
                  </td>
                  <td className="py-4 px-4">
                    <span className="text-sm font-medium">
                      {tender.totalBudget?.toLocaleString()}₮
                    </span>
                  </td>
                  <td className="py-4 px-4">
                    <span className="text-sm">{tender.status}</span>
                  </td>
                  <td className="py-4 px-4">
                    <button className="px-4 py-1 text-xs bg-green-100 rounded-md">
                      {formatDate(tender.startDate)}
                    </button>
                  </td>
                  <td className="py-4 px-4">
                    <button className="px-4 py-1 text-xs bg-red-100 rounded-md">
                      {formatDate(tender.endDate)}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="py-4 px-4 text-center text-[#A2A2A2]"
                >
                  Одоогоор оролцсон тендер байхгүй
                  <div className="w-full flex items-center justify-center">
                    <img src="/empty.png" alt="тендер олдсонгүй" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagination && (
        <Pagination
          pagination={pagination}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};
