import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/global-context";
import { FaUserCircle } from "react-icons/fa";

export function TenderDetails() {
  const ctx = useContext(GlobalContext);
  useEffect(() => {
    const handleListeners = async () => {
      if (!(ctx?.socket ?? null)) return;
      ctx?.socket?.emit("watch-on", ctx?.socket.id);
    };
    handleListeners();
  }, [ctx.socket]);

  useEffect(() => {
    if (!ctx?.socket) return;

    const listenOtherUsers = async (res) => {
      ctx?.setListeners((ctx?.listeners ?? 0) + parseInt(`${res}`));
      console.log("listen: ", res);
    };

    ctx?.socket.on("listen-users", listenOtherUsers);

    return () => {
      ctx?.socket.off("listen-users", listenOtherUsers);
    };
  }, [ctx, ctx?.socket]);

  return (
    <div className="bg-white rounded-lg border border-base-300 p-5">
      <div className="flex gap-2 text-[#898989] mb-3 text-sm font-semibold items-center">
        <FaUserCircle />
        <p>{ctx?.listeners ?? 0} хэрэглэгч энэ тэндэрийг үзэж байна</p>
      </div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          Агуулахын тавиур нийлүүлж угсрах ажил
        </h2>
        <span className="badge bg-primary text-white px-3 py-1 rounded-full text-xs">
          9 өдөр үлдсэн
        </span>
      </div>

      <div className="mb-6">
        <h3 className="font-bold mb-2">Ерөнхий мэдээлэл</h3>
        <p className="text-sm mb-4">
          Захиалагч эрх бүхий аж ахуйн нэгжийг "Агуулахын тавиур нийлүүлж угсрах
          ажил" үнийн санал ирүүлэхийг урьж байна.
        </p>
        <p className="text-sm mb-4">
          Барааг нийлүүлэх байршил: Хантай аймаг, Харгал сум, 7-р баг
          (Үйлдвэрбалгаас 288 км зайтай зам, 10км шороон замын төгсгөл)
        </p>
        <p className="text-sm mb-4">
          Бараа нийлүүлж угсрах хийх гүйцэтгэх хугацаа: 2025 оны 5-р сарын 10-нд
        </p>
      </div>

      <div className="mb-6">
        <h3 className="font-bold mb-3">Нийлүүлэх бараа:</h3>
        <ul className="list-disc pl-5 space-y-2 text-sm">
          <li>Тавцангүй тавиур - 20ш (L2400mm*W1000*H3500)</li>
          <li>Нүхтэй тавцантай тавиур - 20ш (L2400mm*W1200*H2200)</li>
          <li>Тавиурын хэмгаалалт - 60ш (L2400mm*W1000*H3500)</li>
        </ul>
      </div>

      <div>
        <h3 className="font-bold mb-3">Тавигдах шаардлага:</h3>
        <ul className="list-disc pl-5 space-y-2 text-sm">
          <li>1 шиpхэг тавиурын 1 тайцын даэрх даац нь 1500кг</li>
          <li>Тавиур нь цайвар саарал өнгөтэй</li>
          <li>
            Хүчний үйлдвэрийн шаардлага хангасан материалаар хийгдсэн байна.
          </li>
          <li>Тавиур нь лоудон хязгаарлагч бүхий хэмгаалалттай байна.</li>
        </ul>
      </div>
    </div>
  );
}
