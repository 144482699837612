import React from "react";
import { Carousel, FilterSection, TenderList } from "../components/homePage";
import thousandify from "thousandify";
import Layout from "../layout/Layout";

const Home = () => {
  return (
    <Layout>
      <div className="relative w-full">
        <Carousel />
      </div>
      <div className="flex flex-col md:flex-row gap-6 container mx-auto max-w-7xl mt-8 lg:mt-20">
        <div className="md:w-1/4 px-4 md:pr-0 md:pl-0">
          <div className="bg-green-500 text-white rounded-lg p-4 mb-4">
            <div className="text-sm font-light mb-1">
              Нийт зарлагдсан тендер
            </div>
            <div className="text-3xl font-bold">{thousandify(2320)}</div>
          </div>
          <FilterSection />
        </div>
        <div className="md:w-3/4 pr-4 md:px-0 min-h-full">
          <div className="lg:w-1/2 flex gap-3 mb-8">
            <div className="flex-1">
              <label>
                <input
                  type="text"
                  placeholder="Тэндэрийн нэр"
                  className="input bg-[#F4F4F5] border-0 w-full rounded-lg focus:outline-0"
                />
              </label>
            </div>
            <button
              type="button"
              className="btn bg-primary rounded-full text-neutral px-8 lg:px-14 hover:bg-primary-dark transition-colors"
            >
              Хайх
            </button>
          </div>
          <TenderList />
        </div>
      </div>
    </Layout>
  );
};

export default Home;
