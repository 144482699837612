import React from "react";
import { DropdownSelect } from "./";
export const CompanyInfoSection = ({
  register,
  errors,
  setValue,
  watch,
  options,
}) => {
  const handleSelectItem = (fieldName, value) => {
    setValue(fieldName, value, { shouldValidate: true });
  };

  return (
    <div className="grid grid-cols-1 mt-6 sm:grid-cols-2 md:grid-cols-3 gap-x-3">
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">
          Хариуцлагын хэлбэр <span className="text-error">*</span>
        </label>

        <DropdownSelect
          options={options?.responsibilities || []}
          placeholder="Хариуцлагын хэлбэр"
          value={watch("responsibility")}
          onChange={(value) => handleSelectItem("responsibility", value)}
          error={errors.responsibility}
          register={register}
          name="responsibility"
        />

        {errors.responsibility && (
          <p className="text-error text-xs mt-1">
            {errors.responsibility.message}
          </p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">
          Өмчийн хэлбэр <span className="text-error">*</span>
        </label>

        <DropdownSelect
          options={options?.ownerships || []}
          placeholder="Өмчийн хэлбэр"
          value={watch("ownerships")}
          onChange={(value) => handleSelectItem("ownerships", value)}
          error={errors.ownerships}
          name="ownerships"
          register={register}
        />

        {errors.ownerships && (
          <p className="text-error text-xs mt-1">{errors.ownerships.message}</p>
        )}
      </div>
      <div className="sm:col-span-2 md:col-span-1">
        <label className="block text-sm font-medium mb-1">
          Үйл ажиллагааны чиглэл <span className="text-error">*</span>
        </label>
        <div>
          <input
            type="text"
            placeholder="таслал аван бичнэ үү"
            className={`w-full p-3 border ${
              errors.induties ? "border-error" : "border-base-300"
            } rounded-md focus:outline-none focus:border-primary`}
            value={watch("induties") || ""}
            onChange={(e) => {
              setValue("induties", e.target.value, { shouldValidate: false });
            }}
            onBlur={(e) => {
              const value = e.target.value;
              let newActivity = [];

              if (value.includes(",")) {
                newActivity = value
                  .split(",")
                  .map((item) => item.trim())
                  .filter(Boolean);
                setValue("induties", newActivity, { shouldValidate: true });
              } else if (value) {
                setValue("induties", [value], { shouldValidate: true });
              } else {
                setValue("induties", [], { shouldValidate: true });
              }
            }}
          />
          <input type="hidden" {...register("induties")} />
        </div>
        {errors.induties && (
          <p className="text-error text-xs mt-1">{errors.induties.message}</p>
        )}
      </div>
    </div>
  );
};
