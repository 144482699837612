import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/global-context";
import { BasicService } from "../service/get-basic";
import { PlanService } from "../service/plan-service";
import { TendersTable } from "../components/dashboardPage/TendersTable";
import { useNavigate } from "react-router-dom";
import { IoWarningOutline } from "react-icons/io5";

const DashboardTenders = () => {
  const { userInfo, socket } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [tenders, setTenders] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    pageCount: 1,
    start: 1,
    end: 0,
    limit: 8,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const fetchTenders = async (page = 1) => {
    try {
      setIsLoading(true);
      const tendersDataResponse = await BasicService.getTenders(
        userInfo.token,
        page
      );
      setTenders(tendersDataResponse?.data || []);
      setPagination(
        tendersDataResponse?.pagination || {
          total: 0,
          pageCount: 1,
          start: 1,
          end: 0,
          limit: 8,
        }
      );
    } catch (e) {
      console.error("Error fetching tenders:", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try { 
        setIsLoading(true);
        let myPlansResponse;
        try {
          myPlansResponse = await PlanService.getMyPlans(userInfo.token);
        } catch (e) {
          console.error("Error fetching plans:", e);
        }
        await fetchTenders(currentPage);
        setPlans(myPlansResponse?.data || []);
      } catch (error) {
        console.error("Error in fetchData function:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (userInfo && userInfo.token) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.token && currentPage > 0) {
      fetchTenders(currentPage);
    }
  }, [currentPage, userInfo]);

  const handleAddTenderClick = () => {
    socket?.off("new-tender-on", () => {});
    navigate("/dashboard/tenders/add");
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      {!plans ? (
        <></>
      ) : (
        (plans ?? []).reduce((n, el) => n + (el.total ?? 0), 0) > 0 && (
          <div className="flex mt-10 gap-4">
            <div className="w-fit h-fit px-4 py-[10px] border bg-[#E9FFEA] border-[#96FB9D] rounded-lg">
              <p className="flex gap-2 text-sm text-primary items-center font-semibold">
                <IoWarningOutline size={20} />
                Хэрвээ та шинээр тендер зарлах гэж байгаа бол тендер үүсгэх дээр
                дарна уу.
              </p>
            </div>
            <button
              className="btn bg-primary border-primary text-sm font-medium rounded-lg text-white"
              onClick={handleAddTenderClick}
            >
              Шинэ тендер үүсгэх
            </button>
          </div>
        )
      )}
      <div>
        <h2 className="text-xl font-semibold my-4">Идэвхитэй тендерүүд</h2>
        {isLoading ? (
          <div className="flex justify-center my-8">
            <div className="loader">Loading...</div>
          </div>
        ) : tenders && tenders.length > 0 ? (
          <TendersTable
            tenders={tenders}
            pagination={pagination}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        ) : (
          <p className="text-gray-500">
            Одоогоор идэвхитэй тендер байхгүй байна.
          </p>
        )}
      </div>
    </>
  );
};

export default DashboardTenders;