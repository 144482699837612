import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import {
  companyExtendedSchema,
  individualExtendedSchema,
} from "../../utils/validations";
import { CompanyForm, IndividualForm } from "./infoModal/";
import { toast } from "react-toastify";
import { ConfirmService } from "../../service/confirm-account";

export const InfoModal = ({
  infoModalRef,
  isPrimary,
  isLoading,
  options,
  tkn,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(
      isPrimary === false ? companyExtendedSchema : individualExtendedSchema
    ),
    mode: "onBlur",
    defaultValues: {
      registration: "",
      responsibility: "",
      ownerships: "",
      induties: [],
      city: "",
      district: "",
      khoroo: "",
      address: "",
      hasForeignInvestment: false,
      isForeigner: false,
      firstName: "",
      lastName: "",
    },
  });

  const onSubmit = async (data) => {
    const formData = { ...data, register: data.registration };
    const result = await ConfirmService.confirmAccount(
      formData,
      tkn
    );
    console.log(result);

    if (result.status === 200) {
      infoModalRef.current.close();
      toast.success(result.data);
    } else if (result.status === 201) {
      toast.error(result.data || "Бүртгэл баталгжуулалт амжилтгүй боллоо");
    }
  };

  return (
    <dialog className="modal !overflow-visible" ref={infoModalRef}>
      <div className="modal-box max-w-3xl">
        <div>
          <img
            src="/banner2.png"
            alt="etender.mn banner"
            className="w-full rounded-lg max-h-28 object-cover"
          />
        </div>
        <h3 className="font-bold text-center text-lg mt-4">
          Та дараах мэдээллүүдийг баталгаажуулна уу
        </h3>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {isLoading ? (
            <div className="flex justify-center items-center h-40">
              <span className="loading loading-spinner loading-lg text-primary"></span>
            </div>
          ) : isPrimary ? (
            <IndividualForm
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
              options={options}
            />
          ) : (
            <CompanyForm
              register={register}
              errors={errors}
              setValue={setValue}
              watch={watch}
              options={options}
            />
          )}
          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="btn btn-lg text-white rounded-full bg-primary px-8"
            >
              Хадгалах
            </button>
          </div>
        </form>
      </div>
    </dialog>
  );
};
