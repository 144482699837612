import { CiFacebook, CiGlobe, CiInstagram } from "react-icons/ci";

export const CompanyProfile = () => {
  return (
    <div>
      <div className="mt-6">
        <img
          src="/companybanner.png"
          alt=""
          className="w-full h-full rounded-md"
        />
      </div>
      <div className="w-full grid grid-cols-[0.2fr_0.8fr] mt-4 gap-4">
        <div className="relative">
          <div className="absolute -top-20 right-0">
            <div className="rounded-full w-40 h-40">
              <img src="/mcslogo2.png" alt="MCS компанийн лого" />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div>
            <h3 className="font-medium text-2xl">MCS Coca Cola</h3>
            <div className="flex items-center text-sm mt-1">
              <div className="flex items-center">
                <span className="text-yellow-500">★</span>
                <span className="text-yellow-500">★</span>
                <span className="text-yellow-500">★</span>
                <span className="text-yellow-500">★</span>
                <span className="text-yellow-500">★</span>
              </div>
              <span className="ml-1 text-gray-600">5.0</span>
              <span className="ml-1 text-gray-400">(68)</span>
            </div>
          </div>
          <div className="flex gap-3">
            <div className="rounded-full text-white bg-primary p-1 h-fit w-fit">
              <CiFacebook size={25} />
            </div>
            <div className="rounded-full text-white bg-primary p-1 h-fit w-fit">
              <CiGlobe size={25} />
            </div>
            <div className="rounded-full h-fit text-white bg-primary p-1 w-fit">
              <CiInstagram size={25} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
