import React from "react";
import {
  CompanyHeader,
  TenderDetails,
  Advertisement,
  CompanyTenders,
  TenderDocument,
} from "../components/tenderDetailPage";
import Layout from "../layout/Layout";

function App() {
  return (
    <Layout>
      <div className="bg-base-200 min-h-screen pb-10">
        <div className="max-w-7xl container mx-auto px-4 py-6 space-y-6">
          <CompanyHeader />
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2">
              <TenderDetails />
              <TenderDocument />
            </div>
            <div className="lg:col-span-1 space-y-6">
              <CompanyTenders />
              <Advertisement />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default App;
