import React from "react";

export function CompanyHeader() {
  return (
    <div className="bg-white rounded-lg border border-base-300">
      <div className="grid grid-cols-1 md:grid-cols-3 p-4">
        {/* Company Info - Left Section */}
        <div className="flex items-start space-x-4">
          <div className="w-16 h-16 rounded-full overflow-hidden">
            <img
              src="/mcslogo.png"
              alt="MCS Coca Cola"
              className="w-full h-full object-contain"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://via.placeholder.com/64";
              }}
            />
          </div>
          <div>
            <h2 className="font-bold text-lg">MCS Coca Cola</h2>
            <p className="text-xs text-gray-600">Холбоо барих: 77118815</p>
            <p className="text-xs text-gray-600">Имэйл: info@mcs-cocacola.mn</p>

            <div className="flex mt-4 space-x-2">
              <div className="bg-gray-100 rounded p-2 text-center">
                <p className="text-xs">Зарласан тэндэр</p>
                <p className="font-bold">28</p>
              </div>
              <div className="bg-gray-100 rounded p-2 text-center">
                <p className="text-xs">Үр дүн гарсан</p>
                <p className="font-bold">28</p>
              </div>
            </div>
          </div>
        </div>

        {/* Dates - Middle Section */}
        <div className="mt-4 md:mt-0 md:px-4">
          <div className="bg-gray-100 p-4 rounded-lg h-full flex flex-col justify-center">
            <div className="text-center">
              <p className="text-sm">Зарласан огноо</p>
              <p className="font-semibold">2025/03/01</p>
            </div>
          </div>
        </div>

        {/* Deadline and Apply Button - Right Section */}
        <div className="grid grid-cols-2 gap-2 mt-4 md:mt-0">
          <div className="bg-gray-100 p-2 rounded-lg">
            <div className="text-center">
              <p className="text-sm">Эхлэх огноо</p>
              <p className="font-semibold">2025/03/01</p>
              <p className="font-bold">16:00</p>
            </div>
          </div>
          <div className="bg-gray-100 p-2 rounded-lg">
            <div className="text-center">
              <p className="text-sm">Хаах огноо</p>
              <p className="font-semibold">2025/03/21</p>
              <p className="font-bold">10:30</p>
            </div>
          </div>
          <div className="col-span-2 mt-2">
            <button className="w-full bg-primary text-white py-3 rounded-lg font-medium">
              Тендерт оролцох
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
