import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/global-context";
import { PlanService } from "../service/plan-service";
import { toast } from "react-toastify";
const COUNTDOWN_SECONDS = 60 * 5;
export default function OrderPayment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { socket, userInfo } = useContext(GlobalContext);
  const [data, setData] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(COUNTDOWN_SECONDS);
  const initialized = useRef(false);

  useEffect(() => {
    const createInvoice = async () => {
      if (userInfo.token === "") return;

      if (!initialized.current) {
        initialized.current = true;
        const res = await PlanService.createInvoice(
          userInfo.token,
          parseInt(`${id}`)
        );
        console.log(res);
        if (res.status !== 200) return;
        setData(res.data);
        setTimeRemaining(res?.data?.duration ?? 5 * 60);
      }
    };
    createInvoice();
  }, [id, userInfo]);

  useEffect(() => {
    if (!socket) return;

    const listenPayment = async (res) => {
      console.log("data: ", data);
      console.log("res: ", parseInt(`${res}`));
      if (!data) return;
      if ((data.id ?? -1) === parseInt(`${res}`)) {
        toast.success("Төлбөр амжилттай төлөгдлөө");
        navigate("/dashboard");
      }
    };

    socket.on("onPaid", listenPayment);

    return () => {
      socket.off("onPaid", listenPayment);
    };
  }, [socket, data]);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate("/dashboard/plans");
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handlePaymentSimulation = () => {
    setIsPaymentSuccessful(true);
    setIsSuccessModalOpen(true);

    setTimeout(() => {
      navigate("/dashboard");
    }, 2000);
  };

  return (
    <>
      <div className="card-body relative shadow-none">
        {/* Countdown Timer */}
        <div className="absolute top-4 right-4 text-2xl font-bold text-error">
          {formatTime(timeRemaining)}
        </div>

        {/* Plan Details */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-base-content mb-2"></h1>
          <p className="text-base-content/70 mb-4">
            Та төлбөрөө доорх QR кодоор хийнэ үү
          </p>
        </div>

        {/* QR Code Container */}
        <div className="flex justify-center items-center mb-8">
          {data && (
            <img
              src={data?.qr ?? ""}
              alt="Payment QR Code"
              className="w-96 h-96 object-contain"
            />
          )}
        </div>
      </div>

      {/* Success Modal */}
      <AnimatePresence>
        {isSuccessModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.7, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.7, opacity: 0 }}
              className="card w-96 bg-base-100 shadow-xl"
            >
              <div className="card-body items-center text-center">
                <FaCheckCircle className="text-success text-6xl mb-4" />
                <h2 className="card-title">Захиалга амжилттай</h2>
                <p>Таны төлбөр хүлээн авалаа</p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
