import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../context/global-context";
import { BasicService } from "../service/get-basic";
import { InfoModal, TendersTable } from "../components/dashboardPage/";
import { NoPlan } from "../components/dashboardPage/";
import { PlanService } from "../service/plan-service";
import PlanCarousel from "../components/dashboardPage/PlanCarousel";
import { useNavigate } from "react-router-dom";
import { IoWarningOutline } from "react-icons/io5";
import { motion } from "framer-motion";

const Dashboard = () => {
  const { userInfo, socket } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState();
  const [plans, setPlans] = useState([]);
  const [dashboard, setDashboard] = useState();
  const [tenders, setTenders] = useState();
  const navigate = useNavigate();
  const [accountDetail, setAccountDetail] = useState({
    isPrimary: false,
    accountVerified: null,
  });
  const [pagination, setPagination] = useState({
    total: 0,
    pageCount: 1,
    start: 1,
    end: 0,
    limit: 8,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const infoModalRef = useRef();
  const fetchTenders = async (page = 1) => {
    try {
      setIsLoading(true);
      const tendersDataResponse = await BasicService.getDashboardTenders(
        userInfo.token
      );
      setTenders(tendersDataResponse?.data || []);
      setPagination(
        tendersDataResponse?.pagination || {
          total: 0,
          pageCount: 1,
          start: 1,
          end: 0,
          limit: 8,
        }
      );
    } catch (e) {
      console.error("Error fetching tenders:", e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (userInfo && userInfo.token && currentPage > 0) {
      fetchTenders(currentPage);
    }
  }, [currentPage, userInfo]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [basicData, myPlans, operationsList, dashboardList, tendersList] =
          await Promise.all([
            BasicService.getBasic(userInfo.token),
            PlanService.getMyPlans(userInfo.token),
            BasicService.getOperationList(),
            BasicService.getDashboard(userInfo.token),
            BasicService.getDashboardTenders(userInfo.token),
          ]);
        setAccountDetail({
          isPrimary: basicData.data.isPrimary,
          accountVerified: basicData.data.accountVerified,
        });
        setPlans(myPlans.data ?? []);
        setOptions(operationsList);
        setDashboard(dashboardList);
        setTenders(tendersList.data);

        if (basicData.data.accountVerified === false) {
          const optionsResult = await BasicService.getOptions(userInfo.token);
          setOptions(optionsResult.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userInfo]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (!isLoading && accountDetail.accountVerified === false) {
      infoModalRef.current.showModal();
    }
  }, [isLoading, accountDetail.accountVerified]);

  const handleAddTenderClick = () => {
    socket?.off("new-tender-on", () => {});
    navigate("/dashboard/tenders/add");
  };

  return (
    <>
      {(plans ?? []).reduce((n, el) => n + (el.total ?? 0), 0) > 0 && (
        <div className="flex my-8 gap-4">
          <div className="w-fit h-fit px-4 py-[10px] border bg-[#E9FFEA] border-[#96FB9D] rounded-lg">
            <p className="flex gap-2 text-sm text-primary items-center font-semibold">
              <IoWarningOutline size={20} />
              Хэрвээ та шинээр тендер зарлах гэж байгаа бол тендер үүсгэх дээр
              дарна уу.
            </p>
          </div>
          <button
            className="btn bg-primary border-primary text-sm font-medium rounded-lg text-white"
            onClick={handleAddTenderClick}
          >
            Шинэ тендер үүсгэх
          </button>
        </div>
      )}
      {plans && (plans ?? []).length > 0 ? (
        <PlanCarousel plans={plans} />
      ) : (
        <NoPlan />
      )}
      {dashboard && (
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 my-8"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: { staggerChildren: 0.1 },
            },
          }}
        >
          {Object.entries(dashboard).map(([key, value]) => (
            <motion.div
              key={key}
              variants={cardVariants}
              className="group relative"
              transition={{ type: "spring", stiffness: 300 }}
            >
              <div className="bg-[#F9F9F9] rounded-xl p-6 min-h-[150px] flex flex-col border border-[#D0D5DD] transition-all duration-300 ease-out cursor-pointer shadow-sm relative overflow-hidden">
                <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-[#27C432] to-[#222222] opacity-50" />
                <p className="font-medium text-sm text-[#222222] mb-2 group-hover:text-[#27C432] transition-colors">
                  {keyMapping[key] || key}
                </p>
                <div className="mt-auto">
                  <p className="text-3xl font-bold bg-gradient-to-r from-[#27C432] to-[#FFC456] bg-clip-text text-transparent">
                    {value.toLocaleString()}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      )}
      {tenders?.length > 0 ? (
        <TendersTable
          tenders={tenders}
          pagination={pagination}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      ) : (
        <TendersTable />
      )}
      {!isLoading && accountDetail.accountVerified === false && (
        <InfoModal
          infoModalRef={infoModalRef}
          isPrimary={accountDetail.isPrimary}
          isLoading={isLoading}
          options={options}
          tkn={userInfo.token}
        />
      )}
    </>
  );
};

export default Dashboard;

const keyMapping = {
  attended: "Миний оролцсон",
  total: "Нийт зарлагдсан тендер",
  totalClosed: "Нийт дууссан тендер",
  totalCompany: "Нийт бүртгэлтэй компани",
};

const cardVariants = {
  offscreen: { y: 20, opacity: 0 },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", bounce: 0.4, duration: 0.8 },
  },
};
