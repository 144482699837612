export const PlanService = {
  getPlans(token) {
    return fetch("http://202.126.94.29:3004/api/v1/get-plans", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => res.data);
  },
  createInvoice(token, id) {
    return fetch("http://202.126.94.29:3004/api/v1/create-invoice", {
      method: "POST",
      body: JSON.stringify({ id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  },
  getMyPlans(token) {
    return fetch("http://202.126.94.29:3004/api/v1/get-my-plans", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  },
};
