import React, { useState, useEffect, useRef } from "react";
import { cyrillicLetters } from "../../data/cyrillicletters";

export const RepresentativeSection = ({ register, errors, setValue }) => {
  const [firstLetter, setFirstLetter] = useState("У");
  const [secondLetter, setSecondLetter] = useState("К");
  const [numberPart, setNumberPart] = useState("");
  const [showFirstDropdown, setShowFirstDropdown] = useState(false);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const firstDropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        firstDropdownRef.current &&
        !firstDropdownRef.current.contains(event.target)
      ) {
        setShowFirstDropdown(false);
      }
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setShowSecondDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    updateRegistrationNumber(firstLetter, secondLetter, numberPart);
  }, [firstLetter, secondLetter, numberPart]);

  const updateRegistrationNumber = (first, second, numbers) => {
    setValue("registration", `${first + second + numbers}`, {
      shouldValidate: hasInteracted,
    });
  };

  const handleFirstLetterChange = (letter) => {
    setFirstLetter(letter);
    setShowFirstDropdown(false);
    setHasInteracted(true);
  };

  const handleSecondLetterChange = (letter) => {
    setSecondLetter(letter);
    setShowSecondDropdown(false);
    setHasInteracted(true);
  };

  const handleNumberInput = (e) => {
    const input = e.target.value;
    const numbers = input.replace(/[^0-9]/g, "").slice(0, 8);
    setNumberPart(numbers);
    setHasInteracted(true);
  };

  return (
    <div>
      <h2 className="font-medium text-lg mt-6 mb-2">
        Төлөөлөх ажилтны мэдээлэл
      </h2>

      <div className="sm:flex gap-3 mb-4">
        <div className="mb-4 md:mb-0 md:w-1/3">
          <label className="block text-sm font-medium mb-1">
            Овог <span className="text-error">*</span>
          </label>
          <input
            type="text"
            placeholder="Болд"
            className={`w-full p-3 border ${
              errors.lastName ? "border-error" : "border-base-300"
            } rounded-md focus:outline-none focus:border-primary`}
            {...register("lastName")}
          />
          {errors.lastName && (
            <p className="text-error text-xs mt-1">{errors.lastName.message}</p>
          )}
        </div>

        <div className="md:w-1/3">
          <label className="block text-sm font-medium mb-1">
            Нэр <span className="text-error">*</span>
          </label>
          <input
            type="text"
            placeholder="Бат"
            className={`w-full p-3 border ${
              errors.firstName ? "border-error" : "border-base-300"
            } rounded-md focus:outline-none focus:border-primary`}
            {...register("firstName")}
          />
          {errors.firstName && (
            <p className="text-error text-xs mt-1">
              {errors.firstName.message}
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">
            Регистрийн дугаар <span className="text-error">*</span>
          </label>
          <div className="flex gap-1">
            {/* First letter dropdown */}
            <div className="relative" ref={firstDropdownRef}>
              <div
                onClick={() => setShowFirstDropdown(!showFirstDropdown)}
                className="w-12 h-12 bg-white border border-base-300 rounded-md p-3 flex justify-center items-center focus:outline-none focus:border-primary"
              >
                <span className="text-lg font-medium">{firstLetter}</span>
              </div>

              {showFirstDropdown && (
                <div className="absolute z-10 mt-1 max-h-40 w-24 overflow-auto bg-white border border-base-300 rounded-md shadow-lg">
                  <div className="p-1 flex flex-wrap gap-1">
                    {cyrillicLetters.map((letter) => (
                      <div
                        key={`first-${letter}`}
                        onClick={() => handleFirstLetterChange(letter)}
                        className={`px-2 py-1 text-center hover:bg-gray-100 rounded ${
                          firstLetter === letter
                            ? "bg-primary text-white hover:text-secondary"
                            : ""
                        }`}
                      >
                        {letter}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="relative" ref={secondDropdownRef}>
              <div
                onClick={() => setShowSecondDropdown(!showSecondDropdown)}
                className="w-12 h-12 bg-white border border-base-300 rounded-md flex justify-center items-center p-3 focus:outline-none focus:border-primary"
              >
                <span className="text-lg font-medium">{secondLetter}</span>
              </div>

              {showSecondDropdown && (
                <div className="absolute z-10 mt-1 max-h-40 w-24 overflow-auto bg-white border border-base300 rounded-md shadow-lg">
                  <div className="p-1 flex flex-wrap gap-1">
                    {cyrillicLetters.map((letter) => (
                      <div
                        key={`second-${letter}`}
                        onClick={() => handleSecondLetterChange(letter)}
                        className={`px-2 py-1 text-center hover:bg-gray-100 rounded ${
                          secondLetter === letter
                            ? "bg-primary hover:text-secondary text-white"
                            : ""
                        }`}
                      >
                        {letter}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Number input */}
            <input
              type="text"
              placeholder="12345678"
              value={numberPart}
              onChange={handleNumberInput}
              className="p-3 border w-full border-base-300 rounded-md focus:outline-none focus:border-primary"
            />

            <input type="hidden" {...register("registration")} />
          </div>
          {errors.registration && hasInteracted && (
            <p className="text-error text-xs mt-1">
              {errors.registration.message}
            </p>
          )}
        </div>
      </div>

      {/* <div>
        <label className="block text-sm font-medium mb-3">
          Гадаадын хөрөнгө оруулалттай эсэх
        </label>
        <div className="flex items-center gap-6">
          <label className="flex items-center gap-2 cursor-pointer">
            <input
              type="radio"
              className="radio radio-sm border-primary"
              value="true"
              {...register("hasForeignInvestment")}
            />
            <span>Тийм</span>
          </label>
          <label className="flex items-center gap-2 cursor-pointer">
            <input
              type="radio"
              className="radio radio-sm border-error"
              value="false"
              {...register("hasForeignInvestment")}
            />
            <span>Үгүй</span>
          </label>
        </div>
      </div> */}
    </div>
  );
};
