import { useState } from "react";
import { TenderCard } from ".";
import { Pagination } from "../companiesPage";

export const TenderList = ({ title }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="flex flex-col justify-between">
      <h1 className="mb-4 font-bold text-xl">{title}</h1>
      {mockTenders.map((tender, idx) => (
        <TenderCard key={`${tender.company}-${idx}`} tender={tender} />
      ))}
      <Pagination
        currentPage={currentPage}
        totalPages={12}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

const mockTenders = [
  {
    id: 1,
    company: "MCS Coca Cola",
    type: "Corporate",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/03/20",
    package: "1",
    status: "Нээлттэй тендер",
    amount: 250000000,
    lots: "1",
    showButton: true,
    showGreenButton: false,
  },
  {
    id: 2,
    company: "MCS Coca Cola",
    type: "Premium",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/03/20",
    package: "1",
    status: "Нээлттэй тендер",
    amount: 250000000,
    lots: "1",
    showButton: true,
    showGreenButton: false,
  },
  {
    id: 3,
    company: "MCS Coca Cola",
    type: "Basic",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/02/20",
    package: "1",
    status: "Хаалттай тендер",
    amount: 69847654,
    lots: "",
    showButton: false,
    showGreenButton: true,
  },
  {
    id: 4,
    company: "MCS Coca Cola",
    type: "Basic",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/03/20",
    package: "1",
    status: "Нээлттэй тендер",
    amount: "36845165",
    lots: "",
    showButton: false,
    showGreenButton: true,
  },
  {
    id: 5,
    company: "MCS Coca Cola",
    type: "Basic",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/03/20",
    package: "1",
    status: "Нээлттэй тендер",
    amount: 16514682,
    lots: "1",
    showButton: false,
    showGreenButton: true,
  },
];
