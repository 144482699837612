import React from "react";

export function Advertisement() {
  return (
    <div className="bg-secondary rounded-lg overflow-hidden">
      <img
        src="/zar.png"
        alt="Advertisement"
        className="w-full h-48 object-cover"
      />
      <div className="p-4 text-white text-center">
        <p className="text-xs">ЦАХИМ ТЕНДЕР</p>
      </div>
    </div>
  );
}
