import React from "react";
import { useNavigate } from "react-router-dom";

export const NewsCard = ({ data }) => {
    const navigate = useNavigate();
  return (
    <div onClick={() => navigate("/news/d")} className="border rounded-[20px] flex hover:scale-[1.01] hover:cursor-pointer hover:shadow-md transition-all flex-col">
      <div className="flex-1 overflow-hidden h-full w-full">
        <img src={data.image} alt={data.title} className="object-cover w-full h-full" />
      </div>
      <div className="p-3 lg:p-5">
        <h3 className="text-base font-bold mb-2 lg:mb-5 line-clamp-2">{data.title}</h3>
        <p className="text-sm font-semibold text-[#898989]">
          Нийтэлсэн: {data.date}
        </p>
      </div>
    </div>
  );
};
