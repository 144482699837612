import React, { useState, useEffect } from "react";
import { CompanyInfoSection, AddressSection } from "./";
import { RepresentativeSection } from "./RepresentativeSection";

export const CompanyForm = ({ register, errors, setValue, watch, options }) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);

  const watchCity = watch("city");
  const watchDistrict = watch("district");

  useEffect(() => {
    if (watchCity) {
      setSelectedCity(watchCity);
      
      setDistricts(
        options?.aimags.filter((item) => item.title === watchCity)[0]?.childs
      );
      setValue("district", "");
      setValue("khoroo", "");
      setSelectedDistrict(null);
    }
  }, [watchCity, setValue, options?.aimags]);

  useEffect(() => {
    if (watchDistrict) {
      setSelectedDistrict(watchDistrict);
    }
  }, [watchDistrict]);

  return (
    <div className="space-y-4 overflow-y-auto md:overflow-visible">
      <CompanyInfoSection
        register={register}
        errors={errors}
        setValue={setValue}
        watch={watch}
        options={options}
      />

      <AddressSection
        register={register}
        errors={errors}
        setValue={setValue}
        watch={watch}
        options={options}
        selectedCity={selectedCity}
        selectedDistrict={selectedDistrict}
        districts={districts}
      />

      <RepresentativeSection
        register={register}
        errors={errors}
        setValue={setValue}
        watch={watch}
      />
    </div>
  );
};
