import React from "react";
import CheckAuth from "../../middleware/check_auth";

export function TenderDocument() {
  return (
    <div className="bg-white rounded-lg border border-base-300 p-4 mt-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
          <span>Агуулахын тавиур нийлүүлж угсрах ажлын бичиг баримт</span>
        </div>
        <CheckAuth isLoggedIn={true}>
          <button className="bg-primary text-white px-4 py-2 rounded-lg text-sm">
            Татаж авах
          </button>
        </CheckAuth>
      </div>
    </div>
  );
}
