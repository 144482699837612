import { motion } from "framer-motion";
import { PiBuildingApartmentThin } from "react-icons/pi";

export const StatsCard = ({ count, title, subtitle }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-primary text-white p-6 rounded-lg mb-6"
    >
      <div className="mb-2">
        <div className="flex gap-1 mb-1 items-center">
          <PiBuildingApartmentThin size={25} />
          <p className="text-base">{title}</p>
        </div>
        <h2 className="text-4xl font-bold">{count}</h2>
      </div>
      <p className="font-medium">{subtitle}</p>
    </motion.div>
  );
};
