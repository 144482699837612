export const TabButton = ({ name, isActive, onClick }) => {
  return (
    <button
      className={`flex-1 py-3 px-4 font-medium transition-colors ${
        isActive
          ? "bg-primary text-white"
          : "bg-[#F9F9F9]  text-[#808080]"
      }`}
      onClick={onClick}
    >
      {name}
    </button>
  );
};
