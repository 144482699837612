import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PlanCard from "./PlanCard";

const PlanCarousel = ({ plans }) => {
  const navigate = useNavigate();

  const carouselRef = React.useRef(null);
  const handleScroll = (direction) => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.offsetWidth;
      carouselRef.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="relative">
        {plans.length === 1 || plans.length === 0 ? null : (
          <div className="absolute left-0 top-1/2 -translate-y-1/2 z-10">
            <button
              className="btn btn-circle bg-black/40 border-0"
              onClick={() => handleScroll("left")}
            >
              <IoIosArrowBack size={24} className="text-white" />
            </button>
          </div>
        )}
        {plans.length === 1 || plans.length === 0 ? null : (
          <div className="absolute right-0 top-1/2 -translate-y-1/2 z-10">
            <button
              className="btn btn-circle bg-black/40 border-0"
              onClick={() => handleScroll("right")}
            >
              <IoIosArrowForward size={24} className="text-white" />
            </button>
          </div>
        )}

        {/* Carousel */}
        <div
          ref={carouselRef}
          className="carousel border shadow-md carousel-center bg-[#86888638] rounded-box max-w-full space-x-4 p-4 overflow-x-auto scroll-smooth"
        >
          {plans.map((plan, idx) => (
            <div key={`plan-${idx}`} className="carousel-item">
              <PlanCard plan={plan} idx={idx} navigate={navigate} hide={true} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PlanCarousel;
