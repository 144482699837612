import React from "react";

export const AddTenderSkeleton = () => {
  return (
    <div className="w-full animate-pulse">
      {/* Steps Skeleton */}
      <div className="mb-8">
        <div className="flex justify-between items-center w-full">
          {/* Step indicators */}
          {[1, 2, 3, 4, 5].map((step) => (
            <div key={step} className="flex flex-col items-center">
              {/* Step circle */}
              <div className="h-8 w-8 rounded-full bg-gray-200"></div>
              {/* Step label */}
              <div className="h-3 w-16 bg-gray-200 rounded mt-2"></div>
            </div>
          ))}
        </div>

        {/* Progress bar */}
        <div className="relative h-1 w-full bg-gray-100 mt-4">
          <div className="absolute h-1 w-1/5 bg-gray-300 left-0"></div>
        </div>
      </div>

      {/* Row 1 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Column 1 */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full"></div>
        </div>

        {/* Column 2 */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-1/3"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full"></div>
        </div>
      </div>

      {/* Row 2 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Column 1 */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-2/5"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full"></div>
        </div>

        {/* Column 2 */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full"></div>
        </div>
      </div>

      {/* Row 3 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Column 1 */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-1/3"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full"></div>
        </div>

        {/* Column 2 */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-2/5"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full"></div>
        </div>
      </div>

      {/* Row 4 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Column 1 */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-2/5"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full"></div>
        </div>

        {/* Column 2 */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full"></div>
        </div>
      </div>

      {/* Row 5 - Operation Selector in first column */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Column 1 - Operation Selector */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full flex items-center p-2">
            <div className="flex gap-2">
              <div className="h-6 bg-[#ececec] rounded-md w-16"></div>
              <div className="h-6 bg-[#ececec] rounded-md w-20"></div>
            </div>
          </div>
        </div>

        {/* Column 2 */}
        <div className="space-y-2">
          <div className="h-4 bg-gray-200 rounded w-1/3"></div>
          <div className="h-10 bg-[#F9F9F9] border border-[#D5D5D6] rounded-lg w-full"></div>
        </div>
      </div>

      {/* Navigation buttons skeleton */}
      <div className="flex justify-end mt-6 space-x-3">
        <div className="h-10 w-28 bg-gray-200 rounded-lg"></div>
        <div className="h-10 w-28 bg-gray-300 rounded-lg"></div>
      </div>
    </div>
  );
};
