import { useContext, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { GlobalContext } from "../context/global-context";

const SocketConnect = ({ children }) => {
  const initialized = useRef(false);

  const { socket, paymentContext, setSocketId, setSocket } =
    useContext(GlobalContext);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (socket) return;
      if (!socket) {
        const newSocket = io("http://202.126.94.29:3004", {
          transports: ["websocket"],
          reconnectionAttempts: 3,
          timeout: 10000,
        });

        newSocket.on("connect", () => {
          console.log("Socket Connected: ", newSocket.id);
          setSocketId(newSocket.id);
          setSocket(newSocket);
        });
        newSocket.on("paid", (paymentDetails) => {
          const { transactionId, paymentProof } = paymentDetails;

          if (paymentContext.transactions[transactionId]) {
            paymentContext.transactions[transactionId].status = "completed";
            paymentContext.transactions[transactionId].proof = paymentProof;
            paymentContext.activePayments.delete(transactionId);
          }
          socket.emit("payment_status", {
            transactionId,
            status: "completed",
          });
        });

        if (newSocket) {
          // newSocket.emit(
          //   "add-tender-edit-field",
          //   "bi id-g n yawuulhar, tuhain id-tai tenderiig olood yawuuldag api gargaad ug"
          // );
        }

        return () => {
          if (socket) {
            newSocket.disconnect();
          }
        };
      }
    }
  }, [socket]);

  return <div>{children}</div>;
};

export default SocketConnect;
