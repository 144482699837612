import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../context/global-context";
import { FaUserCircle, FaDownload, FaTag } from "react-icons/fa";

export function DashboardTenderDetails({ detail }) {
  const ctx = useContext(GlobalContext);

  useEffect(() => {
    const handleListeners = async () => {
      if (!(ctx?.socket ?? null)) return;
      ctx?.socket?.emit("watch-on", ctx?.socket.id);
    };
    handleListeners();
  }, [ctx.socket]);

  useEffect(() => {
    if (!ctx?.socket) return;

    const listenOtherUsers = async (res) => {
      ctx?.setListeners((ctx?.listeners ?? 0) + parseInt(`${res}`));
      console.log("listen: ", res);
    };

    ctx?.socket.on("listen-users", listenOtherUsers);

    return () => {
      ctx?.socket.off("listen-users", listenOtherUsers);
    };
  }, [ctx, ctx?.socket]);

  const calculateDaysRemaining = () => {
    if (!detail?.endDate) return "9 өдөр үлдсэн";

    const endDate = new Date(detail.endDate);
    const today = new Date();
    const diffTime = endDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays > 0 ? `${diffDays} өдөр үлдсэн` : "Хугацаа дууссан";
  };

  const getFundingInfo = () => {
    return detail?.funding || "Өөрийн санхүүжилт";
  };

  const formatNumber = (num) => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
  };

  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-white rounded-lg border border-base300 p-5">
      <div className="flex justify-between items-center mb-3">
        <div className="flex gap-2 text-[#898989] text-sm font-semibold items-center">
          <FaUserCircle />
          <p>{ctx?.listeners ?? 0} хэрэглэгч энэ тэндэрийг үзэж байна</p>
        </div>
        <div className="flex items-center">
          <span
            className={`inline-block w-3 h-3 rounded-full mr-2 ${
              detail?.status === "Нээлттэй" ? "bg-green-500" : "bg-yellow-500"
            }`}
          ></span>
          <span className="text-sm font-medium">
            {detail?.status || "Нээлттэй"}
          </span>
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          {detail?.title || "Тендерийн нэр"}
        </h2>
        <span className="badge bg-primary text-white px-3 py-1 rounded-full text-xs">
          {calculateDaysRemaining()}
        </span>
      </div>

      {detail?.tags && detail.tags.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-4">
          <FaTag className="text-gray-500 mt-1" />
          {detail.tags.map((tag, index) => (
            <span
              key={index}
              className="bg-gray-100 text-gray-600 text-xs px-2 py-1 rounded-md"
            >
              {tag}
            </span>
          ))}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div>
          <h3 className="font-bold mb-2">Ерөнхий мэдээлэл</h3>
          <p className="text-sm mb-2">
            <span className="font-medium">Санхүүжилт:</span> {getFundingInfo()}
          </p>
          <p className="text-sm mb-2">
            <span className="font-medium">Төсөв:</span>{" "}
            {formatNumber(detail?.totalBudget)} ₮
          </p>
          <p className="text-sm mb-2">
            <span className="font-medium">Тендер №:</span> {detail?.no || "-"}
          </p>
          <p className="text-sm mb-2">
            <span className="font-medium">Ангилал:</span>{" "}
            {detail?.category || "Нээлттэй тендер"}
          </p>
        </div>

        <div>
          <h3 className="font-bold mb-2">Хугацаа</h3>
          <p className="text-sm mb-2">
            <span className="font-medium">Зарлагдсан:</span>{" "}
            {new Date(detail?.createdAt).toLocaleDateString("mn-MN")}
          </p>
          <p className="text-sm mb-2">
            <span className="font-medium">Эхлэх огноо:</span>{" "}
            {new Date(detail?.startDate).toLocaleDateString("mn-MN")}
          </p>
          <p className="text-sm mb-2">
            <span className="font-medium">Дуусах огноо:</span>{" "}
            {new Date(detail?.endDate).toLocaleDateString("mn-MN")}
          </p>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="font-bold mb-2">Шаардлагууд</h3>
        {detail?.requirements && detail.requirements.length > 0 ? (
          <ul className="list-disc pl-5 space-y-2 text-sm">
            {detail.requirements.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        ) : (
          <p className="text-sm text-gray-500">Шаардлагууд оруулаагүй байна.</p>
        )}
      </div>

      {detail?.files && detail.files.length > 0 && (
        <div className="mt-6">
          <h3 className="font-bold mb-3">Хавсралт файлууд:</h3>
          <div className="grid grid-cols-1 gap-2">
            {detail.files.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 bg-gray-50 rounded border border-gray-200"
              >
                <div className="flex items-center">
                  <span className="text-xs bg-gray-200 px-2 py-1 rounded mr-2">
                    {file.ext.toUpperCase()}
                  </span>
                  <span className="text-sm">{file.title}</span>
                </div>
                <button
                  onClick={() => handleDownload(file.url, file.title)}
                  className="flex items-center gap-1 bg-blue-50 hover:bg-blue-100 text-blue-600 px-3 py-1 rounded-md text-sm"
                >
                  <FaDownload size={12} />
                  <span>Татах</span>
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-8 pt-4 border-t border-gray-200">
        <p className="text-xs text-gray-500">
          Тендерийн дугаар: {detail?.no || "-"}
        </p>
        <p className="text-xs text-gray-500">
          Сүүлд шинэчлэгдсэн:{" "}
          {new Date(detail?.createdAt).toLocaleString("mn-MN")}
        </p>
      </div>
    </div>
  );
}
