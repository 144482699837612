import CryptoJS from "crypto-js";

export const encryptToken = (token, secretKey) => {
  try {
    const encryptToken = CryptoJS.AES.encrypt(token, secretKey).toString();
    return encryptToken;
  } catch (error) {
    console.error("Error encrypting token:", error);
    throw new Error("Token encryption failed");
  }
};

export const decryptToken = (encryptToken, secretKey) => {
  try {
    if (["", undefined, null].includes(encryptToken)) {
      return "";
    }
    const decryptedBytes = CryptoJS.AES.decrypt(encryptToken, secretKey);
    const decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedToken;
  } catch (error) {
    console.error("Error decrypting token:", error);
    throw new Error("Token decryption failed");
  }
};
