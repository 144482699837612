import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Tenders from "./pages/Tenders";
import TenderDetail from "./pages/TenderDetail";
import SignIn from "./pages/Login";
import Signup from "./pages/Signup";
import CreatePassword from "./pages/CreatePassword";
import Companies from "./pages/Companies";
import CompanyDetail from "./pages/CompanyDetail";
import Contact from "./pages/Contact";
import News from "./pages/News";
import NewsDetail from "./pages/NewsDetail";
import FAQ from "./pages/FAQ";
import "react-toastify/dist/ReactToastify.css";
import { GlobalProvider } from "./context/global-context";
import SocketConnect from "./middleware/socket_connect";
import { ToastContainer } from "react-toastify";
import Dashboard from "./pages/Dashboard";
import { ProtectedRoute, ProtectedDashboardRoute } from "./components/routes/";
import Settings from "./pages/Settings";
import Order from "./pages/Order";
import Plans from "./pages/Plans";
import AddTender from "./pages/AddTender";
import DashboardTenders from "./pages/DashboardTenders";
import AdminLayout from "./layout/AdminLayout";
import DashboardTenderDetail from "./pages/DashboardTenderDetail";
import DashboardActiveTenders from "./pages/DashboardActiveTenders";
import DashboardAccount from "./pages/DashboardAccount";

function App() {
  return (
    <GlobalProvider>
      <ToastContainer position="top-right" autoClose={2000} />
      <SocketConnect>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tenders" element={<Tenders />} />
            <Route path="/tenders/:name" element={<TenderDetail />} />
            <Route
              path="/login"
              element={
                <ProtectedRoute>
                  <SignIn />
                </ProtectedRoute>
              }
            />
            <Route
              path="/register"
              element={
                <ProtectedRoute>
                  <Signup />
                </ProtectedRoute>
              }
            />
            <Route path="/forgot-password" element={<CreatePassword />} />
            <Route path="/companies/" element={<Companies />} />
            <Route path="/companies/:name" element={<CompanyDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:name" element={<NewsDetail />} />
            <Route path="/faq" element={<FAQ />} />
            <Route
              path="/dashboard/*"
              element={
                <AdminLayout>
                  <Routes>
                    <Route path="settings" element={<Settings />} />
                    <Route path="plans" element={<Plans />} />
                    <Route path="order/:id" element={<Order />} />
                    <Route path="tenders/add" element={<AddTender />} />
                    <Route path="tenders" element={<DashboardTenders />} />
                    <Route path="account" element={<DashboardAccount />} />
                    <Route
                      path="tenders/:id"
                      element={<DashboardTenderDetail />}
                    />
                    <Route
                      path="active-tenders"
                      element={<DashboardActiveTenders />}
                    />
                    <Route
                      path=""
                      element={
                        <ProtectedDashboardRoute>
                          <Dashboard />
                        </ProtectedDashboardRoute>
                      }
                    />
                  </Routes>
                </AdminLayout>
              }
            />
          </Routes>
        </BrowserRouter>
      </SocketConnect>
    </GlobalProvider>
  );
}

export default App;
