import React, { useContext, useEffect, useState } from "react";
import { AdminNavbar } from "../components/navbar/AdminNavbar";
import { motion } from "framer-motion";
import {
  RiDashboardLine,
  RiFileList3Line,
  RiSettings4Line,
} from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../context/global-context";
import { BasicService } from "../service/get-basic";

const AdminLayout = ({ children, selectedMenu }) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(GlobalContext);
  const [profileData, setProfileData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompany, setIsCompany] = useState();
  const pathName = useParams();
  console.log(Object.values(pathName));

  useEffect(() => {
    setIsLoading(true);
    const fetchProfileData = async () => {
      try {
        const response = await BasicService.getProfile(userInfo.token);
        if (response && response.data) {
          if (
            !response.data.company ||
            Object.keys(response.data.company).length === 0 ||
            !response.data.company.name ||
            response.data.company.name.trim() === ""
          ) {
            setProfileData(response.data.user);
            setIsCompany(false);
          } else {
            setProfileData(response.data.company);
            setIsCompany(true);
          }

          if (response.status === 200) {
            setIsLoading(false);
          }
        } else {
          console.error("Invalid response structure:", response);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setIsLoading(false);
      }
    };

    if (userInfo && userInfo.token) {
      fetchProfileData();
    } else {
      setIsLoading(false);
    }
  }, [userInfo]);

  return (
    <>
      <AdminNavbar />
      <div className="mx-auto max-w-7xl px-4 my-10 w-full h-full gap-10 flex">
        {/* Sidebar */}
        <div className="hidden md:block">
          <div className="max-w-[20%] min-w-80 h-fit border-base300 border rounded-xl p-4">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex flex-col h-full"
            >
              {isLoading || !profileData ? (
                <div className="flex gap-3 items-center animate-pulse">
                  <div className="w-16 h-16 bg-gray-200 rounded-full"></div>
                  <div className="h-6 bg-gray-200 rounded w-40"></div>
                </div>
              ) : (
                <div className="flex gap-3 items-center">
                  <div className="w-16 h-16">
                    <img
                      src={
                        profileData.logo ||
                        "https://placehold.co/600x400?text=Зураг"
                      }
                      className="w-full h-full object-cover rounded-full"
                      alt={`${profileData.name} logo`}
                    />
                  </div>
                  <h1 className="font-bold">{profileData.name}</h1>
                </div>
              )}
              {/* Menu Items */}
              <hr className="my-4" />
              <nav className="flex-1">
                <ul className="space-y-2">
                  {menuItems.map((item) => (
                    <li key={item.id}>
                      <div onClick={() => navigate(`/dashboard/${item.path}`)}>
                        <motion.button
                          className={`w-full flex items-center text-black p-3 rounded-lg text-left transition-all duration-200 ${
                            `${Object.values(pathName)}` === item.id
                              ? "bg-primary text-white font-medium"
                              : "hover:bg-base-300"
                          }`}
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                        >
                          <item.icon className="mr-3 text-lg" />
                          <span>{item.name}</span>
                        </motion.button>
                      </div>
                    </li>
                  ))}
                </ul>
              </nav>
            </motion.div>
          </div>
          <div className="mt-4">
            <p className="font-bold mb-2">Сурталчилгаа</p>
            <img src="/zar.png" alt="zar" className="rounded-xl" />
          </div>
        </div>
        <main className="md:mx-auto md:max-w-[80%] rounded-xl h-full w-full">
          {children}
        </main>
      </div>
    </>
  );
};

export default AdminLayout;

const menuItems = [
  { id: "", name: "Хянах самбар", icon: RiDashboardLine, path: "" },
  { id: "tenders", name: "Тендерүүд", icon: RiFileList3Line, path: "tenders" },
  {
    id: "active-tenders",
    name: "Оролцож буй тендер",
    icon: RiFileList3Line,
    path: "active-tenders",
  },
  {
    id: "account",
    name: "Банкны данс",
    icon: RiFileList3Line,
    path: "account",
  },
  { id: "settings", name: "Тохиргоо", icon: RiSettings4Line, path: "settings" },
];
