import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const registerSchema = yup.object().shape({
  register: yup
    .string()
    .trim()
    .required("Регистрийн дугаар оруулна уу")
    .when("isPerson", {
      is: true,
      then: () =>
        yup
          .string()
          .matches(
            /^[А-ЯӨҮ]{2}\d{8}$/,
            "Регистрийн дугаар 2 үсэг, 8 тооноос бүрдэнэ"
          ),
      otherwise: () =>
        yup
          .string()
          .matches(/^\d{7}$/, "Регистрийн дугаар 7 оронтой байх ёстой"),
    }),
  title: yup.string().when("isPerson", {
    is: false,
    then: () => yup.string().trim().required("Байгууллагын нэр оруулна уу"),
    otherwise: () => yup.string().optional(),
  }),
  firstName: yup.string().when("isPerson", {
    is: true,
    then: () => yup.string().trim().required("Та өөрийн нэрийг оруулна уу"),
    otherwise: () => yup.string().optional(),
  }),
  lastName: yup.string().when("isPerson", {
    is: true,
    then: () => yup.string().trim().required("Та өөрийн овгийг оруулна уу"),
    otherwise: () => yup.string().optional(),
  }),
  userType: yup.string().when("isPerson", {
    is: false,
    then: () => yup.string().required("Төрөл сонгоно уу"),
    otherwise: () => yup.string().optional(),
  }),
  email: yup
    .string()
    .trim()
    .required("Имэйл хаяг оруулна уу")
    .email("Имэйл хаяг буруу байна"),
  isPerson: yup.boolean(),
});

export const loginSchema = yup.object().shape({
  register: yup
    .string()
    .required("Регистрийн дугаар оруулна уу")
    .when("isPerson", {
      is: true,
      then: () =>
        yup
          .string()
          .matches(
            /^[А-ЯӨҮ]{2}\d{8}$/,
            "Регистрийн дугаар 2 үсэг, 8 тооноос бүрдэнэ"
          ),
      otherwise: () =>
        yup
          .string()
          .matches(/^\d{7}$/, "Регистрийн дугаар 7 оронтой байх ёстой"),
    }),
  email: yup
    .string()
    .required("Имэйл оруулна уу")
    .email("Имэйл хаяг буруу байна"),
  password: yup.string().required("Нууц үг оруулна уу"),
  isPerson: yup.boolean(),
});

export const companyExtendedSchema = yup.object().shape({
  responsibility: yup.string().required("Хариуцлагын хэлбэр сонгоно уу"),
  ownerships: yup.string().required("Өмчийн хэлбэр сонгоно уу"),
  induties: yup
    .array()
    .of(yup.string().trim())
    .min(1, "Үйл ажиллагааны чиглэл сонгоно уу")
    .required("Үйл ажиллагааны чиглэл сонгоно уу")
    .typeError("Үйл ажиллагааны чиглэл сонгоно уу"), // Add this for better error message

  city: yup.string().required("Аймаг/Хот сонгоно уу"),
  district: yup.string().required("Сум/Дүүрэг сонгоно уу"),
  khoroo: yup.string().required("Баг/Хороо сонгоно уу"),
  address: yup.string().required("Дэлгэрэнгүй хаяг оруулна уу"),

  hasForeignInvestment: yup.boolean().optional(),
  firstName: yup.string().required("Нэр оруулна уу"),
  lastName: yup.string().required("Овог оруулна уу"),
  registration: yup
    .string()
    .matches(/^[А-ЯӨҮ]{2}\d{8}$/, "Регистрийн дугаар 2 үсэг, 8 тооноос бүрдэнэ")
    .required("Регистрийн дугаар оруулна уу"),
});

export const individualExtendedSchema = yup.object().shape({
  firstName: yup.string().required("Нэр оруулна уу"),
  lastName: yup.string().required("Овог сонгоно уу"),
  city: yup.string().required("Аймаг/Хот сонгоно уу"),
  district: yup.string().required("Сум/Дүүрэг сонгоно уу"),
  khoroo: yup.string().required("Баг/Хороо сонгоно уу"),
  address: yup.string().required("Дэлгэрэнгүй хаяг оруулна уу"),

  phone: yup
    .string()
    .required("Утасны дугаар оруулна уу")
    .matches(/^[0-9]{8}$/, "Утасны дугаар 8 оронтой байх ёстой"),
});

export const testSchema = yup.object().shape({
  firstName: yup.string().required("Нэр оруулна уу"),
  lastName: yup.string().required("Овог сонгоно уу"),
  city: yup.string().required("Аймаг/Хот сонгоно уу"),
  district: yup.string().required("Сум/Дүүрэг сонгоно уу"),
  khoroo: yup.string().required("Баг/Хороо сонгоно уу"),
  address: yup.string().required("Дэлгэрэнгүй хаяг оруулна уу"),

  phone: yup
    .string()
    .required("Утасны дугаар оруулна уу")
    .matches(/^[0-9]{8}$/, "Утасны дугаар 8 оронтой байх ёстой"),
});

export const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Хамгийн багадаа 8 тэмдэгт")
    .required("Шаардлагатай"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Нууц үг таарахгүй байна"),
});

export const phoneSchema = yup.object().shape({
  phone: yup
    .string()
    .matches(/^[5-9]\d{7}$/, "Утасны дугаар буруу")
    .required("Утасны дугаар оруулна уу"),
});

export const profileSchema = yup.object().shape({
  firstName: yup.string().required("Овог оруулна уу"),
  lastName: yup.string().required("Нэр оруулна уу"),
  city: yup.string().required("Хот оруулна уу"),
  district: yup.string().required("Дүүрэг оруулна уу"),
  khoroo: yup.string().required("Хороо оруулна уу"),
  address: yup.string().required("Дэлгэрэнгүй хаяг оруулна уу"),
  regNumber: yup
    .string()
    .matches(/^УК\d{8}$/, "Бүртгэлийн дугаар буруу")
    .required("Бүртгэлийн дугаар оруулна уу"),
});

export const passwordResetSchema = yup.object().shape({
  password: yup
    .string()
    .required("Нууц үг оруулна уу")
    .min(8, "Нууц үг хамгийн багадаа 8 тэмдэгт байх ёстой"),

  confirmPassword: yup
    .string()
    .required("Нууц үгээ баталгаажуулна уу")
    .oneOf([yup.ref("password"), null], "Нууц үг таарахгүй байна"),
});

export const recoverySchema = yup.object().shape({
  email: yup
    .string()
    .required("Имэйл хаяг оруулна уу")
    .email("Имэйл хаяг буруу байна"),

  register: yup
    .string()
    .required("Регистрийн дугаар оруулна уу")
    .matches(/^\d{7}$/, "Регистрийн дугаар 7 оронтой байх ёстой"),
});

export const resolvers = {
  register: yupResolver(registerSchema),
  login: yupResolver(loginSchema),
  passwordReset: yupResolver(passwordResetSchema),
  recovery: yupResolver(recoverySchema),
};

export const profileEditSchema = yup.object().shape({
  firstName: yup.string().required("Нэр заавал шаардлагатай"),
  lastName: yup.string().required("Овог заавал шаардлагатай"),
  email: yup
    .string()
    .email("Буруу и-мэйл хаяг")
    .required("И-мэйл хаяг заавал шаардлагатай"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]{8}$/, "Утасны дугаар 8 оронтой байх ёстой"),
});

export const phoneUpdateSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Утасны дугаар оруулна уу")
    .matches(/^[5-9][0-9]{7}$/, "Та зөв утасны дугаар оруулна уу"),
});

export const step1Schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Компанийн нэр оруулна уу")
    .min(2, "Компанийн нэр хамгийн багадаа 2 тэмдэгт байх ёстой")
    .max(100, "Компанийн нэр хамгийн ихдээ 100 тэмдэгт байх ёстой"),
  title: yup
    .string()
    .trim()
    .required("Тендерийн нэр оруулна уу")
    .min(5, "Тендерийн нэр хамгийн багадаа 5 тэмдэгт байх ёстой")
    .max(150, "Тендерийн нэр хамгийн ихдээ 150 тэмдэгт байх ёстой"),
  cost: yup
    .number()
    .typeError("Зардал тоон утга байх ёстой")
    .positive("Зардал эерэг тоо байх ёстой")
    .optional(),
  endDate: yup
    .date()
    .typeError("Хаагдах огноо буруу байна")
    .required("Хаагдах огноо оруулна уу")
    .min(new Date(), "Хаагдах огноо өнөөдрөөс хойш байх ёстой"),
  startDate: yup
    .date()
    .typeError("Нээгдэх огноо буруу байна")
    .required("Нээгдэх огноо оруулна уу")
    .max(
      yup.ref("endDate"),
      "Нээгдэх огноо хаагдах огнооноос өмнө байх ёстой"
    ),
  tags: yup
    .string()
    .required("Үйл ажиллагааны чиглэл оруулна уу"),
  totalBudget: yup
    .number()
    .typeError("Төсөвт өртөг тоон утга байх ёстой")
    .positive("Төсөвт өртөг эерэг тоо байх ёстой")
    .required("Төсөвт өртөг оруулна уу"),
  funding: yup
    .string()
    .trim()
    .required("Санхүүжилт оруулна уу")
    .min(3, "Санхүүжилт хамгийн багадаа 3 тэмдэгт байх ёстой")
    .max(50, "Санхүүжилт хамгийн ихдээ 50 тэмдэгт байх ёстой"),
});

export const step2Schema = yup.object().shape({
  fileTitle: yup.string().required("Файлын гарчиг оруулна уу"),
  files: yup.array().min(1, "Дор хаяж нэг файл оруулах шаардлагатай"),
});