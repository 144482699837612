import React, { useEffect, useState } from "react";

const CheckAuth = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    setIsLoggedIn(true);
  }, [setIsLoggedIn]);
  return !isLoggedIn ? <></> : props.children || <div></div>;
};

export default CheckAuth;
