import React, { useRef } from "react";
import {
  Carousel,
  SearchFloatingBar,
  FilterSection,
  TenderList,
  IndustryCard,
} from "../components/homePage";
import thousandify from "thousandify";
import { industries } from "../components/data/industries";
import Layout from "../layout/Layout";

const Home = () => {
  return (
    <Layout>
      <div className="relative w-full">
        <Carousel />
        <div className="max-w-7xl container mx-auto w-full">
          <SearchFloatingBar />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-6 mx-auto container max-w-7xl mt-8 lg:mt-20">
        <div className="md:w-1/4 px-4 md:pr-0 md:pl-0">
          <div className="bg-green-500 text-white rounded-lg p-4 mb-4">
            <div className="text-sm font-light mb-1">
              Нийт зарлагдсан тендер
            </div>
            <div className="text-3xl font-bold">{thousandify(2320)}</div>
          </div>
          <FilterSection />
        </div>
        <div className="md:w-3/4 pr-4 md:px-0 min-h-full">
          <TenderList title="Сүүлд зарлагдсан тэндэрийн урилгууд" />
        </div>
      </div>
      <div className="w-full max-w-7xl container mx-auto my-6">
        <h2 className="font-bold text-center mb-5">Салбараар харах</h2>
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 gap-4">
          {industries?.map((industry, idx) => (
            <IndustryCard key={idx} industry={industry} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Home;
