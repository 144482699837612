import { useContext, useEffect, useState } from "react";
import { PlanService } from "../service/plan-service";
import { GlobalContext } from "../context/global-context";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../layout/AdminLayout";
import { PlansSkeleton } from "../components/skeleton/PlansSkeleton";
import PlanCard from "../components/dashboardPage/PlanCard";

const Plans = () => {
  const { userInfo } = useContext(GlobalContext);
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getPlans = async () => {
      try {
        const response = await PlanService.getPlans(userInfo.token);
        setPlans(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error getting plans: ", error);
        throw new Error("Алдаа гарлаа:", error);
      }
    };
    getPlans();
  }, [userInfo]);

  if (isLoading) return <PlansSkeleton numberOfCards={3} />;

  return (
    <div className="w-full h-full p-6">
      <div className="card w-fit mx-auto content-center h-fit grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
        {plans?.map((plan, idx) => (
          <PlanCard
            plan={plan}
            idx={idx}
            key={idx}
            navigate={navigate}
            hide={false}
          />
        ))}
      </div>
    </div>
  );
};

export default Plans;
