import React, { useState } from "react";
import Layout from "../layout/Layout";
import { Pagination, TabButton } from "../components/companiesPage";
import { TenderCard } from "../components/homePage";
import { CompanyProfile, ContactUs } from "../components/companiesPage";

const CompanyDetail = () => {
  const [activeTab, setActiveTab] = useState("Тэндэрүүд");
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout>
      <div className="mx-auto container max-w-7xl">
        <CompanyProfile />
        <div className="flex w-3/6 mt-20 justify-center">
          <div className="flex w-full rounded-lg overflow-hidden">
            <TabButton
              name="Тэндэрүүд"
              isActive={activeTab === "Тэндэрүүд"}
              onClick={() => setActiveTab("Тэндэрүүд")}
            />
            <TabButton
              name="Бидний тухай"
              isActive={activeTab === "Бидний тухай"}
              onClick={() => setActiveTab("Бидний тухай")}
            />
          </div>
        </div>
        {activeTab === "Тэндэрүүд" ? (
          <div>
            <div className="grid grid-cols-3 my-8 gap-3">
              {mockTenders.map((tender, idx) => (
                <TenderCard tender={tender} key={idx} deadline={false} />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={12}
              onPageChange={handlePageChange}
            />
          </div>
        ) : (
          <ContactUs />
        )}
      </div>
    </Layout>
  );
};

export default CompanyDetail;

const mockTenders = [
  {
    id: 1,
    company: "MCS Coca Cola",
    type: "Corporate",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/03/20",
    package: "1",
    status: "Нээлттэй тендер",
    amount: 250000000,
    lots: "1",
    showButton: true,
    showGreenButton: false,
  },
  {
    id: 2,
    company: "MCS Coca Cola",
    type: "Premium",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/03/20",
    package: "1",
    status: "Нээлттэй тендер",
    amount: 250000000,
    lots: "1",
    showButton: true,
    showGreenButton: false,
  },
  {
    id: 3,
    company: "MCS Coca Cola",
    type: "Basic",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/02/20",
    package: "1",
    status: "Хаалттай тендер",
    amount: 69847654,
    lots: "",
    showButton: false,
    showGreenButton: true,
  },
  {
    id: 4,
    company: "MCS Coca Cola",
    type: "Basic",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/03/20",
    package: "1",
    status: "Нээлттэй тендер",
    amount: "36845165",
    lots: "",
    showButton: false,
    showGreenButton: true,
  },
  {
    id: 5,
    company: "MCS Coca Cola",
    type: "Basic",
    title: "Шилэн кабель болон автоматжуулах төхөөрөг нийлүүлэх",
    date: "2025/03/20",
    package: "1",
    status: "Нээлттэй тендер",
    amount: 16514682,
    lots: "1",
    showButton: false,
    showGreenButton: true,
  },
];
