import React from "react";
import { FaRegUser } from "react-icons/fa6";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";

export const UserTypeSelector = ({ isPerson, onUserTypeChange }) => {
  return (
    <div className="flex mb-4">
      <button
        type="button"
        onClick={() => onUserTypeChange(false)}
        className={`flex-1 py-2 ${
          isPerson === false
            ? "bg-primary border border-primary text-white"
            : "bg-white border border-base300 text-secondary"
        } rounded-l-md flex items-center justify-center font-medium`}
      >
        <span className="mr-2">
          <HiOutlineBuildingLibrary size={20} />
        </span>
        Байгууллага
      </button>
      <button
        onClick={() => onUserTypeChange(true)}
        type="button"
        className={`flex-1 py-2 ${
          isPerson === true
            ? "bg-primary border border-primary text-white"
            : "bg-white text-secondary border border-base300"
        } rounded-r-md flex items-center justify-center font-medium`}
      >
        <span className="mr-2">
          <FaRegUser />
        </span>
        Хувь хүн
      </button>
    </div>
  );
};
