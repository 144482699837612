import React from "react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

export const Pagination = ({ pagination, currentPage, onPageChange }) => {
  const { total, pageCount, limit } = pagination || {
    total: 0,
    pageCount: 1,
    limit: 8,
  };

  const getPageNumbers = () => {
    const pages = [];
    if (pageCount <= 7) {
      for (let i = 1; i <= pageCount; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, "...", pageCount);
      } else if (currentPage >= pageCount - 2) {
        pages.push(
          1,
          "...",
          pageCount - 3,
          pageCount - 2,
          pageCount - 1,
          pageCount
        );
      } else {
        pages.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          pageCount
        );
      }
    }
    return pages;
  };

  return (
    <div className="flex justify-center my-8">
      <div className="join">
        <button
          className="join-item btn btn-sm"
          onClick={() => onPageChange(currentPage > 1 ? currentPage - 1 : 1)}
          disabled={currentPage === 1}
        >
          <MdNavigateBefore />
        </button>

        {getPageNumbers().map((page, index) => (
          <button
            key={index}
            className={`join-item btn btn-sm ${
              currentPage === page ? "bg-primary border-primary text-white" : "bg-white"
            }`}
            onClick={() => typeof page === "number" && onPageChange(page)}
            disabled={typeof page !== "number"}
          >
            {page}
          </button>
        ))}

        <button
          className="join-item btn btn-sm"
          onClick={() =>
            onPageChange(currentPage < pageCount ? currentPage + 1 : pageCount)
          }
          disabled={currentPage === pageCount}
        >
          <MdNavigateNext />
        </button>
      </div>
    </div>
  );
};
