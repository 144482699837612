export const ConfirmService = {
  confirmAccount(data, token) {
    console.log({ data, token });
    return fetch("http://202.126.94.29:3004/api/v1/confirm-account", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  },
  sendOTP(phone, token) {
    const data = {
      email: "",
      type: "phone",
      status: "change-phone-number",
      phone: phone,
    };
    return fetch("http://202.126.94.29:3004/api/v1/send-otp", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  },
  verifyOTP(data, token) {
    return fetch("http://202.126.94.29:3004/api/v1/auth/change-phone-number", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  },
};
