import { FiChevronDown } from "react-icons/fi";

export const FilterSection = ({
  filters,
  provinces,
  availableSoums,
  industries,
  onFilterChange,
}) => {
  return (
    <div className="mb-8">
      <div className="mb-4">
        <label className="block text-sm text-gray-600 mb-2">Аймаг/Хот</label>
        <div className="dropdown w-full">
          <div
            tabIndex={0}
            className="border border-base300 rounded-md p-2.5 flex justify-between items-center w-full cursor-pointer bg-white"
          >
            <span className="text-sm text-gray-700">
              {filters.province ? filters.province.name : "Сонгох"}
            </span>
            <FiChevronDown />
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content flex-nowrap menu p-2 shadow bg-white rounded-lg w-full mt-1 max-h-60 overflow-y-auto z-10"
          >
            {provinces.map((province) => (
              <li
                key={province.id}
                onClick={() => onFilterChange("province", province)}
              >
                <a className="text-sm py-2">{province.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm text-gray-600 mb-2">Сум/Дүүрэг</label>
        <div className="dropdown w-full">
          <div
            tabIndex={0}
            className={`border border-base300 rounded-md p-2.5 flex justify-between items-center w-full cursor-pointer bg-white ${
              !filters.province ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <span className="text-sm text-gray-700">
              {filters.soum || "Сонгох"}
            </span>
            <FiChevronDown />
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow bg-white rounded-lg w-full mt-1 max-h-60 overflow-y-auto z-10"
          >
            {availableSoums.map((soum) => (
              <li key={soum} onClick={() => onFilterChange("soum", soum)}>
                <a className="text-sm py-2">{soum}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm text-gray-600 mb-2">Салбарууд</label>
        <div className="dropdown w-full">
          <div
            tabIndex={0}
            className="border border-base300 rounded-md p-2.5 flex justify-between items-center w-full cursor-pointer bg-white"
          >
            <span className="text-sm text-gray-700">
              {filters.industry || "Сонгох"}
            </span>
            <FiChevronDown />
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow bg-white rounded-lg w-full mt-1 max-h-60 overflow-y-auto z-10"
          >
            {industries.map((industry) => (
              <li
                key={industry}
                onClick={() => onFilterChange("industry", industry)}
              >
                {industry}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
