import React from 'react'
import Layout from '../layout/Layout';

const FAQ = () => {
  return (
    <Layout>
      test
    </Layout>
  )
}

export default FAQ;